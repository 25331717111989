import React from "react";

export function IndexButtons({ ids, handleEdit, handleDelete }) {
  return (
    <>
      <div className="row ml-0">
        <button
          type="button"
          id="tour_new_job"
          className="btn btn-light-primary"
          disabled={ids.length != 1}
          onClick={handleEdit}
        >
          Edit
        </button>
        <button
          type="button"
          id="tour_new_job"
          className="btn btn-outline-danger ml-3"
          disabled={ids.length == 0}
          onClick={handleDelete}
        >
          Delete
        </button>
      </div>
    </>
  );
}
