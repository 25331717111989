import React from "react";
import Select from "react-select";
import { SendTimeSelector } from "./SendTimeSelector.js";

export function AddFlowItem({
  templates,
  setChosenTemplate,
  chosenTemplate,
  sendAfter,
  sendAfterOptions,
  setSendAfter,
  handleAddFlowSave,
  flow,
  setFlow,
}) {
  //hideconsole.log("sendAfter is", sendAfter);
  return (
    <>
      <div className="form-group row">
        <div className="col-3">Choose Template</div>
        <div className="col-3">
          <Select
            className="prefix"
            options={templates}
            value={chosenTemplate}
            onChange={(e) => {
              setChosenTemplate(e);
            }}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 99999 }),
            }}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-4 d-flex justify-contents-center align-items-center">
          <span className="mr-4"> Send Mail After</span>{" "}
          <input
            type="Number"
            value={sendAfter[0]}
            onChange={(e) => {
              const temp = [...sendAfter];
              temp[0] = Number(e.target.value);
              setSendAfter(temp);
            }}
          />
        </div>
        <div className="col-4">
          <Select
            className="prefix"
            options={sendAfterOptions}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 99999 }),
            }}
            value={
              sendAfter[1] != "-1"
                ? sendAfterOptions.filter((item) => {
                    return item.value == sendAfter[1];
                  })[0]
                : null
            }
            onChange={(e) => {
              const temp = [...sendAfter];
              temp[1] = e.value;
              setSendAfter(temp);
            }}
          />
        </div>
      </div>
      <div className="form-group row">
        <button
          type="button"
          id="tour_new_job"
          className="btn btn-primary tour_new_job  ml-2"
          onClick={handleAddFlowSave}
        >
          Save
        </button>
      </div>
    </>
  );
}
