import React, { useState } from "react";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
// import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import { Button, Modal } from "react-bootstrap";
import { Mode } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";

export function ClientPortalAppointments({
  events,
  customerId,
  getEvents,
  companies,
  setEvents,
  handleRefresh,
}) {
  //hideconsole.log("ClientPortalAppointments events are", ClientPortalAppointments);
  const [showDetails, setShowDetails] = useState(false);
  const [company, setCompany] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [address, setAddress] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [dateTime, setDateTime] = useState(null);
  const DragAndDropCalendar = withDragAndDrop(Calendar);
  const localizer = momentLocalizer(moment);
  const history = useHistory();
  //hideconsole.log(events);
  function handleCancelJob() {
    axios
      .post("https://www.api.sendatradie.com/client/cancelJob", {
        company_id: companyId,
        customer_id: customerId,
        id: jobId,
      })
      .then((response) => {
        if (response) {
          toast.success("Job succesfully canceled");
          getEvents();
          handleClose();
          //hideconsole.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleEventClick = (e) => {
    setShowDetails(true);
    setCompany(e.title);
    setCompanyId(e.company_id);

    //hideconsole.log("company id is", companyId);
    setAddress(e.address);
    setDateTime(e.start);
    setJobId(e.id);
    //hideconsole.log(e);
  };

  function handleClose() {
    setShowDetails(false);
  }

  return (
    <>
      <Modal
        size="md"
        show={showDetails}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">{company}</Modal.Title>
          <button
            type="button"
            className="btn btn-light btn-close mr-0"
            aria-label="Close"
            onClick={handleClose}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-2">
            <div className="col-lg-1">
              <i className="fas fa-map-marker-alt"></i>
            </div>
            <div className="col-lg-8">{address}</div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-1">
              <i className="fas fa-calendar-alt"></i>
            </div>
            <div className="col-lg-8">
              {moment(dateTime).format("DD-MM-yyyy HH:mm")}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button>Cancel Job</Button>
          <Button>Add Details</Button> */}
          <div>
            <button
              type="button"
              onClick={handleCancelJob}
              className="btn btn-light-danger btn-elevate"
            >
              Cancel Job
            </button>

            <button
              type="button"
              onClick={handleClose}
              className="btn btn-primary ml-3 btn-elevate"
            >
              Confirm
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <div style={{ height: "700px", overflowY: "auto" }}>
        <div
          className="card card-custom p-3"
          style={{ boxShadow: "none", maxHeight: "100%" }}
        >
          <div className="card-header border-0" style={{ maxHeight: "100%" }}>
            <h3 className="card-title font-weight-bolder text-dark">
              Appointments
            </h3>
          </div>
          {/* <DragAndDropCalendar
            selectable
            events={events}
            resizable
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            defaultView={Views.MONTHS}
            defaultDate={new Date()}
            popup={true}
            onSelectEvent={(event) => {
              handleEventClick(event);
            }}
            eventPropGetter={() => ({
              className: "btn-light",
              // style: { backgroundColor: "grey", color: "black" },
            })}
          /> */}
          <Calendar
            views={["month"]}
            selectable
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            defaultDate={new Date()}
            popup={true}
            onSelectEvent={(event) => {
              handleEventClick(event);
            }}
            eventPropGetter={(event) => ({
              className:
                event?.type == "calendar" ? "btn-success" : "btn-light",
              style: {
                backgroundColor: event?.type == "calendar" ? "#1bc5bd" : "",
              },
            })}
          />
        </div>
      </div>
    </>
  );
}
