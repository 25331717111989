import React, { useState, useEffect } from "react";
import {
  Paper,
  Divider,
  TextareaAutosize,
  makeStyles,
  Grid,
  Button,
} from "@material-ui/core";
let IsGsmCOunt = 0;
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#EEF0F8",
    padding: "1rem",
  },
}));
export function Template({
  Title,
  type,
  messages,
  setMessage,
  buttonValue,
  count,
  setCount,
  numberCount,
  setnumberCount,
  onSaveData,
}) {
  const classes = useStyles();

  const handleMsg = (e) => {
    let lengthOfevent = e.target.value.length;
    setMessage({
      Message: e.target.value,
    });

    if (lengthOfevent > 0) {
      var lastChar = e.target.value.charAt(e.target.value.length - 1);
      let IsGsm = isGSMAlphabet(`${lastChar}`);
      //hideconsole.log(IsGsm);
      //hideconsole.log(`${lastChar}`);

      if (IsGsm === false) {
        IsGsmCOunt = 1;
      }
      var msgCountAsNum = IsGsmCOunt === 0 ? 160 : 67;
      //hideconsole.log(msgCountAsNum);

      const split = e.target.value.split(" ");
      const CountStep = split.filter((count) => {
        return (
          count === `{Name}` ||
          count === `{Company}` ||
          count === `{Link}` ||
          count === `{Customer_Name}` ||
          count === `{Appointment_time}` ||
          count === `{Customer_Name}` ||
          count === `{Customer_Phone}` ||
          count === `{Customer_Address}` ||
          count === `{Additional_Information}` ||
          count === `{Coustomer_Phone}` ||
          count === `{Coustomer_Address}` ||
          count === `{Additional_Information}` ||
          count === `{Google_Map}` ||
          count === `{appointment_Time}`
        );
      });

      if (CountStep.length > 1) {
        setCount(CountStep.toString().length - CountStep.length + 1);

        const calculateval =
          CountStep.toString().length - CountStep.length + 1 - CountStep.length;
        const currentMsglength = lengthOfevent - calculateval;
        const messageLengthRound = Math.ceil(currentMsglength / msgCountAsNum);
        const messageLength = msgCountAsNum * messageLengthRound;
        setnumberCount({
          messageLength: messageLength,
          currentMsglength: currentMsglength,
          remainingLength: messageLength - currentMsglength,
          MessageCount: messageLengthRound,
        });
      } else if (CountStep.length === 1) {
        setCount(CountStep.toString().length);
        const calculateval = lengthOfevent - CountStep.toString().length + 1;
        const currentMsglength = lengthOfevent - calculateval;
        const messageLengthRound = Math.ceil(currentMsglength / msgCountAsNum);
        const messageLength = msgCountAsNum * messageLengthRound;
        setnumberCount({
          messageLength: messageLength,
          currentMsglength: currentMsglength,
          remainingLength: messageLength - currentMsglength,
          MessageCount: messageLengthRound,
        });
      } else {
        setCount(0);
        const currentMsglength = lengthOfevent;
        const messageLengthRound = Math.ceil(currentMsglength / msgCountAsNum);
        const messageLength = msgCountAsNum * messageLengthRound;
        setnumberCount({
          messageLength: messageLength,
          currentMsglength: currentMsglength,
          remainingLength: messageLength - currentMsglength,
          MessageCount: messageLengthRound,
        });
      }
    } else {
      setCount(0);
      IsGsmCOunt = 0;
      setnumberCount({
        messageLength: 160,
        currentMsglength: 0,
        remainingLength: 0,
        MessageCount: 0,
      });
    }
  };

  const isGSMAlphabet = (text) => {
    var regexp = new RegExp(
      "^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$"
    );
    return regexp.test(text);
  };

  const handleResetData = () => {
    setCount(0);
    setMessage({ Message: "" });
    setnumberCount({
      messageLength: 160,
      currentMsglength: 0,
      remainingLength: 0,
      MessageCount: 0,
    });
    IsGsmCOunt = 0;
  };

  const handleClickButtonVariable = (value) => {
    var msgCountAsNum = IsGsmCOunt === 0 ? 160 : 67;
    setMessage({
      Message: `${messages.Message} ${value}`,
    });
    if (messages.Message) {
      if (
        numberCount.currentMsglength % msgCountAsNum === 0 ||
        (numberCount.currentMsglength + 1) % msgCountAsNum === 0
      ) {
        setnumberCount({
          messageLength: numberCount.messageLength + msgCountAsNum,
          currentMsglength: numberCount.currentMsglength + 2,
          remainingLength: msgCountAsNum - 2,
          MessageCount: numberCount.MessageCount + 1,
        });
      } else {
        setnumberCount({
          messageLength: numberCount.messageLength,
          currentMsglength: numberCount.currentMsglength + 2,
          remainingLength: numberCount.remainingLength - 2,
          MessageCount: numberCount.MessageCount,
        });
      }
    } else {
      setnumberCount({
        messageLength: numberCount.messageLength,
        currentMsglength: numberCount.currentMsglength + 2,
        remainingLength: msgCountAsNum - 2,
        MessageCount: numberCount.MessageCount + 1,
      });
    }
  };

  return (
    <>
      <Paper>
        <h3 className="p-5 mb-0 fw-bolder fs-4">{Title}</h3>
        <Divider />
        <div className="p-5">
          {type === "showPaper" ? (
            <>
              <Paper className={classes.root} elevation={0}>
                <p>
                  When you send a SMS message over 160 characters the message
                  will be split. Large messages are segmented into 153 character
                  segements and sent individually then rebuilt by the recipients
                  device. For example, a 161 character message will be sent as
                  two messages, one with 153 characters and the second with 8
                  characters.
                </p>
                <p>
                  If you include non-GSM characters like eastern characters in
                  SMS messages, those messages have to be sent via UCS-2
                  encoding. Messages containing any UCS-2 characters are limited
                  to 67 characters and will be concatenated into 67 character
                  message segments, even if the messages contain less than 160
                  characters.
                </p>
                <p>
                  Variable characters will only be included when message is
                  sent. Please check character limit before sending.
                  {`{Link}`} variable is 22 characters.
                </p>
              </Paper>
            </>
          ) : null}

          <div>
            {buttonValue.length > 0 &&
              buttonValue.map((value, index) => {
                return (
                  <Button
                    className="mr-3 mt-5 text-white"
                    key={index}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleClickButtonVariable(value);
                    }}
                  >
                    {value}
                  </Button>
                );
              })}
          </div>
          <div className="mt-5 w-100">
            <TextareaAutosize
              maxRows={7}
              minRows={7}
              className="w-100 shadow border border-2 p-3"
              style={{ outline: "none" }}
              aria-label="maximum height"
              value={messages.Message}
              onChange={(e) => {
                handleMsg(e);
              }}
            />
          </div>

          {/* <div className="mt-5">
            <Grid container spacing={2}>
              <Grid item xs={6} lg={3} md={6}>
                <div>Per message length: {numberCount.messageLength}</div>
              </Grid>
              <Grid item xs={6} lg={3} md={6}>
                <div>Length: {numberCount.currentMsglength}</div>
              </Grid>
              <Grid item xs={6} lg={3} md={6}>
                <div>Remaining: {numberCount.remainingLength}</div>
              </Grid>
              <Grid item xs={6} lg={3} md={6}>
                <div>Messages : {numberCount.MessageCount}</div>
              </Grid>
            </Grid>
          </div> */}
        </div>
        <Divider />
        <div className="p-5">
          <Button
            className="mr-3 text-white"
            variant="contained"
            color="primary"
            onClick={onSaveData}
          >
            Save
          </Button>
          <Button
            className="mr-3 text-white"
            variant="contained"
            color="primary"
            onClick={handleResetData}
          >
            Reset
          </Button>
        </div>
      </Paper>
    </>
  );
}
