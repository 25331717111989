import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";

export function CustomFieldsSettingsModal({
  show,
  setShow,
  handleHide,
  row,
  fieldLabel,
  setFieldLabel,
  tab,
  setTab,
  handleSubmitModal,
}) {
  const tabOptionJob = [
    { value: 0, label: "Job" },
    { value: 1, label: "Customer" },
    { value: 2, label: "Files" },
    { value: 3, label: "Notes" },
  ];
  const tabOptionClient = [{ value: 0, label: "Client Info" }];
  return (
    <>
      <Modal
        show={show}
        onHide={handleHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit Custom Field
          </Modal.Title>
          <button
            type="button"
            className="btn btn-light btn-close mr-0"
            aria-label="Close"
            onClick={handleHide}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <div className="col-lg-12">
              <label>Field Name</label>
              <input
                value={fieldLabel}
                as="textarea"
                className="form-control"
                onChange={(e) => {
                  setFieldLabel(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12">
              <label>Tab</label>
              {row?.page_id == "/jobs" ? (
                <>
                  <Select
                    classNamePrefix="mySelect"
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                    }}
                    options={tabOptionJob}
                    onChange={(e) => {
                      setTab(e);
                    }}
                    value={tab}
                  />
                </>
              ) : (
                <>
                  <Select
                    classNamePrefix="mySelect"
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                    }}
                    options={tabOptionClient}
                    value={tab}
                    onChange={(e) => {
                      setTab(e);
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={handleHide}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={handleSubmitModal}
              className="btn btn-primary ml-3 btn-elevate"
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
