import React, { useState, useEffect } from "react";
import * as requestFromServer from "./../modules/Jobs/_redux/customers/customersCrud.js";
import { PlansView } from "../modules/Plans";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { connect, useSelector } from "react-redux";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";
import { PaymentMethodModal } from "../modules/Plans/PlanSelectDetail/PaymentMethodModal.js";

export function Plans(props) {
  const [plans, setPlans] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const userData = useSelector((state) => state.auth.user);
  const [show, setShow] = useState(false);

  useEffect(() => {
    //hideconsole.log(userData);
    getData();
  }, []);

  useEffect(() => {
    console.log(show);
  }, [show]);

  const getData = () => {
    setLoading(true);
    requestFromServer
      .getSubScriptionPlan()
      .then((response) => {
        // //hideconsole.log(response.data.result, "response.data.result.");
        setLoading(false);
        if (response.data.result.length) {
          setPlans(response.data.result);
        } else {
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.data?.message == "User not authorized.") {
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className="card">
        <div className="card-body p-lg-17">
          <div className="d-flex flex-column justify-content-center text-center">
            <div className="mb-13 text-center">
              {userData.pack_id != 1 && userData.pack_id != 5 ? (
                <button
                  className="btn btn-sm btn-light-success mb-3"
                  type="button"
                  onClick={() => setShow(true)}
                >
                  Change Payment Method
                </button>
              ) : (
                ""
              )}
              <h1 className="fs-2hx fw-bold">Choose Your Plan</h1>

              {/* <div class="text-gray-400 fw-semibold fs-5">
              If you need more info about our pricing, please check
              <a href="#" class="link-primary fw-bold">
                Pricing Guidelines
              </a>
              .
            </div> */}
            </div>
            {loading ? (
              <div>
                <svg className="splash-spinner m-0" viewBox="0 0 50 50">
                  <circle
                    className="path center-block"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"
                  ></circle>
                </svg>
              </div>
            ) : (
              <PlansView
                data={plans}
                type="plan"
                currentPlan={0}
                showPaymentMethodModal={setShow}
              />
            )}
          </div>
        </div>
        <PaymentMethodModal show={show} onHide={() => setShow(false)} />
      </div>
    </MuiPickersUtilsProvider>
  );
}
