import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import qs from "qs";
import { CustomerSignatureDialog } from "./custome-invoice-signature.js";
import unpaid from "../assets/svg/unpaid.svg";
import paid from "../assets/svg/paid.svg";
import styles from "./customers-receipt-invoice.module.scss";
// import Logo from "../../../../../public/media/logos/logo.png";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { toasterMessage } from "./../../../../utils/toast.js";
import CustomAxios from "../../../../utils/axios";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CardFooter,
} from "../../../../_metronic/_partials/controls";

export function CustomerInvoiceDialog(props) {
  //using usehistory for routing to login page
  const history = useHistory();
  // required hooks
  const [invoiceData, setInvoice] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [signatureRequest, setSignatureRequest] = React.useState(false);
  const [signatureModal, setSignatureModal] = React.useState(false);
  const [imageURL, setImageURL] = React.useState(null);
  const [signBy, setSignBy] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [onMobile, setOnMobile] = React.useState(false);
  const [submitting, setSubmitting] = useState(false);

  // Signature canvas useRef
  const sigCanvas = useRef({});

  // call get invoice api in use hooks
  useEffect(() => {
    // get ids from params
    if (props.match.params && props.match.params.id) {
      const id = props.match.params.id;
      if (id === "error") {
        return;
      } else {
        //hideconsole.log("id", id);
        var data = qs.stringify({
          id: `${id}`,
        });
        var config = {
          method: "post",
          url: "https://www.api.sendatradie.com/client/getInvoice",
          headers: {
            responseType: "text",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: data,
        };
        // get invoice data, will placed whole api calls in redux.
        axios(config)
          .then(function(response) {
            //hideconsole.log(response);
            let invoiceData = {};
            // check for data if exist then enter in if.
            if (response.data.result.length) {
              invoiceData = response.data.result[0];
              let cder = JSON.stringify(response.data.result[0]);
              let a = JSON.parse(cder);
              let b = JSON.parse(a.items);
              invoiceData.items = b;
              setInvoice([invoiceData]);
              const files = invoiceData?.files?.split(",");
              if (files?.[0]) {
                setFiles(files);
              }
              setIsLoading(false);
            } else {
              // history.push("/");
            }
          })
          .catch(function(error) {
            if (error?.response?.data?.message == "User not authorized.") {
              // history.push("/logout");
            } else {
              //   toasterMessage("error", error.response.data.message);
            }
          });
      }
    }
  }, []);

  // signature save function
  const handleSave = () => {
    setImageURL(
      sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("../../../../../../../public/media/demos/")
    );
    setSignatureModal(false);
  };

  // signature clear function
  const handleClear = () => {
    sigCanvas.current.clear();
  };

  //get data from sign modal & post to api
  const handleForm = (value) => {
    setSignBy(value.name);
    const id = props.match.params.id;
    let data = {
      id,
      signature: imageURL,
      signedBy: value.name,
    };

    // get invoice data, will placed whole api calls in redux.
    setSubmitting(true);

    CustomAxios.call("post", `jobs/updateSignature`, null, data)
      .then(function(res) {
        toasterMessage("success", "Signature Updated");
        setInvoice([
          {
            ...invoiceData[0],
            invoice_file: res.data.data.file,
            request_signature: 0,
          },
        ]);
        setSubmitting(false);
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
          history.push("/logout");
        } else {
          // toasterMessage("error", error.response.data.message);
        }
        //   props.history.push("/logout");
        setSubmitting(false);
      });
  };

  const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  useEffect(() => {
    setOnMobile(deviceType() == "mobile");
    // var x = document.getElementById("myframe");
    // x.style.backgroundColor = "white";
  }, []);

  return (
    <>
      {!isLoading ? (
        <div className="container">
          <Card>
            <CardHeader title="Invoice"></CardHeader>
            <CardBody>
              <div className="row">
                <div className={onMobile ? "col-12" : "col-8"}>
                  <iframe
                    id="myframe"
                    allowtransparency="true"
                    // style="background: #FFFFFF;"
                    src={
                      invoiceData[0].invoice_file.startsWith("http")
                        ? invoiceData[0].invoice_file +
                          "#toolbar=0&navpanes=0&scrollbar=0"
                        : `https://platform-295512.appspot.com.storage.googleapis.com/${invoiceData[0].invoice_file}#toolbar=0&navpanes=0&scrollbar=0`
                    }
                    height={onMobile ? "400px" : "1024px"}
                    width="100%"
                    className="w-300"
                    style={{
                      backgroundColor: "#FFFFFF",
                      maxWidth: "800px",
                    }}
                  ></iframe>
                </div>
                <div className={onMobile ? "col-12" : "col-4"}>
                  {files && files.length ? <label>Files</label> : null}
                  <div className="col mt-1">
                    {files && files.length
                      ? files.map((item, index) => {
                          //hideconsole.log(item);

                          return (
                            <>
                              <div className="row">
                                <img
                                  src={`https://storage.googleapis.com/platform-295512.appspot.com/${item}`}
                                  alt="img"
                                  style={{ maxWidth: "100%" }}
                                />
                              </div>
                            </>
                          );
                        })
                      : null}
                  </div>
                </div>
              </div>
            </CardBody>
            {/* </div>*/}
          </Card>
          {/* <div className="form-group"> */}

          {/* </div> */}

          {/* Adding condition for rendering signature component */}
          {invoiceData.length && invoiceData[0].request_signature == 1 ? (
            <Card>
              <CardHeader title="Signature"></CardHeader>
              <CardBody>
                <CustomerSignatureDialog
                  show={signatureModal}
                  onHide={() => {
                    setSignatureModal(false);
                  }}
                  imageURL={imageURL}
                  handleSave={handleSave}
                  handleClear={handleClear}
                  sigCanvas={sigCanvas}
                  handleForm={handleForm}
                  submitting={submitting}
                  setSubmitting={setSubmitting}
                />
              </CardBody>
            </Card>
          ) : null}
        </div>
      ) : (
        <LayoutSplashScreen />
      )}
      <div className={styles.footer}>
        <div style={{ fontWeight: "500" }} className="mt-2">
          Created Using
        </div>
        <Link to="/">
          <img
            style={{ marginTop: "-25px" }}
            alt="Logo"
            className="max-h-80px"
            src={toAbsoluteUrl("/media/logos/logo.png")}
          />
        </Link>
      </div>
    </>
  );
}
