import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import AuthForm from "../AuthForm";
import * as authCrud from "../../_redux/authCrud.js";
import * as auth from "../../_redux/authRedux.js";
import TCModal from "../Other/TermsConditionsModal";
import "./RegistrationStart.scss";

const RegistrationStart = (props) => {
  const [loginDetail, setLoginDetail] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [showTCModal, setShowTCModal] = useState(false);
  const history = useHistory();

  const handleSubmit = () => {
    setIsLoading(true);
    authCrud
      .checkEmail(loginDetail.email)
      .then((res) => {
        if (res.data.length) {
          toast.error("Email already exists");
        } else {
          const searchParams = history.location.search
            ? history.location.search
            : "";
          const splitsearch = searchParams
            ? searchParams.split("refer_code=")
            : "";
          localStorage.setItem(
            "registerData",
            JSON.stringify({
              ...loginDetail,
              activeStep: 1,
              status: "normal",
              code: splitsearch ? splitsearch[1] : "",
            })
          );
          history.push(`/auth/signup/step${searchParams}`);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const responseGoogle = (response) => {
    authCrud
      .checkEmail(response?.profileObj?.email)
      .then((res) => {
        if (res.data.length) {
          toast.error("Email already exists");
        } else {
          const searchParams = history.location.search
            ? history.location.search
            : "";
          const splitsearch = searchParams.split("refer_code=");
          localStorage.setItem(
            "registerData",
            JSON.stringify({
              email: response?.profileObj?.email,
              password: response?.googleId,
              social: { ...response },
              activeStep: 1,
              status: "google",
            })
          );
          history.push(`/auth/signup/step${searchParams}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const responseGoogleError = (response) => {
    console.log(response);
  };

  const responseFacebook = (response) => {
    authCrud
      .checkEmail(response?.email)
      .then((res) => {
        if (res.data.length) {
          toast.error("Email already exists");
        } else {
          const searchParams = history.location.search || "";
          const splitsearch = searchParams.split("refer_code=");
          localStorage.setItem(
            "registerData",
            JSON.stringify({
              email: response?.email,
              password: response?.userID,
              social: { ...response },
              activeStep: 1,
              status: "facebook",
            })
          );
          history.push(`/auth/signup/step${searchParams}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const responseFacebookError = (response) => {
    console.log(response);
  };

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <>
      <div
        className="d-flex align-items-center flex-column"
        style={{ height: "calc(100% - 170px)" }}
      >
        <div className="container d-flex flex-column align-items-center">
          <div className="col-4 mt-15">
            <img
              alt="Responsive image"
              src="/media/logos/login_logo.webp"
              className="img-fluid"
            ></img>
          </div>
        </div>
      </div>
      <div className="p-2 p-sm-5 d-flex justify-content-center align-items-center flex-column bg_color_white">
        <div>
          <h1 className="heading_register">START YOUR FREE 14-DAY TRIAL</h1>
        </div>
        <div className="sub_head_reg">
          Sendatradie helps you schedule jobs, get paid in the field and grow
          your service business.
        </div>
        <br />
        <AuthForm
          type="signup"
          loginDetail={loginDetail}
          setLoginDetail={setLoginDetail}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          responseFacebook={responseFacebook}
          responseFacebookError={responseFacebookError}
          responseGoogle={responseGoogle}
          responseGoogleError={responseGoogleError}
        />
        <div style={{ fontSize: "1rem" }} className="term_cond_sent">
          By submitting this form you agree to our{" "}
          <span className="link_tag_login" onClick={() => setShowTCModal(true)}>
            Terms & Conditions
          </span>
          .
        </div>
        <div style={{ fontSize: "1rem" }} className="Already_acc">
          Already have an account?
        </div>
        <div
          onClick={() => history.push("/auth/login")}
          style={{ zIndex: 10 }}
          className="link_tag_login"
        >
          Log In
        </div>
      </div>
      <TCModal show={showTCModal} onHide={() => setShowTCModal(false)} />
    </>
  );
};

export default injectIntl(connect(null, auth.actions)(RegistrationStart));
