import React from 'react';
import { Divider } from '@material-ui/core';
//import SendieMessage from '../../SendieMessage.js';
import "./StepHeader.scss";

const StepHeader = ({ title, subtitle }) => {
  return (
    <div className="step-header">
      {/* <SendieMessage
        personalMessage={personalMessage}
        imageSrc={relativeImgPath}
      /> */}
      <div className="title-step">{title}</div>
      {subtitle && <div className="subtitle-step">{subtitle}</div>}
      <Divider />
    </div>
  );
};

export default StepHeader;
