import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { DisplayLocations } from "./DisplayLocations.js";
import { LoadingData } from "./../../pages/LoadingData.js";
import { DisplayLocationReviews } from "./DisplayLocationReviews.js";

export default function GoogleMapReviewsIndex() {
  const [loadingData, setLoadingData] = useState(true);
  const [locationsAdded, setLocationsAdded] = useState(true);
  const [chosenLocation, setChosenLocation] = useState(null);
  const [data, setData] = useState([]);
  const [showReviews, setShowReviews] = useState(false); // State to manage review visibility

  useEffect(() => {
    axios
      .post("https://www.api.sendatradie.com/company/googleMyBusinessSelectedLocations")
      .then((locs) => {
        if (locs.data.data == "[]") {
          if (locs.data.toggle) {
            setLocationsAdded(false);
            let locations = JSON.parse(locs.data.data);
            setData(locations);
            setLoadingData(false);
          } else {
            setLocationsAdded(false);
            setLoadingData(false);
          }
        } else {
          let locations = JSON.parse(locs.data.data);
          setData(locations);
          if (locations?.length == 1) {
            setChosenLocation(locations[0]);
          }
          setLoadingData(false);
        }
      });
  }, []);

  const locationNotChosen = () => {
    if (!chosenLocation) {
      if (loadingData) {
        return (
          <>
            <LoadingData loading={loadingData} />
          </>
        );
      } else {
        return (
          <>
            <DisplayLocations
              data={data}
              setChosenLocation={setChosenLocation}
              locationsAdded={locationsAdded}
            />
          </>
        );
      }
    }
    return <></>;
  };

  const toggleReviews = () => {
    setShowReviews((prevShowReviews) => !prevShowReviews);
  };

  return (
    <>
      <Card>
        <CardHeader title="Google My Business Reviews">
          {chosenLocation && (
            <CardHeaderToolbar>
              <button 
                className="btn btn-light-primary btn-elevate ml-3" 
                onClick={toggleReviews}
              >
                {showReviews ? "Hide Reviews" : "Show Reviews"}
              </button>
            </CardHeaderToolbar>
          )}
        </CardHeader>
        <CardBody>
          {locationNotChosen()}
          {chosenLocation && showReviews && (
            <DisplayLocationReviews chosenLocation={chosenLocation} />
          )}
        </CardBody>
      </Card>
    </>
  );
}
