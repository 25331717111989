import { Switch } from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CustomBootstrapTable from "./../../../_metronic/_helpers/CustomBootstrapTable.js";
import { saveSetting } from "./../Auth/_redux/settingAction.js";
import * as requestFromServer from "./../Jobs/_redux/customers/customersCrud.js";
import CustomAxios from "./../../../utils/axios.js";
import EditNewModal from "./EditJobTypeModal.js";
import { SettingJobTypeFilter } from "./SettingJobTypeFilter.js";

function SettingJobTypeTable() {
  const [editDataId, setEditDataId] = React.useState({});
  const [showEditModal, setshowEditModal] = React.useState(false);
  const [ids, setIds] = React.useState([]);
  const history = useHistory();
  const location = useLocation();
  const [columns, setColumns] = React.useState([{ text: "", dataField: "" }]);
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [search, setSearch] = React.useState(null);
  const [status, setStatus] = React.useState(2);

  const headerTable = (data) => {
    const tempData = {
      name: "",
      email: "",
      due_Date: "",
      create_Date: "",
    };

    let HeaderValue = [];
    let header =
      data && data.length ? Object.keys(data[0]) : Object.keys(tempData);

    if (typeof header != "undefined") {
      header.map((key, index) => {
        //hideconsole.log(key);
        if (key != "id" && key != "company_id" && key != "payment_type_id") {
          HeaderValue.push({
            dataField: key.replace(/_view/g, ""),
            text: key
              .replace(/_view/g, " ")
              .replace(/_/g, " ")
              .replace("nots", "notes")
              .toUpperCase(),
            // sort: true,
          });
        }
      });
      //hideconsole.log("header: ", HeaderValue);
    }
    return HeaderValue;
  };

  useEffect(() => {
    if (location.hash == "#jobTypeChange") {
      getDataForJobType();
      history.push("/settings/jobtype");
    }
  }, [location]);

  const getDataForJobType = () => {
    let queryParams = {
      filters: {
        query: search,
        status: status,
      },
    };
    CustomAxios.call(
      "post",
      "https://www.api.sendatradie.com/settings/getJobtypes",
      null,
      queryParams
    )
      .then((res) => {
        res.data.data.forEach((element) => {
          element.status = (
            <Switch
              defaultChecked={!!element.status}
              onChange={(e) => handleStatus(e)}
              color="primary"
              id={element.id.toString()}
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          );
        });

        setData(res.data.data);
        setColumns(headerTable(res.data.data));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEditModal = (id) => {
    setEditDataId(id);
    setshowEditModal(true);
  };

  const handleEditModalClose = () => {
    setshowEditModal(false);
    setEditDataId({});
  };

  useEffect(() => {
    getDataForJobType();
  }, [status, search]);

  const handleStatus = (e) => {
    e.persist();
    //hideconsole.log(e);
    requestFromServer
      .changeJobTypeStatus(e.target.id, { checked: e.target.checked })
      .then((res) => {
        if (res.status != 200) {
          //hideconsole.log("error");
        }
      });
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  return (
    <>
      <SettingJobTypeFilter
        handleSearch={handleSearch}
        status={status}
        setStatus={setStatus}
      />

      <>
        <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20 mt-3">
          <div className="col form-group form-group-inline">
            <div>
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                disabled={ids.length != 1}
                onClick={() => {
                  handleEditModal(ids[0]);
                }}
              >
                <i className="fa fa-edit"></i> Edit Row
              </button>
              &nbsp;
            </div>
          </div>
        </div>
      </>

      <CustomBootstrapTable
        data={data}
        columns={columns}
        isSelectable
        ids={ids}
        setIds={setIds}
        isLoading={isLoading}
      />
      <EditNewModal
        show={showEditModal}
        allData={data}
        editDataId={editDataId}
        onHide={handleEditModalClose}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return { settings: state.settings };
};
export default connect(mapStateToProps, { saveSetting })(SettingJobTypeTable);
