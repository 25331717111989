import React, { useEffect, useRef, useState } from "react";
import * as requestFromServer from "./../../Jobs/_redux/customers/customersCrud.js";
import Select from "react-select";

export const LeadsFilter = (props) => {
  useEffect(() => {
    const isoStartDate = date[0].startDate.toISOString();
    // requestFromServer.findCustomers(queryParams).then((res) => {
    //     //hideconsole.log(res)
    // })
  }, [fullDate]);
  const [fullDate, setfullDate] = React.useState([]);
  const [permissionType, setPermissionType] = React.useState([]);
  const [dateToSend, setdateToSend] = React.useState({});
  const [date, setDate] = React.useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const statusOptions = [
    { value: 2, label: "All Groups" },
    { value: 1, label: "Active" },
    { value: 0, label: "Disabled" },
  ];

  useEffect(() => {
    //  new Date()

    const date1 = ("0" + date[0].startDate.getDate()).slice(-2);
    const year = date[0].startDate.getFullYear();
    const month = ("0" + date[0].startDate.getMonth() + 1).slice(-2);
    const fullStartDate = `${year}-${month}-${date1}`;

    const date2 = ("0" + date[0].endDate.getDate()).slice(-2);
    const year1 = date[0].endDate.getFullYear();
    const month1 = ("0" + date[0].endDate.getMonth() + 1).slice(-2);
    const fullEndDate = `${year1}-${month1}-${date2}`;

    const startAndendDate = `${fullStartDate} / ${fullEndDate}`;

    setfullDate(startAndendDate);
    setdateToSend({ start: fullStartDate, end: fullEndDate });
  }, [date]);

  // //hideconsole.log("hii from");
  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12">
          <small className="form-text text-muted">
            <b>Search</b>
          </small>
          <input
            type="text"
            className="form-control"
            name="search"
            placeholder="search here"
            // value={fullDate}
            onChange={(event) => {
              props.handleSearch(event.target.value);
            }}
            // onClick={hanldeOnclick}
          />
        </div>
        <div className="col-lg-4">
          <small className="form-text text-muted">
            <b>Show</b>
          </small>
          <Select
            classNamePrefix="mySelect"
            style={{ height: "24px" }}
            name="leads_status"
            label="Show"
            //className="form-control"
            value={statusOptions.find((item) => item.value === props.status)}
            onChange={(e) => props.setStatus(e.value)}
            options={statusOptions}
          ></Select>
        </div>
      </div>
    </>
  );
};
