import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import "./CreateModal.scss";

function CreateModal({ onHide, show }) {
  const SETTINGS_URL = "https://www.api.sendatradie.com/settings";
  const [jobTypes, setJobTypes] = useState([{ name: "", status: true }]);
  const history = useHistory();

  const MAX_ENTRIES = 10;

  const handleSubmitRequest = () => {
    if (jobTypes.some(jobType => !jobType.name.trim())) {
      toast.error("Please fill all Name fields");
      return;
    }

    const jobTypeNames = jobTypes.map(jobType => jobType.name.trim());

    axios
      .post(`${SETTINGS_URL}/jobtypes`, { job_type_names: jobTypeNames })
      .then((res) => {
        toast.success(`Added ${jobTypeNames.length} Job Types.`);
        history.push("/settings/jobtype#jobTypeChange");
      })
      .catch((err) => {
        console.log(err);
        toast.error(`An error occurred while adding Job Types.`);
      });
    handleClose();
  };

  const handleJobTypeChange = (index, field, value) => {
    const newJobTypes = [...jobTypes];
    newJobTypes[index][field] = value;
    setJobTypes(newJobTypes);
  };

  const addJobType = () => {
    if (jobTypes.length < MAX_ENTRIES) {
      setJobTypes([...jobTypes, { name: "", status: true }]);
    } else {
      toast.error(`You can only add up to ${MAX_ENTRIES} job types at a time.`);
    }
  };

  const removeJobType = (index) => {
    setJobTypes(jobTypes.filter((_, i) => i !== index));
  };

  const handleClose = () => {
    onHide();
    setJobTypes([{ name: "", status: true }]);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      aria-labelledby="example-modal-sizes-title-lg"
      className="create-modal"
    >
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          Add Job Types
        </Modal.Title>
        <button
          type="button"
          className="btn btn-light btn-close mr-0"
          aria-label="Close"
          onClick={handleClose}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group step_form job-type-form">
          <TransitionGroup>
            {jobTypes.map((jobType, index) => (
              <CSSTransition key={index} timeout={300} classNames="transition">
                <div className="job-type-row">
                  <input
                    type="text"
                    id={`name-${index}`}
                    value={jobType.name}
                    onChange={(e) => handleJobTypeChange(index, "name", e.target.value)}
                    className="form-control __input_step"
                    placeholder="Enter Job Type..."
                  />
                  <input
                    type="hidden"
                    value="true"
                    onChange={(e) => handleJobTypeChange(index, "status", e.target.value)}
                  />
                  {jobTypes.length > 1 && (
                    <button
                      type="button"
                      className="job-type-remove-btn"
                      onClick={() => removeJobType(index)}
                    >
                      <span className="job-type-remove-btn">-</span>
                    </button>
                  )}
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
        <div className="add-btn-container">
          <button type="button" className="add-button" onClick={addJobType}>
            Add
          </button>           
        </div>
       
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={handleClose}
          className="btn btn-light btn-elevate"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={handleSubmitRequest}
          className="btn btn-primary btn-elevate"
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default CreateModal;
