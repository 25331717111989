import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useHistory, useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { TextField } from "@mui/material";
import Select from "react-select";
import axios from "axios";
import { headerSortingClasses, sortCaret } from "../../../_metronic/_helpers";
import CustomBootstrapTable from "./../../../_metronic/_helpers/CustomBootstrapTable.js";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import { useSelector } from "react-redux";
import moment from "moment";
import * as clientPortalAuth from "./ClientPortalAuth.js";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { ConstructionOutlined } from "@mui/icons-material";
import { NewBookingModal } from "./NewBookingModal.js";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import { useStaticState } from "@material-ui/pickers";
export function QuotesBooking({}) {
  const [dataArriving, setDataArriving] = useState(false);

  const CompanySelect = () => {
    return dataArriving ? (
      LayoutSplashScreen
    ) : (
      <div className="container" style={{ height: "100px" }}>
        <div className="row pt-8 pl-8">
          <div className="col-4">
            <b>Please select company</b>
          </div>

          <div className="col-4">
            <Select
              classNamePrefix="mySelect"
              name="company"
              label="Select Company"
              styles={{
                marginLeft: "10px",
                menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                container: (base) => ({
                  ...base,
                  width: "140px", // set width as needed
                  height: "100px", // set height as needed
                }),
              }}
              menuPortalTarget={document.body}
              //value={typeOptions.find((opt) => opt.value == state?.type)}
              onChange={(e) => {
                handleCompanyChange(e);
              }}
              options={companies}
              placeholder="Select..."
              required
            />
          </div>
        </div>
      </div>
    );
  };
  const BookingComponent = () => {
    return (
      <>
        <div className="row">
          <div className="col-6">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <StaticDatePicker
                // orientation="landscape"
                disablePast
                value={selectedDate}
                onChange={(e) => {
                  setSelectedDate(e);
                  //hideconsole.log("selected date is", e);
                }}
                componentsProps={{
                  // pass props `actions={['clear']}` to the actionBar slot
                  actionBar: { actions: [] },
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            {/* <DateCalendar
                value={value}
                onChange={(newValue) => setValue(newValue)}
              /> */}
          </div>
          <div className="col-6">
            {/* <Paper style={{ width: "100%", height: "100%" }}> */}
            {slotOptions.map((opt, index) => {
              return (
                <button
                  key={index}
                  type="button"
                  onClick={() => {
                    setStart(moment(opt).format("yyyy-MM-DD HH:mm:ss"));
                    setEnd(
                      moment(opt)
                        .add(30, "m")
                        ?.format("yyyy-MM-DD HH:mm:ss")
                    );
                    setShowBooking(true);
                  }}
                  className="btn btn-outline-primary m-3 btn-elevate"
                >
                  {opt.format("HH:mm")}
                </button>
              );
            })}
            {/* </Paper> */}
          </div>
        </div>
      </>
    );
  };
  const props = useSelector((state) => state.clientPortalAuth.userData);
  const [selectedDate, setSelectedDate] = useState(moment());
  const localizer = momentLocalizer(moment);
  const [onMobile, setOnMobile] = React.useState(false);
  const [selectedCompany, setSelectedCompany] = React.useState("");
  const [selectedTimezone, setSelectedTimezone] = React.useState("");
  const date = new Date();
  const [search, setSearch] = React.useState(null);
  const [fileUpload, setFileUpload] = React.useState("");
  const [service, setService] = React.useState("");
  const [typeOptions, setTypeOptions] = React.useState([]);
  const [companies, setCompanies] = useState([]);
  const company_ids = [];
  const history = useHistory();

  const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  const [companyDataArrived, setCompanyDataArrived] = useState(false);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}`;
  const [tab, setTab] = React.useState(0);
  const [newQuoteModal, setNewQuoteModal] = React.useState(false);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [showBooking, setShowBooking] = useState(false);
  const [slotOptions, setSlotOptions] = useState([]);
  const [rawAvailabilityData, setRawAvailabilityData] = useState([]);
  const [timeSlot, setTimeSlot] = useState([]);
  const handleChange = function handleChange(event, newValue) {
    setTab(newValue);
  };

  const [Overlay, setOverlay] = useState(false);
  const [ProfileClass, setProfileClass] = useState(
    "offcanvas offcanvas-right p-10"
  );

  const handleCompanyChange = (company) => {
    setSelectedCompany(company.value);
    setSelectedTimezone(company.time_zone);
    setDataArriving(true);
    axios
      .get(`https://www.api.sendatradie.com/settings/getAvailability/${company.value}`)
      .then((response) => {
        if (response.data.result) {
          //hideconsole.log("availability data has arrived");
          setAvailability(JSON.parse(response.data.result.availability));
          setTimeSlot(response.data.result.time_slot);
          setDataArriving(false);
          axios
            .get(
              `https://www.api.sendatradie.com/client/getCompanyServices/${company.value}`
            )
            .then((response) => {
              //hideconsole.log(response.data.result);
              setTypeOptions([response.data.result]);
            })
            .catch((error) => {
              console.log(error);
            });

          setCompanyDataArrived(true);
        }
      });
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const [occupiedSlots, setOccupiedSlots] = useState([]);

  const [availability, setAvailability] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    //hideconsole.log(selectedDate.isoWeekday());
    const range = availability[selectedDate.isoWeekday()];
    const format = "HH:mm";

    let optionCounter = moment(range?.start, format)
      .year(selectedDate.year())
      .month(selectedDate.month())
      .date(selectedDate.date());
    const endTime = moment(range?.end, format)
      .year(selectedDate.year())
      .month(selectedDate.month())
      .date(selectedDate.date());
    let tempOptions = [];

    while (optionCounter.isBefore(endTime)) {
      //hideconsole.log("in first while");
      let available = true;
      let i = 0;
      while (i < occupiedSlots.length) {
        //hideconsole.log("in second while");
        //hideconsole.log(optionCounter);
        //hideconsole.log(occupiedSlots[i].start);
        //hideconsole.log(occupiedSlots[i].end);
        if (
          optionCounter.isBetween(
            moment(occupiedSlots[i].start).subtract(1, "m"),
            moment(occupiedSlots[i].end)
          )
        ) {
          available = false;
        }
        i += 1;
      }
      available && tempOptions.push(moment(optionCounter));
      optionCounter.add(timeSlot, "m");
    }

    setSlotOptions(tempOptions);

    //hideconsole.log(optionCounter);
  }, [selectedDate, occupiedSlots]);

  useEffect(() => {
    setOnMobile(deviceType() == "mobile");
  }, []);

  function handleFileUpload(file) {
    setFileUpload(file);
  }

  function handleSelectService(s) {
    setService(s);
  }
  useEffect(() => {
    let tempOptions = [];
    for (let i = 0; i < props.quotes.length; i++) {
      var quote = props.quotes[i];
      var c = {};
      c["label"] = quote["name"];
      c["value"] = Number(quote["company_id"]);
      c["time_zone"] = quote["time_zone"];
      if (company_ids.includes(c["value"])) {
        continue;
      }
      tempOptions.push(c);
      company_ids.push(c["value"]);
    }

    for (let i = 0; i < props.invoices.length; i++) {
      var invoice = props.invoices[i];
      var c = {};
      c["label"] = invoice["company_name"];
      c["value"] = Number(invoice["company_id"]);
      c["time_zone"] = quote["time_zone"];
      if (company_ids.includes(c["value"])) {
        continue;
      }
      tempOptions.push(c);
      company_ids.push(c["value"]);
    }

    setCompanies(tempOptions);
  }, [props.quotes, props.invoices]);

  //hideconsole.log(companies);

  function showProfile() {
    setProfileClass("offcanvas offcanvas-right p-10 offcanvas-on");
    setOverlay(true);
  }
  function hideProfile() {
    setProfileClass("offcanvas offcanvas-right p-10");
    setOverlay(false);
  }
  function logOut() {
    dispatch(clientPortalAuth.actions.clientportallogout());
    history.push("/clientportal/login");
  }

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <div className="aside aside-left  aside-fixed d-flex flex-column flex-row-auto">
            <div className="brand flex-column-auto " id="kt_brand">
              <a className="brand-logo" href="/">
                <img alt="logo" src="/media/logos/logo-light.png" />
              </a>
            </div>
          </div>
          <div className="d-flex flex-column flex-row-fluid wrapper">
            <div className="header">
              <div className="container d-flex align-items-stretch justify-content-end mx-0 w-100 mw-100">
                <div
                  className="header-progress-bar"
                  style={{ height: "3px", width: "100%" }}
                ></div>
                <div className="w-70 d-flex">
                  <button
                    type="button"
                    className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                    id="kt_quick_user_toggle"
                    onClick={showProfile}
                  >
                    <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                      Hi,
                    </span>
                    <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                      {props.customer.name.split(" ")[0]}{" "}
                    </span>
                    <span className="symbol symbol-35 symbol-light-success">
                      <span className="symbol-label font-size-h5 font-weight-bold">
                        {props.customer.name[0].toUpperCase()}
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="content  d-flex flex-column flex-column-fluid">
              <div
                id="kt_subheader"
                className="subheader py-2 py-lg-4   subheader-solid"
              >
                <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                  <div className="font-weight-bold font-size-h6 text-dark text-hover-primary link-dark nav-item dropdown"></div>
                  <div className="d-flex align-items-center">
                    <a
                      href="#"
                      className="btn btn-light btn-sm font-weight-bold"
                      id="kt_dashboard_daterangepicker"
                      data-toggle="tooltip"
                      title="Select dashboard daterange"
                      data-placement="left"
                      onClick={(event) => event.preventDefault()}
                      style={{ marginRight: "15px" }}
                    >
                      <span
                        className="text-muted font-weight-bold mr-2"
                        id="kt_dashboard_daterangepicker_title"
                      >
                        Today
                      </span>
                      <span
                        className="text-primary font-weight-bold"
                        id="kt_dashboard_daterangepicker_date"
                      >
                        {formattedDate}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column-fluid">
                <div className="container">
                  <div className="card card-custom gutter-b">
                    <div className="card-header">
                      <div className="card-title">
                        <h3 className="card-label">
                          <b>New Booking</b>
                        </h3>
                      </div>
                    </div>
                    {!companyDataArrived ? (
                      <CompanySelect />
                    ) : (
                      <BookingComponent />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {Overlay ? <div className="offcanvas-overlay"></div> : <div></div>}

      <div id="kt_quick_user" className={ProfileClass}>
        <div
          className="offcanvas-header d-flex align-items-center justify-content-between pb-5"
          kt-hidden-height="43"
        >
          <h3 className="font-weight-bold m-0">User Profile</h3>
          <button
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
            onClick={hideProfile}
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </button>
        </div>
        <div
          className="offcanvas-content pr-5 mr-n5 scroll ps"
          style={{ height: "817px" }}
        >
          <div className="d-flex align-items-center mt-5">
            <div
              className="symbol symbol-100 mr-5"
              style={{ cursor: "pointer" }}
            >
              <div className="symbol-label">
                <img
                  src="https://www.sendatradie.com/wp-content/uploads/logo-new.svg"
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              <i className="symbol-badge bg-success"></i>
            </div>
            <div className="d-flex flex-column">
              <a
                href="#"
                className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
                {props.customer.name.split(" ")[0]}{" "}
              </a>
              <button
                className="btn btn-light-primary btn-bold"
                onClick={logOut}
              >
                Sign out
              </button>
            </div>
          </div>
          <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
            <div
              className="ps__thumb-x"
              tabIndex="0"
              style={{ left: "0px", width: "0px" }}
            ></div>
          </div>
          <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}>
            <div
              className="ps__thumb-y"
              tabIndex="0"
              style={{ top: "0px", height: "0px" }}
            ></div>
          </div>
        </div>
      </div>
      <NewBookingModal
        show={showBooking}
        selectedCompany={selectedCompany}
        selectedTimezone={selectedTimezone}
        onHide={() => setShowBooking(false)}
        typeOptions={typeOptions}
        start={start}
        end={end}
      />
    </>
  );
}
