import React, { useState, useEffect } from "react";
import * as requestFromServer from "./../modules/Jobs/_redux/customers/customersCrud.js";
import { useLocation } from "react-router-dom";

export default function UnsubscribePage() {
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const company_id = searchParams.get("company");
  const email = searchParams.get("email");

  const unsubscribe = () => {
    setLoading(true);
    requestFromServer
      .unsubscribe({ company_id, email })
      .then((res) => {
        setUnsubscribed(true);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const resubscribe = () => {
    setLoading(true);
    requestFromServer
      .resubscribe({ company_id, email })
      .then((res) => {
        setLoading(false);
        setUnsubscribed(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  return (
    <div className="card">
      <div className="card-body p-lg-17">
        <div className="d-flex flex-column">
          <div className="mb-10 text-center">
            <h3 className="mb-3">
              {unsubscribed
                ? "You have been unsubscribed."
                : "Are you sure you want to unsubscribe from all email communications?"}
            </h3>

            {unsubscribed ? (
              <button
                className="btn btn-light-success"
                type="button"
                disabled={loading}
                onClick={() => resubscribe()}
              >
                Resubscribe
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm ml-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )}
              </button>
            ) : (
              <button
                className="btn btn-light-danger"
                type="button"
                disabled={loading}
                onClick={() => unsubscribe()}
              >
                Unsubscribe
                {loading ? (
                  <span
                    className="spinner-border spinner-border-sm ml-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
