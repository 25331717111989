import React from "react";
import axios from "axios";
var moment = require("moment");
export function emailTemplateAddon(props) {
  //hideconsole.log(props.match.params.id);
  //hideconsole.log(moment.utc().format("DD/MM/YYYY HH:mm:ss"));

  return (
    <>
      <img src="https://www.sendatradie.com/wp-content/uploads/internet.png" />
    </>
  );
}
