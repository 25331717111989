import React from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSelector } from "react-redux";
import CustomBootstrapTable from "./../../../../_metronic/_helpers/CustomBootstrapTable.js";

export function ManageLocations({
  locations,
  ids,
  setIds,
  columns,
  showManageLocations,
  setShowManageLocations,
}) {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <br />
          <Card>
            <CardHeader title="Business Locations">
              <CardHeaderToolbar>
                {" "}
                <button
                  type="button"
                  className="btn btn-light-primary btn-elevate ml-3"
                  onClick={() => {
                    setShowManageLocations(false);
                  }}
                >
                  Close
                </button>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <CustomBootstrapTable
                data={locations}
                columns={columns}
                ids={ids}
                setIds={setIds}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
