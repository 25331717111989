import React, { useState } from "react";

import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory, Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import { requestPassword, checkEmail } from "./../../_redux/authCrud.js";
import * as auth from "./../../_redux/authRedux.js";

import "../LoginFlow/LoginPage.scss";

function ForgotPasswordPage(props) {
  const { intl } = props;
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [isRequested, setIsRequested] = useState(false);

  const handleSubmit = () => {
    // //hideconsole.log("forgitpassword");
    checkEmail(email)
      .then((res) => {
        // //hideconsole.log(res.data);
        if (res.data.length) {
          requestPassword(email)
            .then(() => setIsRequested(true))
            .catch(() => {
              setIsRequested(false);
            });
          localStorage.setItem("forgotemail", email);
          history.push("/auth/verifyOTP");
        } else {
          toast.error("Email does not exist");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <>
          <div
            style={{ height: "calc(100% - 170px)" }}
            className="d-flex align-items-center flex-column"
          >
            <div>
              <h1 className="heading_register">Password Reset</h1>
            </div>
            <div className="sub_head_reg">No worries.</div>
            <div className="mt-1 sub_head_reg2">
              We’ll email you instructions to reset your password
            </div>

            <div className=" w-100 d-flex mainContainer mt-5  flex-column logout_container">
              <div className="mt-4 w-100">
                <input
                  className="__input_auth"
                  placeholder="Email Address"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>

              <div
                className="login_button w-100 text-center"
                onClick={
                  email
                    ? handleSubmit
                    : () => {
                        toast.error("email or password is empty");
                      }
                }
              >
                <span className="login_font">Reset Password</span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

// export default ForgotPasswordPage

export default injectIntl(connect(null, auth.actions)(ForgotPasswordPage));
