


/**
 * Denotes what auth flow is current. Used for conditional rendering.
 */
export const AuthFlowType = {
    Registration: {
        LandingPage: {
            type: "LandingPage",
            headerTitle: "Registration"
        },
        Steps: {
            GeneralInfo: {
                type: "Step 1: Your General Information",
                headerTitle: "Registration"
            },
            Profession: {
                type: "Step 2: Your Profession",
                headerTitle: "Registration"
            },
            JobTypes: {
                type: "Step 3: Your Job Types",
                headerTitle: "Registration"
            }
        }
    },
    Login: {
        LandingPage: {
            type: "Login",
            headerTitle: ""
        },
    },
    Blank: {
        type: "Login",
        headerTitle: ""
    }
}

/**
 * Finds data related to currently active step for rendering
 * @param {*} stepId 
 * @returns 
 */
export function FindCurrentStep(stepId) {
    if (stepId === STEP.ONE_GENERAL)
        return AuthFlowType.Registration.Steps.GeneralInfo;
    else if (stepId === STEP.TWO_PROFESSION)
        return AuthFlowType.Registration.Steps.Profession;
    else if (stepId === STEP.THREE_JOB_TYPES)
        return AuthFlowType.Registration.Steps.JobTypes;
    else if (stepId === STEP.FOUR_CODE)
        return AuthFlowType.Registration.Steps.JobTypes;
    else return AuthFlowType.Blank;
};


// Registration step identifiers
export const STEP = {
    ONE_GENERAL: 1,
    TWO_PROFESSION: 2,
    THREE_JOB_TYPES: 3,
    FOUR_CODE: 4
};

export const STEP_LABELS = ["General Info", "Profession", "Job Types", "Code Verification"];
