import { QrCodeScannerOutlined } from "@mui/icons-material";
import React, { useMemo, useState, useEffect } from "react";
import { LeadsEditModal } from "./LeadsEditModal.js";

export function TablesGrouping({ value, getData }) {
  const [showEdit, setShowEdit] = useState(false);
  const handleEditModal = () => {
    setShowEdit(!showEdit);
  };

  return (
    <>
      <div className="form">
        <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
          <div className="col-xl-12 mt-3">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bolder font-size-sm"
              disabled={value?.length != 1}
              onClick={handleEditModal}
            >
              <i className="fa fa-edit"></i> Edit
            </button>
            &nbsp;
          </div>
        </div>
      </div>
      <LeadsEditModal
        show={showEdit}
        onHide={handleEditModal}
        id={value?.[0]}
        getData={getData}
      />
    </>
  );
}
