import React, { useState, useEffect } from "react";
import { FaRegCircle } from "react-icons/fa";
import Autocomplete from "react-autocomplete"; // Ensure this is installed and imported
import { toast } from "react-toastify";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import StepHeader from "./StepHeader";
import ac from "../../icons/air-conditioner.svg";
import carpet from "../../icons/carpet.png";
import key from "../../icons/key.png";
import clean from "../../icons/sweeping.png";
import tap from "../../icons/water-tap.png";

function JobTypes({ setJobTypes, jobTypes, profession }) {
  const MAX_ENTRIES = 50;
  const MIN_ENTRIES = 2;

  const handleChange = (index, field, value) => {
    const newJobTypes = [...jobTypes];
    newJobTypes[index][field] = value;
    setJobTypes(newJobTypes);
  };

  const addField = () => {
    if (jobTypes.length < MAX_ENTRIES) {
      setJobTypes([...jobTypes, { name: "" }]);
    } else {
      toast.error(`You can only add up to ${MAX_ENTRIES} job types.`);
    }
  };

  const removeField = (index) => {
    if (jobTypes.length > MIN_ENTRIES) {
      setJobTypes(jobTypes.filter((_, i) => i !== index));
    } else {
      toast.error(`You must have at least ${MIN_ENTRIES} job types.`);
    }
  };

  const labelStyling = {
    fontSize: "16px",
    marginBottom: "8px",
    fontFamily: "Poppins, Sans-serif",
    background: "none",
    width: "100px",
    display: "block",
  };

  const formGroupStyling = {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
    textAlign: "left",
    width: "400px",
  };

  return (
    <>
      <StepHeader
        title="Tell me, what kind of work do you do?"
        subtitle="This helps us customize Sendatradie for your needs"
        relativeImgPath="/icons/sendie3.webp"
      />
      <button
        type="button"
        className="btn btn-light-primary add-button"
        onClick={addField}
      >
        + Add another
      </button>
      <div className="step_form job-type-form">
        <TransitionGroup>
          <div className="job-type-container">
            {jobTypes.map((jobType, index) => (
              <CSSTransition key={index} timeout={300} classNames="transition">
                <div className="job-type-input">
                  <Autocomplete
                    getItemValue={(item) => item}
                    items={[]} // This should be dynamically filled as needed
                    renderItem={(item, isHighlighted) => (
                      <div
                        style={{
                          background: isHighlighted ? "lightgray" : "white",
                        }}
                      >
                        {item}
                      </div>
                    )}
                    value={jobType.name}
                    onChange={(e) =>
                      handleChange(index, "name", e.target.value)
                    }
                    onSelect={(value) => handleChange(index, "name", value)}
                    inputProps={{
                      className: "form-control __input_auth",
                      placeholder: "Enter Job Type...",
                      style: { width: "200px" },
                    }}
                  />
                  <button
                    type="button"
                    disabled={jobTypes.length === MIN_ENTRIES}
                    className="btn btn-icon btn-light btn-close"
                    onClick={() => removeField(index)}
                  >
                    <i className="ki ki-close icon-sm" />
                  </button>
                </div>
              </CSSTransition>
            ))}
          </div>
        </TransitionGroup>
      </div>
    </>
  );
}

export default JobTypes;
