import React, { useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { IconButton } from "@material-ui/core";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import dayjs from "dayjs";
import { toast } from "react-toastify";
export function CreateLeave({ setAdding, fetchedLeaves, worker, fetchData }) {
  const handleLeaveAdd = async () => {
    //hideconsole.log(worker);
    let fetchedLeavesTemp = [...fetchedLeaves];
    fetchedLeavesTemp.push({
      date: chosenDate,
      dateFormatted: dayjs(chosenDate).format("DD-MM-YYYY"),
    });
    axios
      .post("https://www.api.sendatradie.com/company/addLeave", {
        leavesData: JSON.stringify(fetchedLeavesTemp),
        workerId: worker.value,
      })
      .then(async (res) => {
        //hideconsole.log("posted leave succesfully");
        toast.success("Leave Created");
        await fetchData();
        setAdding(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [chosenDate, setChosenDate] = useState(null);
  useEffect(() => {
    if (chosenDate) {
      //hideconsole.log("chosen date is", chosenDate);
      // Format the chosen date using dayjs
      const formattedDate = dayjs(chosenDate).format("DD-MM-YYYY");
      //hideconsole.log("chosen date is", formattedDate);
    }
  }, [chosenDate]);
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="row">
          <div className="col-3 d-flex align-items-center">
            Choose Leave Date{" "}
          </div>
          <div className="col-3">
            <DatePicker
              onChange={(e) => {
                //hideconsole.log("chosen Date is", e);
                setChosenDate(e);
              }}
              value={chosenDate}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div>
            <IconButton color="primary" onClick={handleLeaveAdd}>
              <TaskAltIcon />
            </IconButton>
          </div>
          <div>
            <IconButton
              color="primary"
              onClick={() => {
                setAdding(false);
              }}
            >
              <CancelIcon style={{ color: "red" }} />
            </IconButton>
          </div>
        </div>
      </LocalizationProvider>
    </>
  );
}
