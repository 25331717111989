import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
export function FlowRender({
  flow,
  sendAfterOptions,
  deleteInProgress,
  setDeleteInProgress,

  handleFlowDelete,
}) {
  return (
    <>
      {flow.map((item, key) => {
        return (
          <>
            <Card>
              <CardBody>
                <div className="row">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      //hideconsole.log("key is", key);
                      handleFlowDelete(key);
                    }}
                    disabled={deleteInProgress}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </div>
                <div className="row">
                  <div className="mr-2">Template used is</div>
                  <div className="font-weight-bold">
                    {" "}
                    {item.chosenTemplate.label}
                  </div>
                </div>
                <div className="row">
                  <div className="mr-2">Send After</div>
                  <div className="">
                    {item.sendAfter[0]}{" "}
                    {
                      sendAfterOptions.filter((sfo) => {
                        return sfo.value == item.sendAfter[1];
                      })[0].label
                    }
                  </div>
                </div>
              </CardBody>
            </Card>
          </>
        );
      })}
    </>
  );
}
