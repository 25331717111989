import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Switch } from "@mui/material";
import { useDropzone } from "react-dropzone";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";

// Define the initial image URLs
const initialImageUrls = [
  "https://storage.googleapis.com/platform-295512.appspot.com/fbshare/mar-bustos-ARVFsI-32Uk-unsplash.jpg",
  "https://storage.googleapis.com/platform-295512.appspot.com/fbshare/juskteez-vu-TIrXot28Znc-unsplash.jpg",
  "https://storage.googleapis.com/platform-295512.appspot.com/fbshare/hanna-IdIosWNwP-Y-unsplash.jpg",
  "https://storage.googleapis.com/platform-295512.appspot.com/fbshare/aedrian-RPokxvk8BCE-unsplash.jpg",
];

// Review text and stars
const reviewText = "This service exceeded our expectations! The team was professional, efficient, and delivered high-quality results that truly enhanced our operations. The attention to detail and dedication to customer satisfaction were evident throughout the entire process. We highly recommend their services to anyone looking for reliable and top-notch solutions!";
const reviewSource = "Sendatradie Demo";

export function ChooseFBTemplate() {
  const [imageUrls, setImageUrls] = useState(initialImageUrls);
  const [selectedImage, setSelectedImage] = useState(imageUrls[0]);
  const [enableBG, setEnableBG] = useState(false);
  const [show, setShow] = useState(false);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImageUrl = e.target.result;
        setImageUrls((prevImageUrls) => [...prevImageUrls, newImageUrl]);
        setSelectedImage(newImageUrl);
        toast.success("Image added successfully!");
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = (imageToRemove) => {
    setImageUrls((prevImageUrls) =>
      prevImageUrls.filter((image) => image !== imageToRemove)
    );
    if (selectedImage === imageToRemove) {
      setSelectedImage(null);
    }
    toast.error("Image removed successfully!");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  function onHide() {
    setShow(false);
  }

  return (
    <>
      <Card>
        <CardHeader title="Reposting on Facebook">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-light-primary btn-elevate ml-3"
              onClick={() => setShow(true)}
            >
              Change Background
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div style={styles.imageGallery}>
            <div
              style={{
                ...styles.selectedImage,
                backgroundImage: selectedImage ? `url(${selectedImage})` : "none",
              }}
            >
              <div
                style={enableBG ? styles.reviewContainerWithBG : styles.reviewContainer}
              >
                <div style={styles.stars}>⭐⭐⭐⭐⭐</div>
                <div style={styles.reviewText}>{reviewText}</div>
                <div style={styles.reviewSource}>{reviewSource}</div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      <Modal show={show} onHide={onHide} dialogClassName="modal-90w" aria-labelledby="modal-title">
        <Modal.Header closeButton>
          <Modal.Title id="modal-title">Edit Background</Modal.Title>
        </Modal.Header>
        <Modal.Body>
  <div className="row align-items-center">
    <div className="col-sm-12 mb-3">
      Enable Overlay
      <Switch
        checked={enableBG}
        onChange={(event) => setEnableBG(event.target.checked)}
      />
    </div>
    <div className="col-sm-12 mb-3">
      <div
        {...getRootProps({
          className: 'dropzone',
          style: {
            ...styles.dropzone,
            ...(isDragActive ? styles.dropzoneActive : {}),
          },
        })}
      >
        <input {...getInputProps()} />
        <p>Drag 'n' drop an image here, or click to select one</p>
      </div>
    </div>
    <div className="col-sm-12">
      <div className="row">
        {imageUrls.map((image, index) => (
          <div key={index} className="col-md-4"> {/* Set each item to occupy 1/3 of the width */}
            <div style={styles.imageListItemWrapper}>
              <div
                onClick={() => handleImageClick(image)}
                style={{
                  ...styles.imageListItem,
                  backgroundImage: `url(${image})`,
                  border: selectedImage === image ? "2px solid #333" : "none",
                }}
              >
                <div style={enableBG ? modalStyles.reviewContainerWithBG : modalStyles.reviewContainer}>
                  <div style={modalStyles.stars}>⭐⭐⭐⭐⭐</div>
                  <div style={modalStyles.reviewText}>Sample</div> {/* Show "Sample" in modal */}
                  <div style={modalStyles.reviewSource}>{reviewSource}</div>
                </div>
              </div>
              <button
                onClick={() => removeImage(image)}
                className="btn btn-danger btn-sm"
                style={modalStyles.removeButton}
              >
                Remove
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
</Modal.Body>



        <Modal.Footer>
          <button onClick={onHide} className="btn btn-primary btn-elevate ml-3">
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
}

const modalStyles = {
  stars: {
    fontSize: "8px", // Smaller stars size in the modal
    marginBottom: "5px",
  },
  reviewText: {
    fontSize: "10px", // Smaller review text in the modal
    marginBottom: "5px",
  },
  reviewSource: {
    fontSize: "4px", // Smaller font size for review source
    fontWeight: "bold",
  },
  removeButton: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    zIndex: 3, // Ensure the button is on top of other elements
    padding: '3px 5px', // Smaller button size in the modal
    fontSize: '8px', // Smaller font size in the modal
  },
  reviewContainer: {
    display: 'flex',
    flexDirection: 'column', // Stack stars, text, and source vertically
    justifyContent: 'center', // Vertically center the content
    alignItems: 'center', // Horizontally center the content
    height: '100%', // Ensure it takes up the entire height of the image
    color: 'white', // Default text color when overlay is not enabled
  },
  reviewContainerWithBG: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5px 30px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // White background with transparency
    borderRadius: '8px',
    color: 'black', // Text color for better readability on overlay
    maxWidth: '80%',
    margin: '0 auto', // Center horizontally
    position: 'absolute', // Absolute positioning for vertical centering
    top: '50%', // Move to the middle of the container
    left: '50%', // Center horizontally
    transform: 'translate(-50%, -50%)', // Ensure the content is centered both vertically and horizontally
  },
  
  
  
  



};


// Styles object
const styles = {
  imageGallery: {
    display: "flex",
  },
  imageList: {
    overflowY: "scroll",
    padding: "10px",
    maxHeight: "70vh",
  },
  imageListItemWrapper: {
    width: "100%",
    paddingBottom: "100%",
    position: "relative",
    marginBottom: "10px",
  },
  imageListItem: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "8px",
    cursor: "pointer",
  },
  selectedImage: {
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "300px",
    position: "relative",
    borderRadius: "8px",
    overflow: "hidden",
  },
  reviewContainer: {
    position: "relative",
    zIndex: 2, // Ensure text is on top
    textAlign: "center",
    color: "white", // Text color for better contrast
  },
  reviewContainerWithBG: {
    position: "relative",
    zIndex: 2, // Ensure text is on top
    textAlign: "center",
    color: "black", // Text color for readability on white background
    padding: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.8)", // White background with some transparency
    borderRadius: "8px",
  },
  stars: {
    fontSize: "24px",
    marginBottom: "10px",
  },
  reviewText: {
    fontSize: "16px",
    marginBottom: "10px",
  },
  reviewSource: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  dropzone: {
    border: "2px dashed #cccccc",
    borderRadius: "8px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
    transition: "border-color 0.3s ease",
  },
  dropzoneActive: {
    borderColor: "#333333",
  },
  removeButton: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    zIndex: 3, // Ensure the button is on top of other elements
    padding: "5px 10px",
  },
};
