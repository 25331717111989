import React from "react";
import { useLocation } from "react-router";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../../../../_metronic/layout/index.js";
import AuthHeader from "./AuthHeader.js";
import LoginPage from "./LoginFlow/LoginPage.js";
import SignUpPage from "./SignUpFlow/RegistrationStart.js";
import SignUpStep from "./SignUpFlow/Steps/StepManager.js";
import ForgotPasswordPage from "./ForgotPassword/ForgotPasswordPage.js";
import OtpverificationPage from "./SignUpFlow/OtpverificationPage.js";
import NewAuth from "./ChangePassword/UpdatePassword.js";
import TermsConditions from "../../../pages/TermsConditions.js";
import PrivacyPolicy from "../../../pages/PrivacyPolicy.js";
import CustomFooter from "../../../partials/Footer.js";
import { AuthFlowType } from "../../../../utils/authHelpers.js";

function AuthenticationPage() {
  const location = useLocation();
  const footerHeight = 80; // Adjust the footer height as needed

  return (
    <div className="app">
      {location.pathname.includes("/auth/login") && 
       <AuthHeader content={AuthFlowType.Login.LandingPage} />}   

      {location.pathname.includes("/auth/signup") && 
       !location.pathname.includes("step") && 
       <AuthHeader content={AuthFlowType.Registration.LandingPage} />}
       
      <div className="main-content bg-white" style={{ minHeight: `calc(100vh - (${footerHeight}px + 140px))` }}>
        <Switch>
          <ContentRoute path="/auth/login" component={LoginPage} />
          <ContentRoute path="/auth/signup/step" component={SignUpStep} />
          <ContentRoute path="/auth/signup" component={SignUpPage} />
          <ContentRoute path="/auth/forgotpassword" component={ForgotPasswordPage} />
          <ContentRoute path="/auth/verifyOTP" component={OtpverificationPage} />
          <ContentRoute path="/auth/newAuth" component={NewAuth} />
          <ContentRoute path="/terms-and-conditions" component={TermsConditions} />
          <ContentRoute path="/privacy-policy" component={PrivacyPolicy} />
          <Redirect from="/auth" exact={true} to="/auth/login" />
          <Redirect to="/auth/login" />
        </Switch>
      </div>
      
      <CustomFooter />
    </div>
  );
}

export default AuthenticationPage;
