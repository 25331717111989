import * as requestFromServer from "./SettingsCrud.js";
import { settingSlice } from "./../../../redux/slice/settingSlice.js";
import { toast } from "react-toastify";
import moment from "moment";

const { actions } = settingSlice;

export const fetchRolePermissions = () => (dispatch) => {
  return requestFromServer
    .getRolePermissions()
    .then((res) => {
      const { data, roles } = res?.data;
      console.log(data);
      dispatch(actions?.rolePermissionsFetched(data));
      dispatch(actions?.roleOptionsFetched(roles));
    })
    .catch((error) => {
      if (
        error?.response?.data?.message == "User not authorized." ||
        error?.response?.data?.message == "No token provided."
      ) {
      } else {
        toast.error(error?.response?.data?.message);
      }
    });
};

export const editRolePermissions = (data) => (dispatch) => {
  return requestFromServer
    .editRolePermissions(data)
    .then((res) => {
      const { data } = res.data;
      dispatch(actions.rolePermissionsFetched(data));
      toast.success("Success.");
    })
    .catch((error) => {
      if (error?.response?.data?.message == "User not authorized.") {
      } else {
        toast.error(error?.response?.data?.message);
      }
    });
};
