import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import CustomAxios from "../../../utils/axios";
import axios from "axios";
let isApiCall = true;
function ReferAFriend() {
  const [emailSepComma, setemailSepComma] = React.useState("");
  const [code, setCode] = React.useState("nPqLdvAILJ");

  useEffect(() => {
    axios.get("https://www.api.sendatradie.com/company/inviteTOJoin").then((res) => {
      //hideconsole.log(res.data);
      const link = res.data.data;
      // const link = "https://platform.sendatradie.com/auth/signup?code=nPqLdvAILJ";
      const [prev, code] = link ? link.split("=") : ["", ""];
      //hideconsole.log(code);
      setCode(code);
    });
  }, []);
  useEffect(() => {
    return () => {
      isApiCall = true;
    };
  }, []);

  const handle_Email_send = () => {
    if (emailSepComma) {
      if (isApiCall) {
        isApiCall = false;
        const body = { email: emailSepComma };
        CustomAxios.call("post", `company/referLink`, null, body)
          .then((res) => {
            //hideconsole.log(res);
            isApiCall = true;
            toast.success("Refer Link successfully sended.");
            setemailSepComma("");
          })
          .catch((err) => {
            console.log(err);
            toast.error("Refer Link not sended.");
          });
      }
    } else {
      toast.error("Email cannot be blank");
    }
  };

  return (
    <Card>
      <CardBody>
        <div
          style={{ height: "calc(100% - 170px)", margin: "0px 3rem" }}
          className="d-flex flex-column"
        >
          <div className="align-items-center d-flex flex-column">
            <h1 style={{ fontWeight: "900", fontSize: "2.5rem" }}>
              Refer A Friend
            </h1>

            <div className="mt-3" style={{ fontSize: "1.3rem" }}>
              Earn money for each friend you refer!
            </div>
          </div>
          <div
            className="mt-3"
            style={{
              fontSize: "1rem",
              marginLeft: "2rem",
              marginRight: "2rem",
            }}
          >
            Step 1: Invite your friend to sign up to Sendatradie using your
            unique friend code
          </div>
          <div
            className="mt-3"
            style={{
              fontSize: "1rem",
              marginLeft: "2rem",
              marginRight: "2rem",
            }}
          >
            Step 2: For each friend that signs up to the monthly plan: They will
            get a 10% discount for 2 months - You will get a 10% discount for 2
            months. For each friend that signs up to the annual plan: They will
            get 1 month FREE - You will get 1 month FREE.
          </div>
          <div
            className="d-flex flex-column align-items-center"
            style={{ marginTop: "2rem" }}
          >
            <button
              variant="contained"
              className="btn btn-primary btn-sm"
              onClick={() => {
                navigator.clipboard.writeText(
                  // "http://localhost:3000/referfriend"
                  // `http://localhost:3000/auth/signup?code=${code}`
                  `https://platform.sendatradie.com/auth/signup?refer_code=${code}`
                );
                toast.success("Copied!");
              }}
              color="primary"
            >
              Copy Invite Link
            </button>
          </div>
          <div
            className="w-100"
            style={{
              margin: "2rem",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="w-75">
              <input
                type="text"
                value={emailSepComma}
                onChange={(e) => {
                  setemailSepComma(e.target.value);
                }}
                style={{ border: "1px solid #0000001f" }}
                className="form-control w-100"
                placeholder="Seprated with comma email"
              />
            </div>
            <div className="ml-2">
              <button
                variant="contained"
                className="btn btn-primary"
                onClick={handle_Email_send}
                color="primary"
              >
                Send Invite
              </button>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default ReferAFriend;
