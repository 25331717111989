import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Paper } from "@material-ui/core/";
import { Formik, Form, Field } from "formik";
import moment from "moment";
import Select from "react-select";
import { TextField } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import LockIcon from "@material-ui/icons/Lock";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as auth from "../../Auth/_redux/authRedux";
import * as Yup from "yup";
import axios from "axios";
// FaCcStripe
import { FaCcStripe } from "react-icons/fa";
import {
  Input,
  DatePickerField,
} from "../../../../_metronic/_partials/controls";
import * as requestFromServer from "../../../modules/Jobs/_redux/customers/customersCrud";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const initialState = {
  NameOncard: "",
  CardNumber: "",
  //   BillCycle: {},
  Plan: {},
  BillingAddress: "",
  ExpirationYear: moment(new Date()).format("yyyy"),
  ExpirationMonth: moment(new Date()).format("MM"),
  CVV: "",
  price: null,
};

export const PaymentMethodModal = ({ show, onHide, requestUser }) => {
  const user = useSelector((state) => state.auth.user);
  const [initialValues, setinitialValues] = useState(initialState);
  const [expireDate, setExpireDate] = useState(
    moment().format("yyyy-MM-DDTHH:mm")
  );
  const [customerData, setcustomerData] = useState({});
  const [customerId, setcustomerId] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [paymentDone, setpaymentDone] = useState(false);
  const [newCard, setNewCard] = useState(false);

  const validateCVV = (value) => {
    let errorMessage;
    if (!/^[0-9]{3,4}$/.test(value)) {
      errorMessage = "Invalid CVV";
    }
    return errorMessage;
  };

  const validateCardNumber = (value) => {
    let errorMessage;
    if (!/^[0-9]{16}$/.test(value)) {
      errorMessage = "Invalid card number ";
    }
    return errorMessage;
  };

  const handleDateChange = (date, type) => {
    const data = initialValues;
    let ChnageFormateWiseDate;
    //hideconsole.log(data);
    //hideconsole.log(date);
    if (type === "ExpirationYear") {
      ChnageFormateWiseDate = moment(date).format("yyyy");
      setExpireDate(
        moment(expireDate)
          .set("year", moment(date).year())
          .format("yyyy-MM-DDTHH:mm")
      );
    } else {
      ChnageFormateWiseDate = moment(date).format("MM");
      setExpireDate(
        moment(expireDate)
          .set("month", moment(date).month())
          .format("yyyy-MM-DDTHH:mm")
      );
    }

    data[type] = ChnageFormateWiseDate;
    setinitialValues(data);
  };

  const modalClose = () => {
    setExpireDate(moment().format("yyyy-MM-DDTHH:mm"));
    setinitialValues(initialState);
    onHide();
  };

  const getCustomerId = () => {
    requestFromServer
      .createCustomerId()
      .then((response) => {
        //hideconsole.log(response.data.result, "response.SMSdata.result.");
        if (response.data.result.length) {
          //hideconsole.log(response.data.result[0]);
          setcustomerData(response.data.result[0]);

          setcustomerId(response.data.result[0].stripe_customer_id);
        } else {
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });
  };
  useEffect(() => {
    getCustomerId();
  }, []);

  const handlePayment = async (value) => {
    try {
      //get data from Form
      setSubmitting(true);

      //hideconsole.log(value);
      let customerId = customerData?.stripe_customer_id;
      let cardToken;
      const data = {
        ...initialValues,
        BillingAddress: value.BillingAddress,
        CVV: value.CVV,
        CardNumber: value.CardNumber,
        NameOncard: value.NameOncard,
      };
      setinitialValues(data);
      //hideconsole.log(data);
      //hideconsole.log(initialValues);

      const tokenParams = {
        card: {
          number: data.CardNumber,
          exp_year: data.ExpirationYear,
          exp_month: data.ExpirationMonth,
          cvc: data.CVV,
        },
        type: "card",
      };

      const createPayment = await requestFromServer.stripeCreateToken(
        tokenParams
      );

      cardToken = createPayment.data.id;

      postPaymentRequestApi(customerId, cardToken);
    } catch (err) {
      console.log(err);
      //hideconsole.log("payment failed---------------------");
      toast.error("Adding payment method failed.");
      setSubmitting(false);
    }
  };

  const postPaymentRequestApi = (CustomerId, token) => {
    if (submitting) return;
    const passDate = {
      customerId: CustomerId,
      pack: token,
    };

    //hideconsole.log("passDate", passDate);
    requestFromServer
      .newPaymentMethod(passDate)
      .then((response) => {
        setSubmitting(false);
        modalClose();
        getCustomerId();
        toast.success("New payment methode saved.");
      })
      .catch((error) => {
        setSubmitting(false);
        console.log(error);
        toast.error("Adding payment method failed.");
        if (error?.response?.data?.message == "User not authorized.") {
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      show={show}
      onHide={modalClose}
    >
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          New Payment Method
        </Modal.Title>
        <button
          type="button"
          className="btn btn-light btn-close mr-0"
          aria-label="Close"
          onClick={modalClose}
        >
          x
        </button>
      </Modal.Header>
      <Paper square>
        <Formik
          enableReinitialize={false}
          initialValues={initialValues}
          onSubmit={(values) => {
            handlePayment(values);
          }}
        >
          {({ handleSubmit, errors, touched, values }) => (
            <>
              <Form className="form form-label-right">
                <Modal.Body>
                  <>
                    <div className="col-lg-6">
                      <label>Name on Card</label>
                      <Field
                        name="NameOncard"
                        className="form-control"
                        component={Input}
                        withFeedbackLabel={false}
                      />
                    </div>
                    <div className="col-lg-6 mt-3">
                      <label>Card Number</label>
                      <Field
                        type="number"
                        name="CardNumber"
                        validate={validateCardNumber}
                        className="form-control"
                        component={Input}
                        withFeedbackLabel={false}
                      />
                      {errors.CardNumber && touched.CardNumber ? (
                        <div className="text-danger">{errors.CardNumber}</div>
                      ) : null}
                    </div>
                    <div className="row mt-3" style={{ marginLeft: "0.5px" }}>
                      <div className="col-lg-4">
                        <label>Expiration Month</label>
                        <KeyboardDatePicker
                          disableToolbar
                          autoOk
                          clearable="true"
                          style={{ padding: `0.65rem 1rem` }}
                          variant="inline"
                          format="MM"
                          views={["month"]}
                          name="ExpirationMonth"
                          value={expireDate}
                          onChange={(e) => {
                            handleDateChange(e, "ExpirationMonth");
                          }}
                        />
                      </div>
                      <div className="col-lg-4">
                        <label>Expiration Year</label>
                        <KeyboardDatePicker
                          views={["year"]}
                          clearable="true"
                          style={{ padding: `0.65rem 1rem` }}
                          autoOk
                          minDate={new Date()}
                          name="ExpirationYear"
                          disableToolbar
                          variant="inline"
                          value={expireDate}
                          onChange={(e) => {
                            handleDateChange(e, "ExpirationYear");
                          }}
                        />
                      </div>
                      <div className="col-lg-4">
                        <label>CVV</label>
                        <Field
                          name="CVV"
                          validate={validateCVV}
                          className="form-control"
                          component={Input}
                          placeholder="ex. 311"
                          withFeedbackLabel={false}
                        />
                        {errors.CVV && touched.CVV ? (
                          <div className="text-danger">{errors.CVV}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-6 mt-3">
                      <label>Billing Address</label>
                      <Field
                        name="BillingAddress"
                        className="form-control"
                        component={Input}
                        withFeedbackLabel={false}
                      />
                    </div>
                  </>

                  <div className="row mt-3" style={{ marginLeft: "0.5px" }}>
                    <div className="col-lg-6">
                      <FaCcStripe size={"30"} /> Secured by Stripe
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="submit"
                    disabled={submitting}
                    className="btn btn-primary btn-elevate"
                  >
                    Save as Default
                    {submitting ? (
                      <span
                        className="spinner-border spinner-border-sm ml-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      ""
                    )}
                  </button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Paper>
    </Modal>
  );
};

export default injectIntl(connect(null, auth.actions)(PaymentMethodModal));
