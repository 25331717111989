import React from "react";
import { Modal } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import TermsConditions from "../../../../pages/TermsConditions";
import "./TermsConditionsModal.scss";

export default function TCModal(props) {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        dialogClassName="tc-modal"
        centered
      >
        <Modal.Header>
          <div className="d-flex align-items-center w-100">
            <img
              src={toAbsoluteUrl("/media/logos/logo-dark.png")}
              alt="Sendatradie Logo"
              className="mr-3"
              style={{ height: "30px" }}
            />
            <Modal.Title className="flex-grow-1">Sendatradie Terms & Conditions</Modal.Title>
            <button
              type="button"
              className="btn btn-light btn-close"
              aria-label="Close"
              onClick={props.onHide}
            >
              x
            </button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
          <TermsConditions /> {/* Render the TermsConditions component here */}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={props.onHide}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
