import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";

function ActivateJob(props) {
  //using usehistory for routing to login page
  const history = useHistory();

  useEffect(() => {
    getData();
  }, [props.match.params]);

  const getData = () => {
    if (props.match.params && props.match.params.id) {
      const id = props.match.params.id;
      //hideconsole.log(id);
      if (id === "error") {
        return;
      } else {
        axios
          .get(`https://www.api.sendatradie.com/jobs/getJobAddress/${id}`)
          .then((response) => {
            //hideconsole.log(response.data);
            window.location.replace(
              "https://maps.google.com/?q=" + response.data.data
            );
          })
          .catch(function(error) {
            console.log(error);
            if (error?.response?.data?.message == "User not authorized.") {
              // history.push("/logout");
            } else {
              //   toasterMessage("error", error.response.data.message);
            }
          });
      }
    }
  };

  return <></>;
}

export default ActivateJob;
