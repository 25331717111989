// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Paper, Tabs, Tab } from "@material-ui/core/";
import { Modal } from "react-bootstrap";
import * as requestFromServer from "./../../Jobs/_redux/customers/customersCrud.js";
import { Formik, Form, Field } from "formik";
import Select from "react-select";
import { Input } from "../../../../_metronic/_partials/controls";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

let InitialLeads = { name: null, payment: null, payment_type: null };
export function CreateLeadsForm({ show, onHide, setisCreateLeads }) {
  const [option, setOption] = useState([]);
  const [Leads, setleads] = useState(InitialLeads);
  const [actionsLoading, setactionsLoading] = useState(true);
  const history = useHistory();

  const getPaymentType = () => {
    requestFromServer
      .getPaymentTypeData()
      .then((response) => {
        //hideconsole.log(response.data.result, "response.SMSTemplatedata.result.");
        if (response.data.result.length) {
          //hideconsole.log(response.data.result);
          setOption(response.data.result);
          setactionsLoading(false);
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });
  };

  useEffect(() => {
    setleads(InitialLeads);
    if (show) {
      getPaymentType();
    }
  }, [show]);

  const handleChangePaymentType = (event) => {
    //hideconsole.log("event", event);
    setleads({ ...Leads, payment_type: event });
  };

  const CreateLeads = (data) => {
    requestFromServer
      .PostCreateLeads(data)
      .then((response) => {
        //hideconsole.log(response.data.result, "response.SMSTemplatedata.result.");
        setisCreateLeads(false);
        toast.success("Lead created");
        onHide();
        history.push("/manage/leads#leadsChange");
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.data?.message == "User not authorized.") {
          toast.error("Lead Creation Failed");
        } else {
          toast.error("Lead Creation Failed");
          //   toasterMessage("error", error.response.data.message);
        }
      });
  };

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title>Add New Leads</Modal.Title>
          <button
            type="button"
            className="btn btn-light btn-close mr-0"
            aria-label="Close"
            onClick={onHide}
          >
            x
          </button>
        </Modal.Header>

        <Paper square>
          <Formik
            enableReinitialize={false}
            initialValues={InitialLeads}
            // validationSchema={CustomerEditSchema}
            onSubmit={(values) => {
              const data = {
                payment_type: Leads?.payment_type?.value || "",
                name: values.name,
                payment: values.payment || "",
                description: values.description,
              };
              CreateLeads(data);
            }}
          >
            {({ handleSubmit, checked }) => (
              <>
                <Form className="form form-label-right">
                  <Modal.Body>
                    {actionsLoading && (
                      <div className="overlay-layer bg-transparent">
                        <div className="spinner spinner-lg spinner-success" />
                      </div>
                    )}
                    <div className="col-8">
                      <Field
                        name="name"
                        component={Input}
                        value={Leads.name}
                        placeholder="Name"
                        label="Name"
                        withFeedbackLabel={false}
                      />
                    </div>
                    <div className="col-8 mt-3">
                      <Field
                        name="payment"
                        component={Input}
                        value={Leads.payment}
                        placeholder="Payment"
                        label="Payment"
                        withFeedbackLabel={false}
                      />
                    </div>
                    <div className="col-8 mt-3">
                      <label>Payment Type</label>
                      <Select
                        classNamePrefix="mySelect"
                        name="payment_type"
                        label="Payment Type"
                        value={Leads?.payment_type}
                        onChange={handleChangePaymentType}
                        options={option?.filter((item) => item.value != 1)}
                      ></Select>
                    </div>
                    <div className="col-8 mt-3">
                      <Field
                        name="description"
                        component={Input}
                        value={Leads.description}
                        placeholder="Description"
                        label="Description"
                        withFeedbackLabel={false}
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={onHide}
                      className="btn btn-light btn-elevate"
                    >
                      Cancel
                    </button>
                    <> </>
                    <button
                      type="submit"
                      className="btn btn-primary btn-elevate"
                    >
                      Save
                    </button>
                  </Modal.Footer>
                </Form>
              </>
            )}
          </Formik>
        </Paper>
      </Modal>
    </>
  );
}
