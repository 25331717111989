import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { CircularProgress } from "@material-ui/core";
import {
  Paper,
  Divider,
  TextareaAutosize,
  makeStyles,
  Grid,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#EEF0F8",
    padding: "1rem",
    color: "black",
  },
}));

// const rawOptions = [
//   { value: "id", label: "ID (Serial)", isDisabled: false },
//   { value: "name", label: "Name", isDisabled: false },
//   { value: "main_address", label: "Main Address", isDisabled: false },
//   { value: "phone", label: "Phone", isDisabled: false },
//   { value: "email", label: "Email", isDisabled: false },
//   { value: "billing_address", label: "Billing Address", isDisabled: false },
// ];

export default function GenerateModal({
  show,
  onHide,
  handleSend,
  text,
  settextEditorValue,
  setPrompt,
  prompt,
  generating,
  generateWithAI,
}) {
  const classes = useStyles();

  const handleSubmitData = () => {
    //hideconsole.log(FileDataHeader);
    handleSend("no_send_data");
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">Generate</Modal.Title>
        <button
          type="button"
          className="btn btn-light btn-close mr-0"
          aria-label="Close"
          onClick={onHide}
        >
          x
        </button>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Paper className={classes.root} elevation={0}>
            <p style={{ color: "black" }}>
              {generating ? (
                <div className="spinner-grow spinner-grow-sm" />
              ) : (
                ""
              )}
              {text}
            </p>
          </Paper>
          <label className="mt-3">Custom Prompt</label>
          <textarea
            type="text"
            name="note"
            if="note"
            placeholder="Prompt"
            value={prompt}
            autoComplete="off"
            onChange={(e) => {
              setPrompt(e.target.value);
            }}
            className="form-control"
          ></textarea>

          <div className="row ml-0">
            <button
              type="button"
              onClick={() => generateWithAI()}
              className="btn btn-primary btn-sm mt-3"
              disabled={generating}
            >
              Regenerate
              {generating ? (
                <div className="spinner-grow spinner-grow-sm" />
              ) : (
                ""
              )}
            </button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => {
              onHide();
            }}
            className="btn btn-light btn-sm"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => {
              settextEditorValue(text);
              onHide();
            }}
            className="btn btn-primary btn-sm ml-3"
          >
            Confirm
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
