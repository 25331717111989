import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import qs from "qs";
import styles from "../../../Invoice/pages/customers-receipt-invoice.module.scss";
import axios from "axios";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { LayoutSplashScreen } from "../../../../../_metronic/layout";
import DatePicker from "react-datepicker";
import QuotesModalPart from "./QuotesModalPart.js";
import { toast } from "react-toastify";
import { Button, TextField } from "@material-ui/core";
import moment from "moment";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CardFooter,
} from "../../../../../_metronic/_partials/controls";

function ParticularQuotes(props) {
  //using usehistory for routing to login page
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(true);
  const [QuotesData, setQuotesData] = React.useState([]);
  const [QuotesDate, setQuotesDate] = React.useState(new Date());
  const [reply, setReply] = React.useState("");
  const [comment, setComment] = useState("");
  const [onMobile, setOnMobile] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [accepted, setAccepted] = React.useState(false);

  useEffect(() => {
    getData();
    getMessages();
  }, [props.match.params]);

  const handleSubmitRequest = (type) => {
    if (props.match.params && props.match.params.id) {
      let id = props.match.params.id;
      //hideconsole.log(id);
      //hideconsole.log(QuotesDate);
      if (id === "error") {
        return;
      } else {
        const data = {
          QuotesClientDate: QuotesDate,
          reply: reply,
          status: type,
          name: QuotesData[0].name,
          email: QuotesData[0].email,
        };

        //hideconsole.log("Submit", data, QuotesData);

        var config = {
          method: "post",
          url: `https://www.api.sendatradie.com/client/QuotesStatusChange/${id}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        axios(config)
          .then((response) => {
            //hideconsole.log(response);
            if (response.data.success) {
              toast.success("Sent");
              //hideconsole.log(response.data.result);
              getMessages();
              setReply("");
            }
          })
          .catch((error) => {
            toast.error("Error in sending Quote");
            console.log(error);
          });
      }
    }
  };

  const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  const getData = () => {
    if (props.match.params && props.match.params.id) {
      const id = props.match.params.id;
      //hideconsole.log(id);
      if (id === "error") {
        return;
      } else {
        axios
          .get(`https://www.api.sendatradie.com/client/getQuote/${id}`)
          .then((response) => {
            //hideconsole.log(response.data);
            let QuotesData = {};
            // check for data if exist then enter in if.
            if (response.data.length) {
              QuotesData = response.data[0];

              setQuotesDate(
                moment(QuotesData.requestDateTime).format("yyyy-MM-DDThh:mm")
              );
              setQuotesData([QuotesData]);
              const files = QuotesData?.files?.split(",");
              if (files?.[0]) {
                setFiles(files);
                //hideconsole.log(files);
                //hideconsole.log("files-----------");
              }
              setIsLoading(false);
            } else {
              // history.push("/");
            }
          })
          .catch(function(error) {
            console.log(error);
            if (error?.response?.data?.message == "User not authorized.") {
              // history.push("/logout");
            } else {
              //   toasterMessage("error", error.response.data.message);
            }
          });
      }
    }
  };

  const getMessages = () => {
    const quoteid = props.match.params.id;
    axios
      .post(`https://www.api.sendatradie.com/client/fetchQuotesData`, {
        quoteid,
      })
      .then((res) => {
        const messagearr = JSON.parse(res.data.data[0].message);
        //hideconsole.log(messagearr);
        setComment(messagearr);
        setAccepted(res.data.data[0].status == "Accepted");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setOnMobile(deviceType() == "mobile");
  }, []);

  return (
    <>
      {!isLoading ? (
        <div
          // style={{
          //   background: "white",
          //   paddingBottom: "20px",
          //   // display: "flex",
          //   height: "94%",
          //   width: "101%",
          // }}
          className="container"
        >
          <Card>
            <CardHeader title="Quote"></CardHeader>

            <CardBody>
              <div className="row">
                <div className={onMobile ? "col-12 " : "col-4"}>
                  <Card>
                    <CardBody>
                      <div className="form-group col-xs-3 w-100">
                        <div className="col-12 w-100">
                          <div>
                            <label>Request Date/time</label>
                          </div>
                          <TextField
                            id="datetime-local"
                            type="datetime-local"
                            value={QuotesDate}
                            // autoOk
                            disabled={accepted}
                            variant="outlined"
                            className="w-100 mt-0"
                            margin="dense"
                            onChange={(e) => {
                              setQuotesDate(e.target.value);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </div>
                        <div className="col-lg-12 w-100 mt-3">
                          <label>Send a message to the company</label>
                          <textarea
                            type="text"
                            name="reply"
                            value={reply}
                            onChange={(e) => {
                              setReply(e.target.value);
                            }}
                            className="form-control"
                          ></textarea>
                        </div>
                        <div className="col-lg-12 w-100 mt-3">
                          <button
                            type="button"
                            className="btn btn-success btn-sm"
                            disabled={accepted}
                            onClick={() => {
                              handleSubmitRequest("Accepted");
                            }}
                          >
                            {accepted ? "Accepted" : "Accept"}
                          </button>
                          <button
                            type="button"
                            className="ml-2 btn btn-light btn-sm"
                            onClick={() => {
                              handleSubmitRequest(
                                accepted ? "Accepted" : "Responded"
                              );
                            }}
                          >
                            Send Message
                          </button>
                        </div>

                        <div className="col-lg-12 w-100 mt-4">
                          <label>Previous messages</label>
                          <div
                            className="col-12 pl-0"
                            style={{
                              overflow: "scroll",
                            }}
                          >
                            {comment &&
                              comment.map((data, index) =>
                                Object.keys(data).map((k, index) => (
                                  <div key={index}>
                                    <div>
                                      <b>{k}:</b>
                                    </div>
                                    <div>{data[k].msg}</div>
                                    <hr />
                                  </div>
                                ))
                              )}
                          </div>
                        </div>
                      </div>
                      {/* </div> */}
                    </CardBody>
                  </Card>
                  {files && files.length ? <label>Files</label> : null}
                  <div className="col mt-1">
                    {files && files.length
                      ? files.map((item, index) => {
                          return (
                            <div className="row mb-2" key={index}>
                              <img
                                src={`https://storage.googleapis.com/platform-295512.appspot.com/${item}`}
                                alt="img"
                                style={{ maxWidth: "100%" }}
                              />
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
                <div className={onMobile ? "col-12 " : "col-8"}>
                  <iframe
                    id="myframe"
                    allowtransparency="true"
                    // style="background: #FFFFFF;"
                    src={
                      QuotesData[0]?.quoteFile?.startsWith("http")
                        ? QuotesData[0]?.quoteFile +
                          "#toolbar=0&navpanes=0&scrollbar=0"
                        : `https://platform-295512.appspot.com.storage.googleapis.com/${QuotesData[0].quoteFile}#toolbar=0&navpanes=0&scrollbar=0`
                    }
                    height={onMobile ? "400px" : "1024px"}
                    width="100%"
                    className="w-300"
                    style={{
                      backgroundColor: "#FFFFFF",
                      maxWidth: "800px",
                    }}
                  ></iframe>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      ) : (
        <LayoutSplashScreen />
      )}

      <div className={`${styles.footer}`}>
        <div style={{ fontWeight: "500" }} className="mt-2">
          Created Using
        </div>
        <Link to="/">
          <img
            style={{ marginTop: "-25px" }}
            alt="Logo"
            className="max-h-80px"
            src={toAbsoluteUrl("/media/logos/logo.png")}
          />
        </Link>
      </div>
    </>
  );
}

export default ParticularQuotes;
