import React from 'react';

const NoJobsOverlay = () => {
  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10
  };

  const messageBoxStyle = {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center'
  };

  return (
    <div style={overlayStyle}>
      <div style={messageBoxStyle}>
        <h2>No jobs found within the date range</h2>
      </div>
    </div>
  );
};

export default NoJobsOverlay;
