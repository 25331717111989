import React, { useState, useEffect, useRef } from "react";
import { Paper, MenuItem, TextField } from "@material-ui/core";
import Downshift from "downshift";
import _ from "lodash";
import Select from "react-select";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers/index.js";
import { requestABN, getCountry } from "../../../_redux/authCrud.js";
import StepHeader from "./StepHeader.js";

const initialValues = {
  userPhone: "",
  companyPhone: "",
  userAddress: "",
  companyName: "",
  companyAbn: "",
  companyType: "",
  timeZone: "",
  currency: "",
  country: "",
};

function GeneralInfo({
  country,
  setCountry,
  name,
  setName,
  setPhone,
  phone,
  companyName,
  companyAbn,
  setCompanyName,
  setCompanyAbn,
  optionForCountry,
}) {
  const [suggestions, setSuggestions] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const inputref = useRef();

  // const formInputStyling = {
  //   width: "400px",
  //   height: "50px",
  //   borderRadius: "4px",
  // };

  const labelStyling = {
    fontSize: "16px",
    marginBottom: "8px",
    fontFamily: "Poppins, Sans-serif",
    background: "none",
    width: "200px",
    display: "block",
  };

  const formGroupStyling = {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
    textAlign: "left",
    width: "400px",
  };

  const companyNameLabel = (
    <>
      <label htmlFor="companyName" className="label" style={labelStyling}>
        Company Name
      </label>
    </>
  );

  const handleCountryChange = (e) => {
    setCountry(e.value);
  };

  function onSearchChange(ev) {
    const q = ev.target.value;
    //hideconsole.log(q);
    setSearchVal(q);
    const debounceData = _.debounce(() => {
      if (q.length > 1) {
        requestABN(q)
          .then((result) => {
            var array = [];
            result.data.forEach(function(value) {
              array.push({
                label:
                  value.Name + "(" + value.State + "-" + value.Postcode + ")",
                value: value.Abn,
              });
            });
            if (array.length) {
              // setIsOpen(true);
              setSuggestions(array);
            }
          })
          .catch(() => {
            // setIsOpen(false);
            setSuggestions([]);
          });
      } else {
        // setIsOpen(false);
        setSuggestions([]);
      }
    }, 1000);
    debounceData();
  }

  function renderSuggestion(suggestionProps) {
    const {
      suggestion,
      index,
      itemProps,
      highlightedIndex,
      selectedItem,
    } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || "").indexOf(suggestion.label) > -1;
    return (
      <MenuItem
        {...itemProps}
        key={`suggestion1${suggestion.value}`}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {suggestion.label}
      </MenuItem>
    );
  }

  function renderInput(inputProps) {
    const { InputProps, classes, ref, ...other } = inputProps;

    return (
      <TextField
        className="__input_step"
        onChange={onSearchChange}
        InputProps={{
          inputRef: ref,
          ...InputProps,
        }}
        {...other}
      />
    );
  }

  function getSuggestions(value, { showEmpty = false } = {}) {
    return suggestions;
  }

  return (
    <>
      <StepHeader
        title="Let's Get Started with Your Information"
        subtitle="Please provide your personal and company details to help us set up your profile."
      />
      <div className="step_form">
        <div className="form-group" style={formGroupStyling}>
          <label htmlFor="name" className="label" style={labelStyling}>
            Full Name
          </label>
          <input
            className="mt-4 __input_auth form-input-general"
            placeholder="Full Name"
            type="text"
            name="name"
            required
            minLength={3}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div className="form-group" style={formGroupStyling}>
          <label htmlFor="Phone" className="label" style={labelStyling}>
            Phone
          </label>
          <input
            className="mt-4 __input_auth form-input-general"
            placeholder="Phone"
            type="text"
            name="Phone"
            required
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
        </div>
        <div className="form-group" style={formGroupStyling}>
          <label htmlFor="country" className="label" style={labelStyling}>
            Country
          </label>
          <Select
            classNamePrefix="mySelect"
            options={optionForCountry}
            className="mt-4 __input_general_select form-input-general-select"
            value={optionForCountry?.find((o) => o.value == country)}
            onChange={handleCountryChange}
            required
            isSearchable
            isClearable
          />
        </div>
        <div className=" mt-4 form-group fv-plugins-icon-container">
          <Downshift id="companyName">
            {({
              getInputProps,
              getItemProps,
              getLabelProps,
              getMenuProps,
              highlightedIndex,
              inputValue,
              isOpen,
              selectedItem,
            }) => {
              const { onBlur, onFocus, ...inputProps } = getInputProps({
                placeholder: "Company Name",
                autoComplete: "off",
              });

              return (
                <div>
                  {renderInput({
                    fullWidth: true,
                    label: "",
                    InputLabelProps: getLabelProps({ shrink: true }),
                    inputProps,
                  })}

                  <div {...getMenuProps()}>
                    {isOpen ? (
                      <Paper square>
                        {getSuggestions(inputValue).map((suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({
                              item: suggestion.label,
                              value: suggestion.value,
                              onClick: () => {
                                setCompanyName(suggestion.label);
                                setCompanyAbn(suggestion.value);
                              },
                            }),
                            highlightedIndex,
                            selectedItem,
                          })
                        )}
                      </Paper>
                    ) : null}
                  </div>
                </div>
              );
            }}
          </Downshift>
        </div>
      </div>
    </>
  );
}

export default GeneralInfo;
