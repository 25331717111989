import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useHistory } from "react-router-dom";

function NewAuth() {
  const [newpwd, setnewpwd] = useState("");
  const [confirmpwd, setconfirmpwd] = useState("");
  const history = useHistory();
  const handleSubmit = () => {
    // //hideconsole.log(newpwd, confirmpwd);
    // //hideconsole.log(
    //   newpwd.length,
    //   confirmpwd.length,
    //   newpwd.toString().localeCompare(confirmpwd.toString()),
    //   newpwd === confirmpwd
    // );

    if (newpwd.length && confirmpwd.length && newpwd === confirmpwd) {
      const email = localStorage.getItem("forgotemail");
      const password = newpwd;
      axios
        .post("https://www.api.sendatradie.com/login/updatePassword ", {
          email,
          password,
        })
        .then((res) => {
          // //hideconsole.log(res);
          history.push("/auth/login");
          localStorage.setItem("forgotemail", "");
          toast.success("Password successfully changed!");
        })
        .catch((err) => {
          // toast.error("Invalid OTP")
          console.log(err);
        });
    } else {
      toast.error("Invalid passwords");
    }
  };
  return (
    <>
      <div
        style={{ height: "calc(100% - 170px)" }}
        className="d-flex align-items-center flex-column"
      >
        <h1 style={{ fontWeight: "900", fontSize: "3rem" }}>New Password</h1>
        <div className="w-50">
          <div className="mt-4 w-100">
            <input
              className="__input_auth"
              placeholder="New Password"
              type="password"
              name="newpwd"
              //   validate={validateOtpNumber}
              //   pattern="[0-9]5"
              value={newpwd}
              onChange={(e) => {
                setnewpwd(e.target.value);
              }}
            />
          </div>

          <div className="mt-4 w-100">
            <input
              className="__input_auth"
              placeholder="Confirm Password"
              type="password"
              name="confirmpwd"
              //   validate={validateOtpNumber}
              //   pattern="[0-9]5"
              value={confirmpwd}
              onChange={(e) => {
                setconfirmpwd(e.target.value);
              }}
            />
          </div>

          <div
            className="login_button w-100 text-center"
            onClick={
              newpwd && confirmpwd
                ? handleSubmit
                : () => {
                    toast.error("password is empty");
                  }
            }
          >
            <span className="login_font">Submit</span>
          </div>
        </div>
      </div>
    </>
  );
}
export default NewAuth;
