import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { ChooseWorker } from "./ChooseWorker.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IconButton } from "@material-ui/core";
import { NewDay } from "./NewDay.js";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Paper, Tabs, Tab } from "@material-ui/core/";
import { HandleLeaves } from "./HandleLeaves.js";

export function ShiftAllocationIndex() {
  const [worker, setWorker] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [editing, setEditing] = useState(false);
  const [dayChosen, setDayChosen] = useState(null);
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [tab, setTab] = useState(0);
  const [tracker, setTracker] = useState(0);
  const [dayOptions, setDayOptions] = useState([
    { label: "Monday-Friday", value: 7 },
    { label: "Saturday-Sunday", value: 8 },
    { label: "Monday", value: 0 },
    { label: "Tuesday", value: 1 },
    { label: "Wednesday", value: 2 },
    { label: "Thursday", value: 3 },
    { label: "Friday", value: 4 },
    { label: "Saturday", value: 5 },
    { label: "Sunday", value: 6 },
  ]);

  const [workerOptions, setWorkerOptions] = useState([null]);

  const fetchWorkers = async () => {
    axios
      .get("https://www.api.sendatradie.com/company/getWorkers")
      .then(async (res) => {
        const workersMapped = res.data.result.map((item) => {
          return { value: item.value, label: item.label };
        });
        setWorkerOptions(workersMapped);
        //hideconsole.log("fetched workers", workersMapped);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const fetchSchedule = async () => {
    axios
      .post("https://www.api.sendatradie.com/company/getWorkerSchedule", {
        workerId: worker.value,
      })
      .then((res) => {
        // const fetchedSchedule = JSON.parse(res.data.data.schedule);
        // //hideconsole.log("fetched schedule", fetchedSchedule);
        setSchedule(JSON.parse(res.data.data[0].schedule));
      });
  };

  const [loading, setLoading] = useState(false);

  let overLapCheck = (obj1, obj2) => {
    let x1 = obj1[0];
    let y1 = obj1[1];
    let x2 = obj2[0];
    let y2 = obj2[1];

    if (x2 == x1) return 0;

    if (x2 < x1) return overLapCheck(obj2, obj1);

    let res = 1;

    if ((x1 <= x2 && x2 < y1) || (x1 < y2 && y2 <= y1)) res = 0;
    //hideconsole.log(x1, y1, x2, y2, "res is", res);
    return res;
  };

  const handleAddSchedule = async () => {
    if (dayChosen == null) {
      toast.error("Select Day");
      return;
    } else if (startPoint == null) {
      toast.error("Select Start Time");
      return;
    } else if (endPoint == null) {
      toast.error("Select End Time");
      return;
    } else if (endPoint.value <= startPoint.value) {
      toast.error("Invalid End Time");
      return;
    }
    let scheduleTemp = schedule.map((row) => [...row]);

    //hideconsole.log(dayChosen);

    let toAdd = null;
    if (dayChosen.value < 7) {
      let dayRow = [...scheduleTemp[dayChosen.value]];
      for (let i = 0; i < dayRow.length; i++) {
        let obj = dayRow[i];
        if (!overLapCheck(obj, [startPoint.value, endPoint.value])) {
          toast.error("Overlapping Timings ");

          //hideconsole.log(obj, [startPoint.value, endPoint.value]);
          return;
        }
      }
      dayRow.push([startPoint.value, endPoint.value]);
      scheduleTemp[dayChosen.value] = dayRow;
    } else {
      if (dayChosen.value == 7) {
        for (let dayId = 0; dayId < 5; dayId++) {
          let dayRow = [...scheduleTemp[dayId]];
          for (let obj of dayRow) {
            if (!overLapCheck(obj, [startPoint.value, endPoint.value])) {
              toast.error("Overlapping Timings ");
              //hideconsole.log(obj, [startPoint.value, endPoint.value]);
              return;
            }
          }
          dayRow.push([startPoint.value, endPoint.value]);
          scheduleTemp[dayId] = dayRow;
        }
      } else {
        for (let dayId = 5; dayId < 7; dayId++) {
          let dayRow = [...scheduleTemp[dayId]];
          for (let obj of dayRow) {
            if (!overLapCheck(obj, [startPoint.value, endPoint.value])) {
              toast.error("Overlapping Timings");
              return;
            }
          }
          dayRow.push([startPoint.value, endPoint.value]);
          scheduleTemp[dayId] = dayRow;
        }
      }
    }
    toAdd = {
      day: dayChosen,
      start: startPoint.label,
      end: endPoint.label,
      startValue: startPoint.value,
      endValue: endPoint.value,
    };

    let scheduleList = [...scheduleTemp[7]];
    scheduleList.push(toAdd);

    scheduleTemp[7] = [...scheduleList];

    //hideconsole.log(scheduleTemp);

    axios
      .post("https://www.api.sendatradie.com/company/workerSchedule", {
        workerId: worker.value,
        schedule: JSON.stringify(scheduleTemp),
      })
      .then(async (res) => {
        //hideconsole.log("posted schedule successfully");
        toast.success("Created Shift");
        await fetchSchedule();
        setEditing(false);
        setDayChosen(null);
        setStartPoint(null);
        setEndPoint(null);
      })
      .catch((err) => {
        //hideconsole.log("failed to post schedule with error", err);
      });
  };

  const handleDayDelete = async (item) => {
    setDeleteInProgress(true);
    let itemDayValue = item.day.value;
    let scheduleTemp = schedule.map((row) => [...row]);
    if (itemDayValue < 7) {
      let updatedDay = [...scheduleTemp[itemDayValue]];
      updatedDay = updatedDay.filter((e) => {
        let check = false;
        if (e[0] == item.startValue && e[1] == item.endValue) check = true;
        return !check;
      });
      scheduleTemp[itemDayValue] = updatedDay;

      let updatedToShow = [...scheduleTemp[7]];
      updatedToShow = updatedToShow.filter((e) => {
        let check = false;
        if (
          e.day.value == item.day.value &&
          e.startValue == item.startValue &&
          e.endValue == item.endValue
        ) {
          //hideconsole.log(e, item);
          check = true;
        }

        return !check;
      });
      scheduleTemp[7] = updatedToShow;

      //hideconsole.log(scheduleTemp);
    } else if (itemDayValue == 7) {
      for (let dayId = 0; dayId < 5; dayId++) {
        let updatedDay = [...scheduleTemp[dayId]];
        updatedDay = updatedDay.filter((e) => {
          let check = false;
          if (e[0] == item.startValue && e[1] == item.endValue) check = true;
          if (check) {
            //hideconsole.log(item, e);
          }
          return !check;
        });
        scheduleTemp[dayId] = updatedDay;
      }
      let updatedToShow = [...scheduleTemp[7]];
      updatedToShow = updatedToShow.filter((e) => {
        let check = false;
        if (
          e.day.value == item.day.value &&
          e.startValue == item.startValue &&
          e.endValue == item.endValue
        ) {
          //hideconsole.log(e, item);
          check = true;
        }

        return !check;
      });
      scheduleTemp[7] = updatedToShow;

      //hideconsole.log(scheduleTemp);
    } else {
      for (let dayId = 5; dayId < 7; dayId++) {
        let updatedDay = [...scheduleTemp[dayId]];
        updatedDay = updatedDay.filter((e) => {
          let check = false;
          if (e[0] == item.startValue && e[1] == item.endValue) check = true;
          return !check;
        });
        scheduleTemp[dayId] = updatedDay;
      }
      let updatedToShow = [...scheduleTemp[7]];
      updatedToShow = updatedToShow.filter((e) => {
        let check = false;
        if (
          e.day.value == item.day.value &&
          e.startValue == item.startValue &&
          e.endValue == item.endValue
        ) {
          //hideconsole.log(e, item);
          check = true;
        }

        return !check;
      });
      scheduleTemp[7] = updatedToShow;

      //hideconsole.log(scheduleTemp);
    }
    axios
      .post("https://www.api.sendatradie.com/company/workerSchedule", {
        workerId: worker.value,
        schedule: JSON.stringify(scheduleTemp),
      })
      .then(async (res) => {
        //hideconsole.log("Edit schedule successfully");
        setDeleteInProgress(false);
        await fetchSchedule();
        setEditing(false);
        setDayChosen(null);
        setStartPoint(null);
        setEndPoint(null);
      })
      .catch((err) => {
        //hideconsole.log("failed to edit schedule with error", err);
      });
  };
  const handleAddDay = () => {
    setEditing(true);
  };

  useEffect(async () => {
    fetchWorkers();
  }, []);

  useEffect(async () => {
    if (worker) await fetchSchedule();
    setEditing(false);
    setDayChosen(null);
    setStartPoint(null);
    setEndPoint(null);
  }, [worker]);

  useEffect(() => {
    if (tracker) {
      //hideconsole.log("set Index Worker & Schedule null");
      setTracker(0);
      setWorker(null);
      setSchedule(null);
    }
  }, [tracker]);

  return (
    <>
      <Card>
        <CardHeader title="Shift Allocation"></CardHeader>
        <CardBody>
          <Paper>
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
              variant="fullWidth"
              scrollButtons="auto"
            >
              <Tab label="Allocate Shifts" />
              <Tab label="Handle Leaves" className="tour_web_leads" />
            </Tabs>
          </Paper>

          {tab == 0 ? (
            <>
              {" "}
              <div className="mt-4 mb-4">
                <ChooseWorker
                  workerOptions={workerOptions}
                  setWorker={setWorker}
                />
              </div>
              <div className="">
                {worker ? (
                  <>
                    {schedule &&
                      schedule[7].map((item, key) => {
                        return (
                          <Card key={key}>
                            <CardBody>
                              {" "}
                              <div
                                style={{
                                  fontFamily: "sans-serif",
                                  fontWeight: "bold",
                                }}
                                className="row"
                              >
                                <div className="col-10">{item.day.label}</div>
                                <div className="col-2">
                                  {" "}
                                  <IconButton
                                    onClick={() => {
                                      handleDayDelete(item);
                                    }}
                                    disabled={deleteInProgress}
                                  >
                                    <DeleteForeverIcon
                                      style={{ color: "solid black" }}
                                    />
                                  </IconButton>{" "}
                                </div>
                              </div>{" "}
                              <div>
                                {" "}
                                {item.start} - {item.end}
                              </div>{" "}
                            </CardBody>
                          </Card>
                        );
                      })}
                    {editing ? (
                      <>
                        <NewDay
                          dayOptions={dayOptions}
                          setEditing={setEditing}
                          startPoint={startPoint}
                          setStartPoint={setStartPoint}
                          endPoint={endPoint}
                          setEndPoint={setEndPoint}
                          handleAddSchedule={handleAddSchedule}
                          setDayChosen={setDayChosen}
                        />
                      </>
                    ) : (
                      <>
                        <div className="row d-flex justify-content-center">
                          <IconButton color="primary" onClick={handleAddDay}>
                            <AddCircleIcon fontSize="large" />
                          </IconButton>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}{" "}
              </div>
            </>
          ) : tab == 1 ? (
            <>
              {" "}
              <HandleLeaves
                workerOptions={workerOptions}
                setTracker={setTracker}
              />
            </>
          ) : (
            <></>
          )}
        </CardBody>
      </Card>
    </>
  );
}
