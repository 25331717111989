import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { TemplateGenerator } from "./TemplateGenerator.js";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import CustomBootstrapTable from "./../../../../_metronic/_helpers/CustomBootstrapTable.js";
import axios from "axios";
import { IndexButtons } from "./IndexButtons.js";
import { EditTemplate } from "./EditTemplate.js";
export function EmailTemplateGeneratorIndex() {
  const history = useHistory();
  const [createNewTemplate, setCreateNewTemplate] = useState(false);
  const [data, setData] = useState([]);
  const [ids, setIds] = useState([]);
  const [doEdit, setDoEdit] = useState(false);

  const [columns, setColumns] = useState([
    {
      text: "Id",
      dataField: "id",
    },
    {
      text: "Name",
      dataField: "name",
    },
    {
      text: "Created On",
      dataField: "created_at",
    },
  ]);

  const fetchData = async () => {
    axios
      .get("https://www.api.sendatradie.com/company/sendaTradieEmailTemplate")
      .then((res) => {
        //hideconsole.log("fetchData called");
        setData(res.data.data);
        //hideconsole.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEdit = async () => {
    //hideconsole.log("id to edit", ids[0]);
    setDoEdit(true);
  };

  const handleDelete = async () => {
    //hideconsole.log("ids to delete", ids);
    axios
      .post("https://www.api.sendatradie.com/company/deleteSendaTradieEmailTemplate", {
        ids: ids,
      })
      .then(async (res) => {
        await fetchData();
        //hideconsole.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <br />
          <Card>
            <CardHeader title="Email Templates">
              <CardHeaderToolbar>
                {!createNewTemplate && !doEdit && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setCreateNewTemplate(true);
                      //hideconsole.log("click detected");
                    }}
                  >
                    Create New Template
                  </button>
                )}
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              {createNewTemplate ? (
                <TemplateGenerator
                  setCreateNewTemplate={setCreateNewTemplate}
                  fetchData={fetchData}
                />
              ) : (
                <>
                  {!doEdit ? (
                    <>
                      <div className="tour_invoice_overview">
                        <IndexButtons
                          ids={ids}
                          handleEdit={handleEdit}
                          handleDelete={handleDelete}
                          fetchData={fetchData}
                        />
                        <CustomBootstrapTable
                          data={data}
                          columns={columns}
                          isSelectable
                          ids={ids}
                          setIds={setIds}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <EditTemplate
                        data={
                          data.filter((item) => {
                            return item.id == ids[0];
                          })[0]
                        }
                        fetchData={fetchData}
                        id={ids[0]}
                        setDoEdit={setDoEdit}
                      />
                    </>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
