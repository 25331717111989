// SettingNotification.js
import React, { useState } from "react";
import { CustomersUIProvider } from "./../Jobs/pages/Jobs/CustomersUIContext.js";
import { CustomersLoadingDialog } from "./../Jobs/pages/Jobs/customers-loading-dialog/CustomersLoadingDialog.js";
// import SettingNotificationtable from "./../SettingNotification/SettingNotificationtable.js";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Select from "react-select";
import CreateModal from "./CreateModal.js";

import SettingJobTypeTable from "./SettingJobTypeTable.js";
const customersUIEvents = {
  openNotificationDailog: (id) => {
    //   history.push(`/Jobs/${id}/edit`);
  },
};

export function SettingJobType() {
  const [ShowData, setShowData] = useState([]);
  const [isOpenAddNew, setisOpenAddNew] = useState(false);
  const ShowDataOption = [
    { value: 1, label: "Active" },
    { value: 0, label: "Disabled" },
    { value: 2, label: "All" },
  ];

  const OpenAddNewModal = () => {
    setisOpenAddNew(true);
  };
  const CloseAddNewModal = () => {
    setisOpenAddNew(false);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <br />
          <Card>
            <CardHeader title="Job Types">
              <CardHeaderToolbar>
                <button
                  type="button"
                  onClick={OpenAddNewModal}
                  className="btn btn-primary"
                >
                  Add New
                </button>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <CustomersUIProvider customersUIEvents={customersUIEvents}>
                <CustomersLoadingDialog />
                <SettingJobTypeTable isOpenAddNew={isOpenAddNew} />
              </CustomersUIProvider>
            </CardBody>
          </Card>
        </div>
        <CreateModal onHide={CloseAddNewModal} show={isOpenAddNew} />
      </div>
    </>
  );
}
