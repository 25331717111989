import { useEffect } from "react";
import { AuthFlowType } from "../../../../utils/authHelpers";
import { useHistory, useLocation } from "react-router-dom";

const headerHeight = "100px";

const AuthHeader = (props) => {
  const { headerTitle } = props; // Destructure headerTitle from props
  const { authFlowType: type } = props;
  const history = useHistory();

  //Handle changes of auth flow type
  useEffect(() => {}, [type]);

  return (
    <>
      {/* Signup header */}
      {type === AuthFlowType.Signup && (
        <div
          className="d-flex align-items-center bg-white"
          style={{ height: "100px", width: "100vw", paddingLeft: "20px" }} // Add padding-left to keep the logo from sticking to the edge
        >
          <img
            alt="logo"
            onClick={() => history.push("/auth")}
            src="/media/logos/logo-light.png"
            style={{ marginRight: "20px", cursor: "pointer" }}
          />
          {headerTitle && (
            <h1 style={{ textAlign: "center", width: "calc(100% - 40px)" }}>
              {headerTitle}
            </h1>
          )}
        </div>
      )}
      {/* Login header */}
      {type === AuthFlowType.Login && (
        <div
          className="bg-white"
          style={{
            height: { headerHeight },
            width: "100vw",
            paddingLeft: "20px",
          }} // Add padding-left to keep the logo from sticking to the edge
        >
          <img alt="logo" src="/media/logos/logo-light.png" />
        </div>
      )}
    </>
  );
};

export default AuthHeader;
