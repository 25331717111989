import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { Formik } from "formik";
import { Field } from "formik";
import moment from "moment";
import { Form } from "react-bootstrap";
import { TextField } from "@mui/material";
import CancelIcon from "@material-ui/icons/Cancel";
import Select from "react-select";
import { useSelector } from "react-redux";
export function NewQuoteModal({ show, onHide, companies }) {
  ////hideconsole.log(companies);
  const customer = useSelector(
    (state) => state.clientPortalAuth.userData.customer
  );
  ////hideconsole.log(customer);
  const [files, setFiles] = React.useState([]);
  const [filesBlob, setFilesBlob] = React.useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [service, setService] = useState(null);
  const [quoteMessage, setQuoteMessage] = useState("");
  const [quoteDate, setQuoteDate] = useState(null);
  const [storeSize, setStoreSize] = useState(0);
  const [typeOptions, setTypeOptions] = useState([]);

  const handleFiles = (e) => {
    if (e.target.files.length == 0 || e.target.files == null) return;
    if (!e.target.files[0].type.startsWith("image/")) {
      toast.error("Only image files allowed");
      return;
    }
    if (
      e.target.files[0].size > 25 * 1024 * 1024 ||
      storeSize + e.target.files[0].size > 25 * 1024 * 1024
    ) {
      toast.error("Maximum upload limit exceeded");
      return;
    }
    let ImgArray = [];
    let ImgBlobArray = [];

    const blobUrl = URL.createObjectURL(e.target.files[0]);
    ImgBlobArray = [...ImgBlobArray, blobUrl];
    ImgArray = [...ImgArray, e.target.files[0]];

    setFiles([...files, ...ImgArray]);
    setFilesBlob([...filesBlob, ...ImgBlobArray]);
    setStoreSize(storeSize + e.target.files[0].size);
  };

  const handleImgDelete = (index) => {
    let DataBlobObj = [...filesBlob];
    let DataObj = [...files];

    DataBlobObj.splice(index, 1);
    DataObj.splice(index, 1);
    setFiles(DataObj);
    setFilesBlob(DataBlobObj);
  };

  function handleSubmit() {
    let formData = new FormData();

    files &&
      files.length &&
      files.map((file) => {
        formData.append("files", file ? file : null);
      });

    formData.append("Customer ID", customer.id ? customer.id : null);
    formData.append("Customer Email", customer.email ? customer.email : null);
    formData.append("Customer Name", customer.name ? customer.name : null);
    formData.append(
      "Address",
      customer.mainaddress ? customer.mainaddress : null
    );
    formData.append("Service Selected", service);
    formData.append("companyID", Number(selectedCompany));
    formData.append("Quote Date", quoteDate);
    formData.append("Quote Message", quoteMessage);
    //hideconsole.log("logging formData data : ");
    for (let [key, value] of formData.entries()) {
      //hideconsole.log(key, value);
    }

    //const dataToSend = JSON.stringify(jsonObject);
    if (selectedCompany == null) {
      toast.error("Select a company");
      return;
    } else if (quoteDate == null) {
      toast.error("Select date time");
      return;
    } else if (service == null) {
      toast.error("Select a service");
      return;
    }
    axios
      .post("https://www.api.sendatradie.com/client/quotesRequest", formData)
      .then((response) => {
        if (response) {
          //hideconsole.log(response);
          toast.success("New Quote Request Successful");
        }
      })
      .catch((error) => {
        toast.error("New Quote Request Failed");
        console.log(error);
      });

    handleCloseButton();
    return;
  }

  function handleCloseButton() {
    setFiles([]);
    setFilesBlob([]);
    setSelectedCompany(null);
    setService(null);
    setQuoteDate(null);
    setStoreSize(0);
    setTypeOptions([]);
    setQuoteMessage("");
    onHide();
  }
  const handleCompanyChange = (e) => {
    axios
      .get(`https://www.api.sendatradie.com/client/getCompanyServices/${e.value}`)
      .then((response) => {
        //hideconsole.log(response.data.result);

        setTypeOptions([response.data.result]);
      })
      .catch((error) => {
        console.log(error);
      });
    setSelectedCompany(e.value);
  };
  return (
    <>
      <Formik
        enableReinitialize={false}
        // validationSchema={CustomerAddSchema}

        // Time is stored as UTC in the DB
        onSubmit={(values) => {}}
      >
        <Modal
          size="lg"
          show={show}
          onHide={handleCloseButton}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              Create New Quote
            </Modal.Title>
            <button
              type="button"
              className="btn btn-light btn-close mr-0"
              aria-label="Close"
              onClick={handleCloseButton}
            >
              x
            </button>
          </Modal.Header>
          <Form className="form form-label-right">
            <Modal.Body>
              <div className="form-group row">
                <div className="col-lg-4">
                  <label>Company</label>

                  <Select
                    classNamePrefix="mySelect"
                    name="company"
                    label="Select Company"
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                    }}
                    //value={typeOptions.find((opt) => opt.value == state?.type)}
                    onChange={(e) => {
                      handleCompanyChange(e);
                    }}
                    options={companies}
                    placeholder="Select..."
                    required
                  />
                </div>

                <div className="col-lg-4">
                  <label>Request Date/time</label>
                  <TextField
                    onChange={(e) => {
                      setQuoteDate(moment(e.target.value));
                      //hideconsole.log(moment(e.target.value));
                    }}
                    name="datetime"
                    id="datetime-local"
                    className="form-control mt1"
                    variant="outlined"
                    // value={QuoteDate}
                    type="datetime-local"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>

                <div className="col-lg-4">
                  <label>Message</label>
                  <Field
                    value={quoteMessage}
                    name="Add_info"
                    className="form-control"
                    as="textarea"
                    onChange={(e) => setQuoteMessage(e.target.value)}
                    //   className="form-control"
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-4">
                  <label>Service</label>
                  <Select
                    classNamePrefix="mySelect"
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                    }}
                    //value={typeOptions.find((opt) => opt.value == state?.type)}
                    onChange={(e) => {
                      //hideconsole.log(e.label);
                      setService(e.label);
                    }}
                    options={typeOptions}
                    placeholder="Select..."
                    isDisabled={selectedCompany == null}
                    required
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  <input
                    type="file"
                    className="mt-1"
                    multiple
                    onChange={(e) => {
                      handleFiles(e);
                    }}
                  ></input>
                  <div style={{ fontSize: "0.8em", fontWeight: "bold" }}>
                    Only image files allowed with max size of 25 MB
                  </div>
                </div>
              </div>
              <div className="form-group row">
                {filesBlob && filesBlob.length
                  ? filesBlob.map((item, index) => {
                      if (item) {
                        return (
                          <div key={index} className="col-lg-4">
                            <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 bg-light">
                              <CancelIcon
                                fontSize="large"
                                onClick={() => {
                                  handleImgDelete(index);
                                  //hideconsole.log("onClick");
                                }}
                              />
                            </div>
                            <img
                              src={
                                item.startsWith("jobsFile/")
                                  ? `https://storage.googleapis.com/platform-295512.appspot.com/${item}`
                                  : `${item}`
                              }
                              alt="img"
                              style={{
                                maxHeight: "100%",
                                maxWidth: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        );
                      }
                    })
                  : null}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="tour_save">
                <button
                  type="button"
                  onClick={handleCloseButton}
                  className="btn btn-light btn-elevate"
                  id="tour_save_job"
                >
                  Cancel
                </button>

                <button
                  type="button"
                  className="btn btn-primary btn-elevate ml-3"
                  // onHide={onHide}
                  onClick={handleSubmit}
                >
                  Save
                  {false ? (
                    <span
                      className="spinner-border spinner-border-sm ml-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    ""
                  )}
                </button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>
      </Formik>
    </>
  );
}
