import React from "react";
import { Modal } from "react-bootstrap";

export function InvoiceModal({ show, invoiceModalUrl, onHide }) {
  function handleCloseButton() {
    onHide();
  }
  return (
    <>
      <Modal
        size="md"
        show={show}
        onHide={handleCloseButton}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">Invoice</Modal.Title>
          <button
            type="button"
            className="btn btn-light btn-close mr-0"
            aria-label="Close"
            onClick={handleCloseButton}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body>
          <iframe src={invoiceModalUrl} width="100%" height="600px"></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
}
