import React, { useEffect, useRef, useState } from "react";
import * as requestFromServer from "./../Jobs/_redux/customers/customersCrud.js";
import Select from "react-select";

export const SettingJobTypeFilter = (props) => {
  const statusOptions = [
    { value: 2, label: "All Groups" },
    { value: 1, label: "Active" },
    { value: 0, label: "Disabled" },
  ];

  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12">
          <small className="form-text text-muted">
            <b>Search</b>
          </small>
          <input
            type="text"
            className="form-control"
            // style={{ height: "38px" }}
            name="search"
            placeholder="search here"
            onChange={(event) => {
              props.handleSearch(event.target.value);
            }}
          />
        </div>
        <div className="col-lg-4">
          <small className="form-text text-muted">
            <b>Show</b>
          </small>
          <Select
            classNamePrefix="mySelect"
            // style={{ height: "24px" }}
            name="leads_status"
            label="Show"
            //className="form-control"
            value={statusOptions.find((item) => item.value === props.status)}
            onChange={(e) => props.setStatus(e.value)}
            options={statusOptions}
          ></Select>
        </div>
      </div>
    </>
  );
};
