import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import CustomBootstrapTable from "./../../../../_metronic/_helpers/CustomBootstrapTable.js";
import { EditFeatureAvailability } from "./EditFeatureAvailability.js";
import axios from "axios";
export function FeaturePermissions() {
  const [featurePermissions, setFeaturePermissions] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [chosenItem, setChosenItem] = useState();
  const getPermissions = async () => {
    axios
      .get("https://www.api.sendatradie.com/settings/featurePermissions")
      .then((res) => {
        //hideconsole.log("fetched permissions", res.data.data);
        setFeaturePermissions(res.data.data);
      })
      .catch((err) => {
        //hideconsole.log("error in fetching permissions", err);
      });
  };

  useEffect(async () => {
    await getPermissions();
  }, []);
  const [ids, setIds] = useState([]);
  const columns = [
    {
      text: "Button Name",
      dataField: "name",
      formatter: (col, row) => {
        return col.replace(/_/g, " ");
      },
    },
    {
      text: "Availability",
      dataField: "pack_id",
      formatter: (col, row) => {
        const options = [
          { value: "1", label: "Lite" },
          { value: "2", label: "Starter" },
          { value: "3", label: "Standard" },
          { value: "4", label: "Ultimate" },
          { value: "5", label: "Trial" },
        ];
        let op = options
          .filter((item) => {
            return col.split(",")?.includes(item.value);
          })
          .map((item) => {
            return item.label;
          })
          .join(", ");

        //hideconsole.log("op is", op);
        return op;
      },
    },
  ];
  const onHide = () => {
    setShowEditModal(false);
  };
  useEffect(() => {
    if (ids.length == 1) {
      //hideconsole.log("id selected is", ids[0]);
      //hideconsole.log(featurePermissions.find((item) => item.id == ids[0]));
      setChosenItem(featurePermissions.find((item) => item.id == ids[0]));
    }
  }, [ids]);
  return (
    <>
      <EditFeatureAvailability
        featurePermissions={featurePermissions}
        getPermissions={getPermissions}
        chosenItem={chosenItem}
        show={showEditModal}
        onHide={onHide}
        id={ids[0]}
      />
      <div className="row">
        <div className="col-md-12">
          <br />
          <Card>
            <CardHeader title="Feature Permissions"></CardHeader>
            <CardBody>
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                disabled={ids.length != 1}
                onClick={() => setShowEditModal(true)}
              >
                <i className="fa fa-edit"></i> Edit
              </button>
              <CustomBootstrapTable
                data={featurePermissions}
                columns={columns}
                ids={ids}
                setIds={setIds}
                isSelectable
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
