import React, { useState, useEffect } from "react";
import { Template } from "./Template.js";
import * as requestFromServer from "./../Jobs/_redux/customers/customersCrud.js";
import { Paper, Tabs, Tab } from "@material-ui/core/";
import { toast } from "react-toastify";

export function JobMessage(props) {
  const [SMSTemplate, setSMSTemplate] = useState({ Message: "" });
  const [countVAl, setCountVAL] = useState({
    messageLength: 160,
    currentMsglength: 0,
    remainingLength: 0,
    MessageCount: 0,
  });
  const [buttonValue, setbuttonValue] = useState([
    "{Customer_Name}",
    "{Customer_Phone}",
    "{Customer_Address}",
    "{Additional_Information}",
    "{Google_Map}",
    "{Job_Type}",
  ]);
  const [countForRemove, setCountForRemove] = useState(0);

  const [SMSTemplateJAST, setSMSTemplateJAST] = useState({ Message: "" });
  const [countVAlJAST, setCountVALJAST] = useState({
    messageLength: 160,
    currentMsglength: 0,
    remainingLength: 0,
    MessageCount: 0,
  });
  const [buttonValueJAST, setbuttonValueJAST] = useState([
    "{Customer_Name}",
    "{Customer_Phone}",
    "{Customer_Address}",
    "{Additional_Information}",
    "{Google_Map}",
    "{Appointment_Time}",
    "{Job_Type}",
  ]);
  const [countForRemoveJAST, setCountForRemoveJAST] = useState(0);
  const [value, setValue] = React.useState(0);

  const onSaveForSMSTemplateJST = () => {
    const data = SMSTemplate;
    requestFromServer
      .PostsmsJobTemplate(data)
      .then((response) => {
        //hideconsole.log(response.data.result, "response.SMSTemplatedata.result.");
        toast.success("Saved.");
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
          toast.error("Failed");
        } else {
          toast.error("Failed");
          //   toasterMessage("error", error.response.data.message);
        }
      });
  };

  const onSaveForSMSJAST = () => {
    const data = SMSTemplateJAST;
    requestFromServer
      .PostsmsAppointmentTemplate(data)
      .then((response) => {
        //hideconsole.log(response.data.result, "response.SMSTemplatedata.result.");
        toast.success("Job Appointment SMS Template successfully saved");
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
          toast.error("Failed");
        } else {
          toast.error("Failed");
          //   toasterMessage("error", error.response.data.message);
        }
      });
  };

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  useEffect(() => {
    getDataForSmsReview();
  }, []);

  const getDataForSmsReview = () => {
    const data = SMSTemplate;
    requestFromServer
      .getJobAppintmentTemplate()
      .then((response) => {
        //hideconsole.log(response.data.result, "response.SMSTemplatedata.result.");
        if (response.data.result.length) {
          setSMSTemplate({ Message: response.data.result[0].job });
          setSMSTemplateJAST({ Message: response.data.result[0].appointment });
          checkCoundNumber(response.data.result[0].job, "sms");
          checkCoundNumber(response.data.result[0].appointment, "appointment");
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });
  };

  const checkCoundNumber = (value, type) => {
    var msgCountAsNum = 160;
    let lengthOfevent = value.length;
    const split = value.length ? value.split(" ") : "";
    const CountStep = split.filter((count) => {
      return (
        count === `{Name}` ||
        count === `{Company}` ||
        count === `{Link}` ||
        count === `{Customer_Name}` ||
        count === `{Appointment_time}` ||
        count === `{Customer_Name}` ||
        count === `{Customer_Phone}` ||
        count === `{Customer_Address}` ||
        count === `{Additional_Information}` ||
        count === `{Coustomer_Phone}` ||
        count === `{Coustomer_Address}` ||
        count === `{Additional_Information}` ||
        count === `{Google_Map}` ||
        count === `{appointment_Time}`
      );
    });

    if (CountStep.length > 1) {
      if (type === "sms") {
        setCountForRemove(CountStep.toString().length - CountStep.length + 1);
      } else {
        setCountForRemoveJAST(
          CountStep.toString().length - CountStep.length + 1
        );
      }

      const calculateval =
        CountStep.toString().length - CountStep.length + 1 - CountStep.length;
      const currentMsglength = lengthOfevent - calculateval;
      const messageLengthRound = Math.ceil(currentMsglength / msgCountAsNum);
      const messageLength = msgCountAsNum * messageLengthRound;
      if (type === "sms") {
        setCountVAL({
          messageLength: messageLength,
          currentMsglength: currentMsglength,
          remainingLength: messageLength - currentMsglength,
          MessageCount: messageLengthRound,
        });
      } else {
        setCountVALJAST({
          messageLength: messageLength,
          currentMsglength: currentMsglength,
          remainingLength: messageLength - currentMsglength,
          MessageCount: messageLengthRound,
        });
      }
    } else if (CountStep.length === 1) {
      if (type === "sms") {
        setCountForRemove(CountStep.toString().length);
      } else {
        setCountForRemoveJAST(CountStep.toString().length);
      }
      const calculateval = lengthOfevent - CountStep.toString().length + 1;
      const currentMsglength = lengthOfevent - calculateval;
      const messageLengthRound = Math.ceil(currentMsglength / msgCountAsNum);
      const messageLength = msgCountAsNum * messageLengthRound;
      if (type === "sms") {
        setCountVAL({
          messageLength: messageLength,
          currentMsglength: currentMsglength,
          remainingLength: messageLength - currentMsglength,
          MessageCount: messageLengthRound,
        });
      } else {
        setCountVALJAST({
          messageLength: messageLength,
          currentMsglength: currentMsglength,
          remainingLength: messageLength - currentMsglength,
          MessageCount: messageLengthRound,
        });
      }
    } else {
      if (type === "sms") {
        setCountForRemove(0);
      } else {
        setCountForRemoveJAST(0);
      }
      const currentMsglength = lengthOfevent;
      const messageLengthRound = Math.ceil(currentMsglength / msgCountAsNum);
      const messageLength = msgCountAsNum * messageLengthRound;
      if (type === "sms") {
        setCountVAL({
          messageLength: messageLength,
          currentMsglength: currentMsglength,
          remainingLength: messageLength - currentMsglength,
          MessageCount: messageLengthRound,
        });
      } else {
        setCountVALJAST({
          messageLength: messageLength,
          currentMsglength: currentMsglength,
          remainingLength: messageLength - currentMsglength,
          MessageCount: messageLengthRound,
        });
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <br />
          <Paper>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              variant="fullWidth"
            >
              <Tab label="Job"></Tab>
              <Tab label="Appointment"></Tab>
            </Tabs>
            {value === 0 && (
              <>
                <br />
                <Template
                  Title="Job SMS Template"
                  type="showPaper"
                  count={countForRemove}
                  setCount={setCountForRemove}
                  buttonValue={buttonValue}
                  setMessage={setSMSTemplate}
                  messages={SMSTemplate}
                  numberCount={countVAl}
                  setnumberCount={setCountVAL}
                  onSaveData={onSaveForSMSTemplateJST}
                />
              </>
            )}
            {value === 1 && (
              <>
                <br />
                <Template
                  Title="Job Appointment SMS Template"
                  type="HidePaper"
                  count={countForRemoveJAST}
                  setCount={setCountForRemoveJAST}
                  buttonValue={buttonValueJAST}
                  setMessage={setSMSTemplateJAST}
                  messages={SMSTemplateJAST}
                  numberCount={countVAlJAST}
                  setnumberCount={setCountVALJAST}
                  onSaveData={onSaveForSMSJAST}
                />
              </>
            )}
          </Paper>
        </div>
      </div>
    </>
  );
}
