import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { Switch } from "@material-ui/core";
import { Button, ButtonGroup, Grid } from "@material-ui/core";
import { Input } from "@mui/material";
import { toast } from "react-toastify";
import { useSelect } from "downshift";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchRecurrentJobToggleValue } from "./../Auth/_redux/settingAction.js";
export function ReccurringJob() {
  const [toggle, setToggle] = useState(false);
  const [editing, setEditing] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRecurrentJobToggleValue());
  }, []);

  const [reccurrentJobToggleValue, setReccurrentJobToggleValue] = useState(
    useSelector((state) => state.settings.reccurrentJobToggleValue)
  );
  useEffect(() => {
    // axios
    //   .get("https://www.api.sendatradie.com/company/recommendWorkerSettings")
    //   .then((res) => {
    //     //hideconsole.log(res.data.result);
    //     setToggle(res.data.result[0].toggle);
    //     //hideconsole.log(res.data.result[0].toggle);
    //     setRadius(res.data.result[0].radius);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <br />
          <Card>
            <CardHeader title="Reccurring Job"></CardHeader>
            <CardBody>
              {/* <div className="form-group row">
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bolder font-size-sm mr-3"
                  style={{ width: 208 }}
                  disabled={editing}
                  onClick={() => setEditing(true)}
                >
                  <i className="fa fa-edit"></i> Edit
                </button>
              </div> */}
              <div className="form-group row">
                <div className="col-lg-6">Allow Reccurring Jobs</div>
                <div className="col-lg-6">
                  <Switch
                    checked={reccurrentJobToggleValue}
                    onChange={(event) => {
                      setEditing(!editing);
                      setReccurrentJobToggleValue(event.target.checked);
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                {editing && (
                  <button
                    type="button"
                    className="btn btn-primary btn-elevate ml-3"
                    onClick={() => {
                      axios
                        .post(
                          "https://www.api.sendatradie.com/company/setReccurrentJobToggle",
                          { toggle: reccurrentJobToggleValue }
                        )
                        .then((res) => {
                          dispatch(fetchRecurrentJobToggleValue());

                          toast.success("Changes save successfully");
                        })
                        .catch((err) => toast.error("Failed to save changes"));
                      setEditing(false);
                    }}
                  >
                    Save
                  </button>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
