// EditNewModal.js
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import * as requestFromServer from "./../Jobs/_redux/customers/customersCrud.js";
import Select from "react-select";
import { toast } from "react-toastify";
import { Switch } from "@material-ui/core";

export default function EditNotificationModal({
  onHide,
  show,
  id,
  roleOptions,
  getData,
}) {
  const [state, setState] = useState({
    action: "",
    notify: "",
    notify_by: "",
    description: "",
    variables: "",
    action_id: 0,
    mobile: 0,
    email: 0,
  });

  const handleClose = () => {
    onHide();
  };

  useEffect(() => {
    if (id && id.length && show) {
      //hideconsole.log("get setting id", id);
      requestFromServer
        .getNotificationSetting(id)
        .then((res) => {
          setState(res.data.data);
          //hideconsole.log(res.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [show]);

  const handleSubmit = () => {
    requestFromServer
      .saveNotificationSettings(state, id)
      .then((res) => {
        //hideconsole.log(res);
        toast.success("Saved");
        getData();
        onHide();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.body?.data);
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit Notification - {state.action}
          </Modal.Title>
          <button
            type="button"
            className="btn btn-light btn-close mr-0"
            aria-label="Close"
            onClick={handleClose}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <div className="col-12 mt-2">
              <label>Notification For</label>
              <Select
                classNamePrefix="mySelect"
                name="group_id"
                label="Group Id"
                value={state?.notify
                  ?.split(",")
                  ?.map((item) =>
                    roleOptions.find((role) => role.value == item)
                  )}
                onChange={(e) => {
                  setState({
                    ...state,
                    notify: e ? e.map((item) => item.value).toString() : "",
                  });
                }}
                options={roleOptions}
                isMulti
                isSearchable
                required
              ></Select>
            </div>
            <div className="col-12 mt-2">
              <label>Notified By</label>
              <Select
                classNamePrefix="mySelect"
                name="group_id"
                label="Group Id"
                value={state?.notify_by
                  ?.split(",")
                  ?.map((item) =>
                    roleOptions.find((role) => role.value == item)
                  )}
                onChange={(e) => {
                  setState({
                    ...state,
                    notify_by: e ? e.map((item) => item.value).toString() : "",
                  });
                }}
                options={roleOptions}
                isMulti
                isSearchable
                required
              ></Select>
            </div>
            <div className="col-12 mt-2">
              <label>Description</label>
              <input
                className="form-control"
                type="text"
                value={state.description}
                onChange={(e) =>
                  setState({ ...state, description: e.target.value })
                }
              ></input>
              <div className="row ml-0 mt-3">
                {state.variables?.split(",")?.map((item) => {
                  if (item) {
                    return (
                      <button
                        type="button"
                        id={item}
                        onClick={(e) =>
                          setState({
                            ...state,
                            description: state.description + e.target.id,
                          })
                        }
                        className="btn btn-secondary btn-sm mr-2"
                      >
                        {item}
                      </button>
                    );
                  }
                })}
              </div>

              {state.action_id == 1 || state.action_id == 19 ? (
                <div className="row ml-0 mt-3">
                  <div className="col-7">
                    <label>Mobile Notification</label>
                    &nbsp;
                    <Switch
                      checked={!!state.mobile}
                      onChange={(e) => {
                        setState((prev) => ({
                          ...state,
                          mobile: !prev.mobile,
                        }));
                      }}
                      color="primary"
                      id="check"
                      name="check"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                  <div className="col-5">
                    <label>Email</label>
                    &nbsp;
                    <Switch
                      checked={!!state.email}
                      onChange={(e) => {
                        setState((prev) => ({
                          ...state,
                          email: !prev.email,
                        }));
                      }}
                      color="primary"
                      id="check"
                      name="check"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary btn-elevate"
            >
              Edit
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
