import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Input } from "../../../../_metronic/_partials/controls";
import "../../../../_metronic/_assets/sass/layout/_customStyle.scss";
import SignatureCanvas from "react-signature-canvas";
import { CardFooter } from "../../../../_metronic/_partials/controls";
import { useMeasure } from "react-use";
import { css } from "@emotion/react";
import "./customers-receipt-invoice.module.scss";
const styles = () => ({
  container: css`
    margin: 0 auto;
    height: 300px;
  `,
  sign: css`
    width: 100%;
    height: 100%;
  `,
});

export function CustomerSignatureDialog({
  handleSave,
  handleClear,
  sigCanvas,
  handleForm,
  submitting,
  setSubmitting,
}) {
  const [containerRef, { width, height }] = useMeasure();
  return (
    <div className="timeline timeline-5 mt-0">
      <Formik
        initialValues={{ name: "" }}
        onSubmit={(values) => handleForm(values)}
      >
        {() => (
          <div>
            <Form>
              <label>Signed By:</label>
              <Field
                style={{ width: "100%" }}
                component={Input}
                name="name"
                className="form-control"
                withFeedbackLabel={false}
              ></Field>
              &nbsp;
              <label className="mt-3">Signature:</label>
              <div style={{ border: "1px solid lightgray", width: "100%" }}>
                <SignatureCanvas
                  ref={sigCanvas}
                  canvasProps={{
                    className: styles.sign,
                  }}
                />
              </div>
              <div className="mt-3">
                <div>
                  <button
                    type="button"
                    onClick={handleClear}
                    className="btn btn-outline-light btn-elevate"
                  >
                    Clear
                  </button>

                  <button
                    type="submit"
                    disabled={submitting}
                    onClick={handleSave}
                    className="btn btn-success btn-elevate ml-3"
                  >
                    Save
                    {submitting ? (
                      <span
                        className="spinner-border spinner-border-sm ml-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      ""
                    )}
                  </button>
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
}
