import React, { useEffect, useRef, useState } from "react";
import { CustomerSignatureDialog } from "./custome-invoice-signature.js";
import unpaid from "../assets/svg/unpaid.svg";
import paid from "../assets/svg/paid.svg";
import styles from "./customers-receipt-invoice.module.scss";
// import Logo from "../../../../../public/media/logos/logo.png";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { toasterMessage } from "./../../../../utils/toast.js";
import CustomAxios from "./../../../../utils/axios.js";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CardFooter,
} from "../../../../_metronic/_partials/controls";
import SignatureCanvas from "react-signature-canvas";
import * as requestFromServer from "./../../Jobs/_redux/customers/customersCrud.js";
import { TCModal } from "./../../Jobs/pages/Jobs/customer-receipt-dialog/TCModal.js";
import "./customers-receipt-invoice.module.scss";

export default function SignaturePortal(props) {
  //using usehistory for routing to login page
  const history = useHistory();
  // required hooks
  const [invoiceData, setInvoice] = React.useState([]);
  const [itemData, setItemData] = React.useState([]);
  const [creditCardFeeAdded, setCreditCardFeeAdded] = React.useState(false);
  const [subTotal, setSubTotal] = React.useState(0);
  const [fees, setFees] = React.useState("");
  const [taxes, setTaxes] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [imageURL, setImageURL] = React.useState("");
  const [t_c, setT_c] = React.useState([]);
  const [savedT_c, setSavedT_c] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [signatureExists, setSignatueExists] = React.useState(true);
  const [id, setId] = React.useState();
  const [accepted, setAccepted] = React.useState(false);
  const [showTC, setShowTC] = React.useState(false);
  const [signedBy, setSignedBy] = useState("");
  const [email, setEmail] = useState("");
  const [notes, setNotes] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [empty, setEmpty] = useState(true);
  const [logo, setLogo] = useState("");

  // Signature canvas useRef
  const sigCanvas = useRef({});

  //this is used to check whenever an itwm is added subtotal should be correct
  useEffect(() => {
    // subTotal total of "COST" field
    let subTotal = 0;
    let totalTax = 0;
    // //hideconsole.log("withoutTaxOpted", itemData);
    //hideconsole.log(itemData);
    itemData &&
      itemData.map((data, index) => {
        subTotal = subTotal + data.quantity * data.price;
        if (data.is_taxable) {
          totalTax =
            totalTax + (data.quantity * data.price * fees?.[0]?.tax) / 100;
        } else if (data.includeTax && data.tax) {
          totalTax = totalTax + parseFloat(data.tax) * data.quantity;
        }
        //hideconsole.log(totalTax);
      });
    setTaxes(totalTax);
    setSubTotal(subTotal);

    setTotal(
      totalTax +
        subTotal +
        parseFloat(
          creditCardFeeAdded
            ? ((totalTax + subTotal) * fees[0]?.credit_fee) / 100
            : 0
        )
    );
  }, [itemData, creditCardFeeAdded, fees]);

  // call get invoice api in use hooks
  useEffect(() => {
    // get ids from params
    if (props.match.params && props.match.params.id) {
      const id = props.match.params.id;

      requestFromServer.getJobSignatureClient(id).then((res) => {
        //hideconsole.log(res.data.data);
        const data = res.data.data;

        setEmail(res.data.email || "");
        setLogo(res.data.logo || "");

        if (data) {
          setSignatueExists(false);
          setSavedT_c(data.t_c);
          setImageURL(data.signature);
          setCreditCardFeeAdded(data.credit_fee_added);
          setFees(JSON.parse(data.fees));
          setItemData(JSON.parse(data.items));
          setId(data.id);
          setNotes(data.notes || "");
          setSignedBy(data.signed_by || "");
        }
        setIsLoading(false);
      });
    }
  }, []);

  // signature clear function
  const handleClear = () => {
    sigCanvas.current.clear();
    setEmpty(true);
  };

  //get data from sign modal & post to api
  const saveSignature = () => {
    setIsSubmitting(true);
    const id = props.match.params.id;
    let data = {
      job_id: id,
      signature: sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("../../../../../../../public/media/demos/"),
      signedBy: signedBy,
      email: email,
      notes: notes,
    };

    // get invoice data, will placed whole api calls in redux.

    requestFromServer
      .updateJobSignature(data)
      .then(function(res) {
        // toasterMessage("success", "Signature Updated");
        setIsSubmitting(false);
        setSignatueExists(true);
      })
      .catch((error) => {
        toasterMessage("error", error.response.data.message);
        //   props.history.push("/logout");
        setIsSubmitting(false);
      });
  };

  const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  return (
    <>
      {!isLoading ? (
        <div className="container">
          <Card>
            <CardHeader>
              {" "}
              <img
                style={{
                  height: "80px",
                  maxWidth: "300px",
                }}
                src={
                  logo
                    ? logo.startsWith("http")
                      ? logo
                      : `https://storage.googleapis.com/platform-295512.appspot.com/${logo}`
                    : ""
                }
              />
            </CardHeader>
            <CardBody>
              {signatureExists ? (
                <h1>Thank you.</h1>
              ) : (
                <>
                  <div className="react-bootstrap-table table-responsive">
                    <table className="table table table-head-custom table-vertical-center table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Item</th>
                          <th scope="col">QTY</th>
                          <th scope="col">Unit Price</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {item?.map((it, i) => {
                                                            // setSum(sum + it.value * it.price)
                                                            subTotal = subTotal + it.value * it.price
                                                        })} */}
                        {itemData?.map((id, i) => {
                          // subTotal = subTotal + id.quantity * id.price;

                          return (
                            <tr key={i}>
                              <td>
                                <div>{id.item}</div>
                                <div>{id.item_description}</div>
                              </td>
                              <td>{id.quantity ? id.quantity : 0}</td>
                              <td>${id.price ? id.price : 0}</td>
                              <td>
                                $
                                {id.quantity * id.price
                                  ? (id.quantity * id.price).toFixed(2)
                                  : 0}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className="form-group row">
                    {/* Add Credit Card Fee */}
                    <div className="col-lg-4 d-flex align-items-middle">
                      <input type="checkbox" style={{ display: "none" }} />
                      <label className="checkbox checkbox-single">
                        <input
                          label="Add Credit Card Fee"
                          type="checkbox"
                          disabled
                          name="add Credit Card Fee"
                          checked={creditCardFeeAdded}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSubTotal(
                                subTotal +
                                  (subTotal * fees?.[0].credit_fee) / 100
                              );
                              //for getting is fees Added or not
                              setCreditCardFeeAdded(true);
                            } else {
                              setSubTotal(subTotal);
                              //for getting is fees Added or not
                              setCreditCardFeeAdded(false);
                            }
                          }}
                        />
                        <span />
                      </label>

                      <span className="font-weight-bold">
                        &nbsp; Add Credit Card Fee({fees?.[0]?.credit_fee}%)
                      </span>
                    </div>
                  </div>
                  {/* Sub Total */}
                  <div className="form-group row mb-0">
                    <div className="col-lg-3 d-flex">
                      <label>
                        Sub Total: ${Number.parseFloat(subTotal).toFixed(2)}
                      </label>
                    </div>

                    {/* Sales Tax */}
                    <div className="col-lg-3 d-flex">
                      <label>
                        GST {fees?.[0]?.tax || 0.0}%: $
                        {parseFloat(taxes).toFixed(2)}
                      </label>
                    </div>

                    {/* Sub Total */}
                    <div className="col-lg-3 d-flex">
                      <label>
                        Credit Card Fee : $
                        {creditCardFeeAdded
                          ? Number.parseFloat(
                              ((subTotal + taxes) * fees?.[0]?.credit_fee) / 100
                            ).toFixed(2)
                          : 0}
                      </label>
                    </div>

                    {/* Total */}
                    <div className="col-lg-3 d-flex">
                      <label>Total: ${total.toFixed(2)}</label>
                      {/* {//hideconsole.log("123", taxValue.toFixed(2))} */}
                    </div>
                  </div>

                  {savedT_c ? (
                    <div className="mt-3">
                      <input type="checkbox" style={{ display: "none" }} />
                      <label className="checkbox checkbox-single">
                        <input
                          type="checkbox"
                          name="signature"
                          checked={accepted}
                          onChange={(e) => setAccepted(e.target.checked)}
                        />
                        <span />
                      </label>
                      &nbsp;&nbsp;
                      <span className="font-weight-bold">
                        I have read and agreed with the&nbsp;
                        <a
                          className="text-primary"
                          onClick={() => setShowTC(true)}
                        >
                          Terms & Conditions.
                        </a>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  <label className="mt-3 font-weight-bold">Signature:</label>
                  <div
                    style={{
                      border: "1px solid lightgray",
                      width: "100%",
                    }}
                  >
                    <SignatureCanvas
                      ref={sigCanvas}
                      onEnd={() => setEmpty(false)}
                      canvasProps={{
                        className: "sigCanvas",
                      }}
                    />
                  </div>

                  <button
                    type="button"
                    onClick={handleClear}
                    className="btn btn-light btn-elevate mt-3"
                  >
                    Clear
                  </button>

                  <div className="row mt-3">
                    <div className="col-6">
                      <label>Signed By:</label>
                      <input
                        value={signedBy}
                        onChange={(e) => setSignedBy(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                    <div className="col-6">
                      <label>Email:</label>
                      <input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                      ></input>
                    </div>
                  </div>
                  <div className="row mt-3 ml-0">
                    <label>
                      <b>Notes</b>
                    </label>
                    <textarea
                      onChange={(e) => setNotes(e.target.value)}
                      value={notes}
                      className="form-control"
                      cols="3"
                      placeholder="Notes"
                    ></textarea>
                  </div>

                  <div className="float-right mt-3">
                    <button
                      type="button"
                      onClick={saveSignature}
                      disabled={!accepted || !signedBy || empty}
                      className="btn btn-primary btn-elevate"
                    >
                      Save
                      {isSubmitting ? (
                        <span
                          className="spinner-border spinner-border-sm ml-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        ""
                      )}
                    </button>
                  </div>
                </>
              )}
            </CardBody>
          </Card>
        </div>
      ) : (
        <LayoutSplashScreen />
      )}
      <div className={styles.footer}>
        <div style={{ fontWeight: "500" }} className="mt-2">
          Created Using
        </div>
        <Link to="/">
          <img
            style={{ marginTop: "-25px" }}
            alt="Logo"
            className="max-h-80px"
            src={toAbsoluteUrl("/media/logos/logo.png")}
          />
        </Link>
      </div>

      <TCModal
        t_c={t_c}
        showTC={showTC}
        onHide={() => setShowTC(false)}
      ></TCModal>
    </>
  );
}
