import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";

import { Formik, Form, Field } from "formik";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import { TextField } from "@mui/material";
import { headerSortingClasses, sortCaret } from "../../../_metronic/_helpers";
import CustomBootstrapTable from "./../../../_metronic/_helpers/CustomBootstrapTable.js";
import { useSelector } from "react-redux";
import { Paper, Tabs, Tab } from "@material-ui/core/";
import * as clientPortalAuth from "./ClientPortalAuth.js";
import { QuotesAndInvoices } from "./QuotesAndInvoices.js";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { ClientPortalAppointments } from "./ClientPortalAppointments.js";
import { Refresh } from "@mui/icons-material";
export function ClientPortalDashboard() {
  function getEvents() {
    //hideconsole.log("getting appoinments data");
    setGettingAppointmentData(true);
    setEvents(null);
    axios
      .get(`https://www.api.sendatradie.com/client/getAppointments/${Number(customerId)}`)
      .then((response) => {
        if (response) {
          // //hideconsole.log(response.data.result);
          //hideconsole.log("Got appoinment data");
          setEvents(response.data.result, response.data.result);
          //hideconsole.log(response.data);
          setGettingAppointmentData(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [events, setEvents] = useState(null);
  const DragAndDropCalendar = withDragAndDrop(Calendar);
  const localizer = momentLocalizer(moment);
  const props = useSelector((state) => state.clientPortalAuth.userData);
  const customerId = Number(
    useSelector((state) => state.clientPortalAuth.userData.customer.id)
  );
  //hideconsole.log("customer ID is", customerId);

  const [onMobile, setOnMobile] = React.useState(false);
  const [gettingAppointmentData, setGettingAppointmentData] = React.useState(
    true
  );
  const [newBookingModal, setNewBookingModal] = React.useState(false);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [rawQuotesData, setrawQuotesData] = React.useState(props.quotes);
  const [rawInvoicesData, setrawInvoicesData] = React.useState(props.invoices);
  const [quotesData, setQuotesData] = React.useState(props.quotes);
  const [invoicesData, setInvoicesData] = React.useState(props.invoices);
  const date = new Date();
  const [ids, setIds] = React.useState([]);
  const [modalUrl, setModalUrl] = React.useState([]);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [invoiceIds, setInvoiceIds] = React.useState([]);
  const [search, setSearch] = React.useState(null);
  const [QuotesDate, setQuotesDate] = React.useState(new Date());
  const [newQuoteNotes, setNewQuoteNotes] = React.useState("");
  const [fileUpload, setFileUpload] = React.useState("");
  const [service, setService] = React.useState("");
  const companies = [];
  const company_ids = [];
  const history = useHistory();
  const InitialValue = {
    company_name: "",
    date: "",
    note: "",
  };
  const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };
  const [columns, setColumns] = React.useState([
    {
      text: "Company",
      dataField: "name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Action",
      dataField: "id",
      formatter: (cell, row) => {
        const baseUrl = "https://platform.sendatradie.com/clientPortal/quotes/";
        return (
          <button
            className="btn btn-sm btn-light tour_job_id"
            onClick={() => window.open(baseUrl + cell, "_blank")}
          >
            View Quote
          </button>
        );
      },
    },
    {
      text: "Date",
      dataField: "date_time",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ]);
  const [invoiceColumns, setInvoiceColumns] = React.useState([
    {
      text: "Company",
      dataField: "company_name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Action",
      dataField: "invoice_file",
      formatter: (cell, row) => {
        const baseUrl =
          "https://platform-295512.appspot.com.storage.googleapis.com/";
        return (
          <button
            className="btn btn-sm btn-light tour_job_id"
            onClick={() => {
              setModalUrl(baseUrl + cell + "#toolbar=0&navpanes=0&scrollbar=0");
              setModalVisible(true);
            }}
          >
            View Invoice
          </button>
        );
      },
    },
    {
      text: "Date",
      dataField: "date_time",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ]);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}`;
  const [tab, setTab] = React.useState(0);
  const [newQuoteModal, setNewQuoteModal] = React.useState(false);
  const handleChange = function handleChange(event, newValue) {
    setTab(newValue);
  };

  const [Overlay, setOverlay] = useState(false);
  const [ProfileClass, setProfileClass] = useState(
    "offcanvas offcanvas-right p-10"
  );

  const handleCompanyChange = (company) => {
    setSelectedCompany(company);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    setOnMobile(deviceType() == "mobile");
  }, []);

  useEffect(() => {
    //hideconsole.log("getting appoinments data");
    setGettingAppointmentData(true);
    setEvents(null);
    axios
      .get(`https://www.api.sendatradie.com/client/getAppointments/${Number(customerId)}`)
      .then((response) => {
        if (response) {
          // //hideconsole.log(response.data.result);
          //hideconsole.log("Got appoinment data");
          setEvents(response.data.result);
          //hideconsole.log(response.data);
          setGettingAppointmentData(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (tab == 0) {
      var newData = [...rawQuotesData];
      search &&
        (newData = newData.filter(
          (item) =>
            item.name
              .toString()
              .toUpperCase()
              .includes(search.toUpperCase()) ||
            item.date_time.toString().includes(search)
        ));
      setQuotesData(newData);
    } else if (tab == 1) {
      var newData = [...rawInvoicesData];
      search &&
        (newData = newData.filter(
          (item) =>
            item.company_name
              .toString()
              .toUpperCase()
              .includes(search.toUpperCase()) ||
            item.date_time.toString().includes(search)
        ));
      setInvoicesData(newData);
    }
  }, [search, tab]);

  function handleFileUpload(file) {
    setFileUpload(file);
  }

  function handleSelectService(s) {
    setService(s);
  }

  for (let i = 0; i < props.quotes.length; i++) {
    var quote = props.quotes[i];
    var c = {};
    c["label"] = quote["name"];
    c["value"] = Number(quote["company_id"]);
    if (company_ids.includes(c["id"])) {
      continue;
    }
    companies.push(c);
    company_ids.push(c["id"]);
  }

  for (let i = 0; i < props.invoices.length; i++) {
    var invoice = props.invoices[i];
    var c = {};
    c["label"] = invoice["company_name"];
    c["value"] = Number(invoice["company_id"]);
    if (company_ids.includes(c["id"])) {
      continue;
    }
    companies.push(c);
    company_ids.push(c["id"]);
  }

  function showProfile() {
    setProfileClass("offcanvas offcanvas-right p-10 offcanvas-on");
    setOverlay(true);
  }
  function hideProfile() {
    setProfileClass("offcanvas offcanvas-right p-10");
    setOverlay(false);
  }
  function logOut() {
    dispatch(clientPortalAuth.actions.clientportallogout());
    history.push("/clientportal/login");
  }

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="d-flex flex-row flex-column-fluid page">
          <div className="aside aside-left  aside-fixed d-flex flex-column flex-row-auto">
            <div className="brand flex-column-auto " id="kt_brand">
              <a className="brand-logo" href="/clientPortal/login">
                <img alt="logo" src="/media/logos/logo-light.png" />
              </a>
            </div>
          </div>
          <div className="d-flex flex-column flex-row-fluid wrapper">
            <div className="header">
              <div className="container d-flex align-items-stretch justify-content-end mx-0 w-100 mw-100">
                <div
                  className="header-progress-bar"
                  style={{ height: "3px", width: "100%" }}
                ></div>
                <div className="w-70 d-flex">
                  <button
                    type="button"
                    className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                    id="kt_quick_user_toggle"
                    onClick={showProfile}
                  >
                    <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                      Hi,
                    </span>
                    <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
                      {props.customer.name.split(" ")[0]}{" "}
                    </span>
                    <span className="symbol symbol-35 symbol-light-success">
                      <span className="symbol-label font-size-h5 font-weight-bold">
                        {props.customer.name[0].toUpperCase()}
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="content  d-flex flex-column flex-column-fluid">
              <div
                id="kt_subheader"
                className="subheader py-2 py-lg-4   subheader-solid"
              >
                <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                  <div className="font-weight-bold font-size-h6 text-dark text-hover-primary link-dark nav-item dropdown"></div>
                  <div className="d-flex align-items-center">
                    <a
                      href="#"
                      className="btn btn-light btn-sm font-weight-bold"
                      id="kt_dashboard_daterangepicker"
                      data-toggle="tooltip"
                      title="Select dashboard daterange"
                      data-placement="left"
                      onClick={(event) => event.preventDefault()}
                      style={{ marginRight: "15px" }}
                    >
                      <span
                        className="text-muted font-weight-bold mr-2"
                        id="kt_dashboard_daterangepicker_title"
                      >
                        Today
                      </span>
                      <span
                        className="text-primary font-weight-bold"
                        id="kt_dashboard_daterangepicker_date"
                      >
                        {formattedDate}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column-fluid">
                <div className="container">
                  <QuotesAndInvoices />
                  {/* <ClientPortalAppointments events={events} /> */}
                  {gettingAppointmentData ? (
                    <></>
                  ) : (
                    <ClientPortalAppointments
                      events={events}
                      setEvents={setEvents}
                      customerId={customerId}
                      companies={companies}
                      getEvents={getEvents}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {Overlay ? <div className="offcanvas-overlay"></div> : <div></div>}

      <div id="kt_quick_user" className={ProfileClass}>
        <div
          className="offcanvas-header d-flex align-items-center justify-content-between pb-5"
          kt-hidden-height="43"
        >
          <h3 className="font-weight-bold m-0">User Profile</h3>
          <button
            href="#"
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
            onClick={hideProfile}
          >
            <i className="ki ki-close icon-xs text-muted"></i>
          </button>
        </div>
        <div
          className="offcanvas-content pr-5 mr-n5 scroll ps"
          style={{ height: "817px" }}
        >
          <div className="d-flex align-items-center mt-5">
            <div
              className="symbol symbol-100 mr-5"
              style={{ cursor: "pointer" }}
            >
              <div className="symbol-label">
                <img
                  src="https://www.sendatradie.com/wp-content/uploads/logo-new.svg"
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              <i className="symbol-badge bg-success"></i>
            </div>
            <div className="d-flex flex-column">
              <a
                href="#"
                className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              >
                {props.customer.name.split(" ")[0]}{" "}
              </a>
              <button
                className="btn btn-light-primary btn-bold"
                onClick={logOut}
              >
                Sign out
              </button>
            </div>
          </div>
          <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
            <div
              className="ps__thumb-x"
              tabIndex="0"
              style={{ left: "0px", width: "0px" }}
            ></div>
          </div>
          <div className="ps__rail-y" style={{ top: "0px", right: "0px" }}>
            <div
              className="ps__thumb-y"
              tabIndex="0"
              style={{ top: "0px", height: "0px" }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}
