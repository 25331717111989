import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Paper } from "@material-ui/core/";
import { Formik, Form, Field } from "formik";
import moment from "moment";
import Select from "react-select";
import { TextField } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import LockIcon from "@material-ui/icons/Lock";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as auth from "./../../Auth/_redux/authRedux.js";
import * as Yup from "yup";
import axios from "axios";
// FaCcStripe
import { FaCcStripe } from "react-icons/fa";
import {
  Input,
  DatePickerField,
} from "../../../../_metronic/_partials/controls";
import * as requestFromServer from "./../../Jobs/_redux/customers/customersCrud.js";
import { toast } from "react-toastify";
import { number } from "prop-types";
import { Switch } from "@material-ui/core";
import { getDataSettingRequest } from "./../../../../_metronic/_partials/axios/Requests.js";
import { useSelector } from "react-redux";
import CardInfoForm from "./CardInfoForm";

const initialState = {
  NameOncard: "",
  CardNumber: "",
  //   BillCycle: {},
  Plan: {},
  BillingAddress: "",
  ExpirationYear: moment(new Date()).format("yyyy"),
  ExpirationMonth: moment(new Date()).format("MM"),
  CVV: "",
  price: null,
};

// const CustomerEditSchema = Yup.object().shape({
//   CardNumber: Yup.number()
//     .max(17, "Invalid card number")
//     .min(15, "Invalid card number")
//     .required("Card number is required"),
//   CVV: Yup.number()
//     .max(5, "Invalid CVV")
//     .min(2, "Invalid CVV")
//     .required("CVV is required"),
// });

const validateCVV = (value) => {
  let errorMessage;
  if (!/^[0-9]{3,4}$/.test(value)) {
    errorMessage = "Invalid CVV";
  }
  return errorMessage;
};

const validateCardNumber = (value) => {
  let errorMessage;
  if (!/^[0-9]{16}$/.test(value)) {
    errorMessage = "Invalid card number ";
  }
  return errorMessage;
};

export const PlanSelectDetailDailog = ({
  show,
  type,
  onHide,
  AllPlan,
  selectedPlan,
  setSelectedPlan,
  requestUser,
  getData,
  selectedNumber,
  setPlanUntil,
}) => {
  const user = useSelector((state) => state.auth.user);
  const [initialValues, setinitialValues] = useState(
    type === "plan" ? { ...initialState, BillCycle: {} } : initialState
  );
  const [expireDate, setExpireDate] = useState(
    moment().format("yyyy-MM-DDTHH:mm")
  );
  const [optionforPlan, setoptionforPlan] = useState([]);
  const [SelectoptionforPlan, setSelectoptionforPlan] = useState({});
  const [optionforSMSPlan, setoptionforSMSPlan] = useState([]);
  const [SelectoptionforSMSPlan, setSelectoptionforSMSPlan] = useState({});
  const [customerData, setcustomerData] = useState({});
  const [customerId, setcustomerId] = useState("");
  const [selectoptionforBill, setselectoptionforBill] = useState({
    value: 2,
    label: "Annually",
  });
  const [submitting, setSubmitting] = useState(false);
  const [paymentDone, setpaymentDone] = useState(false);
  const history = useHistory();
  const optionforBill = [
    { value: 2, label: "Annually" },
    { value: 1, label: "Monthly" },
  ];
  const [autoRefill, setAutoRefill] = useState(true);
  const [newCard, setNewCard] = useState(false);
  const [currentBillingCircle, setCurrentBillingCircle] = useState(0);
  const [changePlan, setChangePlan] = useState(false);
  const [priceDiff, setPriceDiff] = useState(0);
  const [paymnetUntil, setPaymentUntil] = useState(null);
  const [applying, setApplying] = useState(false);
  const [discount, setDiscount] = useState(null);

  const handleChangePlan = (event) => {
    setSelectedPlan(
      AllPlan.find((item) => {
        return item.name === event.label;
      })
    );
  };

  const handleBillCycle = (event) => {
    //hideconsole.log(event);
    setselectoptionforBill(event);
    setinitialValues({
      ...initialValues,
      price:
        selectedPlan[event.value == 1 ? "monthly_price" : "annually_price"],
    });
  };

  const handleDateChange = (date, type) => {
    const data = initialValues;
    let ChnageFormateWiseDate;
    //hideconsole.log(data);
    //hideconsole.log(date);
    if (type === "ExpirationYear") {
      ChnageFormateWiseDate = moment(date).format("yyyy");
      setExpireDate(
        moment(expireDate)
          .set("year", moment(date).year())
          .format("yyyy-MM-DDTHH:mm")
      );
    } else {
      ChnageFormateWiseDate = moment(date).format("MM");
      setExpireDate(
        moment(expireDate)
          .set("month", moment(date).month())
          .format("yyyy-MM-DDTHH:mm")
      );
    }

    data[type] = ChnageFormateWiseDate;
    setinitialValues(data);
  };

  useEffect(() => {
    if (type === "plan") {
      //hideconsole.log(initialValues);
      //hideconsole.log(">>>>>>>>>>>", selectedPlan);
      const InitialSelectPlan = {
        value: selectedPlan?.id,
        label: selectedPlan?.name,
      };
      setSelectoptionforPlan(InitialSelectPlan);
      setselectoptionforBill({ value: 2, label: "Annually" });
      setinitialValues({
        ...initialValues,
        price: selectedPlan?.annually_price,
      });
      const optionsPlan = AllPlan
        ? AllPlan.map((item, index) => {
            return { value: item.id, label: item.name };
          })
        : {};
      setoptionforPlan(optionsPlan);
    } else {
      const InitialSelectPlan = {
        value: selectedPlan?.id,
        label: selectedPlan?.name,
      };
      setSelectoptionforSMSPlan(InitialSelectPlan);
      const optionsPlan = AllPlan
        ? AllPlan.map((item, index) => {
            return { value: item.id, label: item.name };
          })
        : {};
      setoptionforSMSPlan(optionsPlan);
      setinitialValues({ ...initialValues, price: selectedPlan?.price });
    }
  }, [selectedPlan]);

  const modalClose = () => {
    setExpireDate(moment().format("yyyy-MM-DDTHH:mm"));
    setinitialValues(initialState);
    onHide();
  };

  const getCustomerId = () => {
    requestFromServer
      .createCustomerId()
      .then((response) => {
        //hideconsole.log(response.data.result, "response.SMSdata.result.");
        if (response.data.result.length) {
          //hideconsole.log(response.data.result[0]);
          if (
            response.data.result[0]?.payment_until &&
            !response.data.result[0]?.stripe_customer_id
          ) {
            setPlanUntil(response.data.result[0]?.payment_until);
          }
          setcustomerData(response.data.result[0]);

          setcustomerId(response.data.result[0].stripe_customer_id);
          setCurrentBillingCircle(response.data.result[0].billing_circle);
          if (!response.data.result[0].stripe_customer_id) {
            setNewCard(true);
          }
        } else {
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });
  };

  const getChangePlanPreview = () => {
    requestFromServer
      .getChangePlanPreview({
        id: selectedPlan.id,
        mode: selectoptionforBill.label,
      })
      .then((res) => {
        // //hideconsole.log(res.data);

        setPaymentUntil(res.data.payment_until);
        if (currentBillingCircle != selectoptionforBill.value) {
          setPriceDiff(res.data.amount_due);
        } else {
          if (user.pack_id < selectedPlan.id || user.pack_id == 5) {
            //hideconsole.log("calculate---------------------");

            setPriceDiff(
              res.data.amount_due -
                (selectoptionforBill.label == "Monthly"
                  ? selectedPlan.monthly_price * 100
                  : selectedPlan.annually_price * 12 * 100)
            );
          } else {
            setPriceDiff(0);
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });
  };

  useEffect(() => {
    getCustomerId();
  }, []);

  useEffect(() => {
    if (type == "plan" && selectedPlan.id) {
      getChangePlanPreview();
      setChangePlan(
        (user.pack_id != selectedPlan.id ||
          currentBillingCircle != selectoptionforBill.value) &&
          user.pack_id != 5
      );
    }
  }, [selectoptionforBill, selectedPlan]);

  const handlePayment = async (value) => {
    try {
      //get data from Form
      setSubmitting(true);
      if (newCard) {
        //hideconsole.log(value);
        let customerId =
          customerData?.stripe_customer_id?.length === 0
            ? ""
            : customerData?.stripe_customer_id;
        let cardToken;
        const data = {
          ...initialValues,
          BillingAddress: value.BillingAddress,
          CVV: value.CVV,
          CardNumber: value.CardNumber,
          NameOncard: value.NameOncard,
          mode: selectoptionforBill.label,
        };
        setinitialValues(data);
        //hideconsole.log(data);
        //hideconsole.log(initialValues);

        const tokenParams = {
          card: {
            number: data.CardNumber,
            exp_year: data.ExpirationYear,
            exp_month: data.ExpirationMonth,
            cvc: data.CVV,
          },
          type: "card",
        };

        const createPayment = await requestFromServer.stripeCreateToken(
          tokenParams
        );

        //hideconsole.log(createPayment);
        //hideconsole.log("Token created");

        cardToken = createPayment.data.id;
        //hideconsole.log(cardToken);

        if (!customerData?.stripe_customer_id?.length) {
          //hideconsole.log("customer id not found");

          const customerParams = {
            name: customerData.name,
            email: customerData.email,
            description: `Customer generated using ${customerData.name} and ${customerData.email}`,
          };

          const createCustomer = await requestFromServer.stripeCreateCustomer(
            customerParams
          );

          //hideconsole.log(createCustomer.data);

          customerId = createCustomer?.data?.id;
        }
        //hideconsole.log(data);
        //hideconsole.log(customerId, data.price, cardToken);

        postPaymentRequestApi(
          customerId,
          data.price,
          cardToken,
          value.discount_code
        );
      } else {
        //hideconsole.log(selectedPlan);
        postPaymentRequestApi(
          customerId,
          type == "plan" ? initialValues.price : selectedPlan?.price,
          0
        );
      }
      // setcustomerData(initialValues)
    } catch (err) {
      console.log(err);
      //hideconsole.log("payment failed---------------------");
      toast.error("Payment failed");
      setSubmitting(false);
    }
  };

  const postPaymentRequestApi = (CustomerId, price, token, discount_code) => {
    if (submitting) return;
    const passDate = {
      customerId: CustomerId,
      price:
        selectoptionforBill.label == "Annually" && type == "plan"
          ? price * 12
          : price,
      pack: token,
      type: type,
      mode: selectoptionforBill.label,
      selectedNumber: selectedNumber,
      autoRefill: autoRefill,
      newCard: newCard,
      changePlan: changePlan,
      discountCode: discount?.id,
      trial: discount?.percent_off == 100,
    };

    //hideconsole.log("passDate", passDate);
    requestFromServer
      .createPOstCustomerId(passDate)
      .then((response) => {
        //hideconsole.log("log 3");
        //hideconsole.log(response.data.result, "response.SMSdata.result.");
        const testVAluechecvk = requestUser();
        //hideconsole.log(testVAluechecvk);
        setSubmitting(false);
        // if(testVAluechecvk.type === "[Request User] Action"){
        //   window.location.href = "/dashboard";
        // }
        setpaymentDone(true);
        modalClose();
        if (type == "SMSplan" || type == "CallPlan") {
          getData();
        }
      })
      .catch((error) => {
        setSubmitting(false);
        console.log(error);
        toast.error("Payment failed");
        if (error?.response?.data?.message == "User not authorized.") {
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });
  };

  useEffect(() => {
    //hideconsole.log("123", paymentDone);
    if (paymentDone) {
      //hideconsole.log("123");
      // window.location.href = "/dashboard";
      toast.success(`Payment successful.`);
      getCustomerId();
    }
  }, [paymentDone]);

  function applyDiscountCode(code) {
    //hideconsole.log(code);
    setApplying(true);
    requestFromServer
      .applyDiscountCode({ code })
      .then((res) => {
        if (!res.data.discount) {
          toast.warning("Invalid discount code.");
        }
        setDiscount(res.data.discount);
        setApplying(false);
      })
      .catch((error) => {
        setApplying(false);
        console.log(error);
        toast.warning("Invalid discount code.");
        if (error?.response?.data?.message == "User not authorized.") {
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
      show={selectedPlan && show}
      onHide={modalClose}
    >
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">Payment</Modal.Title>
        <button
          type="button"
          className="btn btn-light btn-close mr-0"
          aria-label="Close"
          onClick={modalClose}
        >
          x
        </button>
      </Modal.Header>
      <Paper square>
        {user.franchise_of ? (
          <div className="mb-13 text-center">
            <h1 className="fs-2hx fw-bold mb-5">
              For changing plans, please contact your parent company.
            </h1>
          </div>
        ) : (
          <Formik
            enableReinitialize={false}
            initialValues={initialValues}
            // validationSchema={CustomerEditSchema}
            onSubmit={(values) => {
              handlePayment(values);
            }}
          >
            {({ handleSubmit, checked, errors, touched, values }) => (
              <>
                <Form className="form form-label-right">
                  <Modal.Body>
                    {customerId ? (
                      <div
                        className="row mt-3 align-items-center"
                        style={{ marginLeft: "0.5px" }}
                      >
                        <Switch
                          variant="contained"
                          onClick={(e) => {
                            setNewCard(e.target.checked);
                          }}
                          color="primary"
                          disabled={!customerId}
                          checked={newCard}
                        ></Switch>
                        Use new card.
                      </div>
                    ) : (
                      ""
                    )}
                    {newCard ? (
                      <>
                        <div className="col-lg-6">
                          <label>Name on Card</label>
                          <Field
                            name="NameOncard"
                            className="form-control"
                            component={Input}
                            withFeedbackLabel={false}
                          />
                        </div>
                        <div className="col-lg-6 mt-3">
                          <label>Card Number</label>
                          <Field
                            type="number"
                            name="CardNumber"
                            validate={validateCardNumber}
                            className="form-control"
                            component={Input}
                            withFeedbackLabel={false}
                          />
                          {errors.CardNumber && touched.CardNumber ? (
                            <div className="text-danger">
                              {errors.CardNumber}
                            </div>
                          ) : null}
                        </div>
                        <div
                          className="row mt-3"
                          style={{ marginLeft: "0.5px" }}
                        >
                          <div className="col-lg-4">
                            <label>Expiration Month</label>
                            <KeyboardDatePicker
                              disableToolbar
                              autoOk
                              clearable="true"
                              style={{ padding: `0.65rem 1rem` }}
                              variant="inline"
                              format="MM"
                              views={["month"]}
                              name="ExpirationMonth"
                              value={expireDate}
                              onChange={(e) => {
                                handleDateChange(e, "ExpirationMonth");
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <label>Expiration Year</label>
                            <KeyboardDatePicker
                              views={["year"]}
                              clearable="true"
                              style={{ padding: `0.65rem 1rem` }}
                              autoOk
                              minDate={new Date()}
                              name="ExpirationYear"
                              disableToolbar
                              variant="inline"
                              value={expireDate}
                              onChange={(e) => {
                                handleDateChange(e, "ExpirationYear");
                              }}
                            />
                          </div>
                          <div className="col-lg-4">
                            <label>CVV</label>
                            <Field
                              name="CVV"
                              validate={validateCVV}
                              className="form-control"
                              component={Input}
                              placeholder="ex. 311"
                              withFeedbackLabel={false}
                            />
                            {errors.CVV && touched.CVV ? (
                              <div className="text-danger">{errors.CVV}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 mt-3">
                          <label>Billing Address</label>
                          <Field
                            name="BillingAddress"
                            className="form-control"
                            component={Input}
                            withFeedbackLabel={false}
                          />
                        </div>
                        {type === "plan" ? (
                          <div className="row mt-3 ml-0 align-items-end">
                            <div className="col-lg-6">
                              <label>Discount Code</label>
                              <Field
                                name="discount_code"
                                className="form-control"
                                component={Input}
                                withFeedbackLabel={false}
                              />
                            </div>
                            <div className="col-lg-6">
                              <button
                                type="button"
                                disabled={applying}
                                className="btn btn-light-primary"
                                onClick={() =>
                                  applyDiscountCode(values.discount_code)
                                }
                              >
                                Apply
                                {applying ? (
                                  <span
                                    className="spinner-border spinner-border-sm ml-1"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                ) : (
                                  ""
                                )}
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    <div className="row mt-3" style={{ marginLeft: "0.5px" }}>
                      {type === "plan" ? (
                        <>
                          <div className="col-lg-6">
                            <label>Plan</label>
                            <Select
                              classNamePrefix="mySelect"
                              name="Plan"
                              label="Plan"
                              options={optionforPlan}
                              value={SelectoptionforPlan}
                              onChange={handleChangePlan}
                            ></Select>
                          </div>
                          <div className="col-lg-6">
                            <label>Bill</label>
                            <Select
                              classNamePrefix="mySelect"
                              name="BillCycle"
                              label="Bill"
                              options={optionforBill}
                              value={selectoptionforBill}
                              onChange={handleBillCycle}
                            ></Select>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-lg-6">
                            <label>
                              {type == "SMSplan" ? "SMS" : "Call"} Plan
                            </label>
                            <Select
                              classNamePrefix="mySelect"
                              name="Plan"
                              label="Plan"
                              options={optionforSMSPlan}
                              value={SelectoptionforSMSPlan}
                              onChange={handleChangePlan}
                            ></Select>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row mt-3" style={{ marginLeft: "0.5px" }}>
                      <div className="col-lg-6">
                        {type === "plan"
                          ? `${
                              discount
                                ? `${
                                    discount.percent_off
                                      ? discount?.percent_off + `%`
                                      : `$` + discount?.amount_off / 100
                                  } off for ${
                                    discount.duration_in_months
                                  } months, then `
                                : ""
                            }$${
                              selectoptionforBill.label == "Annually"
                                ? initialValues.price * 12
                                : initialValues.price
                            } Paid ${selectoptionforBill.label}`
                          : `$${initialValues.price}`}
                      </div>
                      <div className="col-lg-6">
                        <FaCcStripe size={"30"} /> Secured by Stripe
                      </div>
                    </div>
                    {priceDiff > 0 &&
                    !(
                      user.pack_id == selectedPlan.id &&
                      currentBillingCircle == selectoptionforBill.value
                    ) ? (
                      <div className="row mt-3" style={{ marginLeft: "0.5px" }}>
                        A price difference of ${priceDiff / 100} will be
                        charged.
                      </div>
                    ) : (
                      ""
                    )}
                    {type == "SMSplan" || type == "CallPlan" ? (
                      <div
                        className="row mt-3 align-items-center"
                        style={{ marginLeft: "0.5px" }}
                      >
                        <Switch
                          variant="contained"
                          onClick={(e) => {
                            setAutoRefill(e.target.checked);
                          }}
                          color="primary"
                          checked={autoRefill}
                        ></Switch>
                        Refill when there are less than 5{" "}
                        {type == "SMSplan" ? "messages" : "minutes"} remain.
                      </div>
                    ) : (
                      ""
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="submit"
                      disabled={
                        submitting ||
                        (user.pack_id == selectedPlan.id &&
                          currentBillingCircle == selectoptionforBill.value)
                      }
                      className="btn btn-primary btn-elevate"
                    >
                      {user.pack_id == selectedPlan.id &&
                      currentBillingCircle == selectoptionforBill.value
                        ? "Current Plan"
                        : changePlan
                        ? "Switch Plan"
                        : "Confirm"}
                      {submitting ? (
                        <span
                          className="spinner-border spinner-border-sm ml-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      ) : (
                        ""
                      )}
                    </button>
                  </Modal.Footer>
                </Form>
              </>
            )}
          </Formik>
        )}
      </Paper>
    </Modal>
  );
};

export default injectIntl(connect(null, auth.actions)(PlanSelectDetailDailog));
