import React, { useMemo, useState, useEffect } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";

// import { CustomersFilter } from "./../../ECommerce/pages/customers/customers-filter/CustomersFilter.js"
import LeadsTable from "./LeadsTable.js";
import { CreateLeadsForm } from "./CreateLeadsForm.js";
// import { CustomersGrouping } from "./../../ECommerce/pages/customers/customers-grouping/CustomersGrouping.js"
// import { useCustomersUIContext } from "./../../ECommerce/pages/customers/CustomersUIContext.js"
import { Paper, Tabs, Tab } from "@material-ui/core/";
export function LeadsHeader(props) {
  const [showAddModal, setShowAddModal] = useState(false);
  const [isCreateLeads, setisCreateLeads] = useState(true);
  const handleModal = () => {
    setShowAddModal(!showAddModal);
  };
  useEffect(() => {}, [showAddModal]);

  return (
    <>
      <Card>
        <CardHeader title="Leads List">
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={handleModal}
              className="btn btn-primary"
            >
              New Leads
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <LeadsTable
            setisCreateLeads={setisCreateLeads}
            isCreateLeads={isCreateLeads}
            settings={props.settings}
          />
        </CardBody>
      </Card>
      <CreateLeadsForm
        show={showAddModal}
        setisCreateLeads={setisCreateLeads}
        onHide={handleModal}
      />
    </>
  );
}
