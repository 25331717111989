import React, { useEffect, useState } from "react";
import axios from "axios";
export function DisplayLocations({
  data,
  setChosenLocation,
  locationsAdded,
  loaded,
}) {
  // const [tempData, setTempData] = useState([]);
  // useEffect(() => {
  //   let temp = [];
  //   for (let i = 0; i < 10; i++) {
  //     temp.push(data[0]);
  //   }
  //   setTempData(temp);
  // }, []);
  return (
    <>
      {!locationsAdded ? (
        <>
          <div className="form-group row">
            Please add locations in GoogleMyBusiness in Settings
          </div>
        </>
      ) : (
        <>
          <div className="form-group row">Please select Location</div>
          <div className="form-group row">
            {" "}
            {data.map((item, key) => {
              return (
                <div className="mr-4 mb-2">
                  {" "}
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bolder font-size-sm"
                    onClick={() => {
                      setChosenLocation(item);
                    }}
                  >
                    {`"${item?.title.toUpperCase()}"` +
                      ", " +
                      item?.storefrontAddress?.addressLines[0] +
                      ", " +
                      item?.storefrontAddress?.locality +
                      ", " +
                      item?.storefrontAddress?.postalCode +
                      ", " +
                      item?.storefrontAddress?.administrativeArea}
                  </button>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}
