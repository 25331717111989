import React from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import "./Footer.scss";

const CustomFooter = () => {
  return (
    <footer className="footer">
      <div className="container text-center">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/logo-dark.png")}
            className="footer-logo"
          />
          <span className="text-muted">
            Copyright 2022 © Sendatradie Pty Ltd ABN: 40638562231
          </span>
      </div>
    </footer>
  );
};

export default CustomFooter;
