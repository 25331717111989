import React from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export default function TermsConditions() {
  return (
    <div className="d-flex justify-content-center text-align-center bg-white">
      <div className=" d-flex flex-column justify-content-center">
        <div className="container">
          <h1>Terms and Conditions</h1>
          <b>Terms of Service </b>
          <p>
            Welcome to Sendatradie, an online job management service designed
            for Tradies. Sendatradie is a software platform for trade
            contractors (“Tradie” and “Tradies”) to assist them to manage their
            business more effectively. It’s cloud-based Software as a Service
            (SaaS) allowing Tradies to manage key aspects of their business from
            anywhere that has internet access, including job sites that the
            Tradie visits. Office staff of the Tradie can also access the
            platform to operate their business.{" "}
          </p>
          <p>
            The following are the terms and conditions for use of the
            Sendatradie service described herein (the "Service") between
            Sendatradie and you (either an individual or a legal entity that you
            represent as an authorized employee or agent) ("You").
          </p>
          <p>
            These Terms are binding on any use of the Service and apply to You
            from the time that Sendatradie provides You with access to the
            Service.
          </p>
          <p>
            By registering to use the Service You acknowledge that You have
            read, understood and agreed to these Terms and have the authority to
            act on behalf of any person for whom You are using the Service. You
            are deemed to have agreed to these Terms on behalf of any entity for
            whom you use the Service.
          </p>
          <b>DEFINITIONS</b>
          <p>"Agreement" means these Terms of Service.</p>
          <p>
            “Add-on” means an additional, activated service that enhances the
            Sendatradie platform.
          </p>
          <p>
            "Confidential Information" includes all information exchanged
            between the parties to this Agreement, whether in writing,
            electronically or orally, including the Service, but does not
            include information which is, or becomes, publicly available other
            than through unauthorised disclosure by the other party.
          </p>
          <p>
            "Data" refers to written and graphical content provided by or
            through the Service, including, without limitation, text,
            photographs, videos, recordings, files, illustrations, and designs,
            whether provided by You, another customer of the Service, or any
            other third party.
          </p>
          <p>
            "Intellectual Property Right" means any patent, trade mark, service
            mark, copyright, moral right, right in a design, know-how and any
            other intellectual or industrial property rights, anywhere in the
            world whether or not registered.
          </p>
          <p>
            "End Customer" means any person or entity, other than You, that uses
            the Service with Your authorisation from time to time.
          </p>
          <p>
            "Service" means the online job management and ancillary services
            made available (as may be changed or updated from time to time by
            Sendatradie) via Sendatradie, including Sendatradie's optional phone
            communications service.
          </p>
          <p>"Sendatradie" means Sendatradie Pty Ltd trading as Sendatradie.</p>
          <p>
            "Subscriber" means the person who registers to use the Service (i.e.
            the Tradie), and, where the context permits, includes any entity on
            whose behalf that person registers to use the Service (such as an
            office staff member of the Tradie). The Subscriber is the sole owner
            of the Sendatradie account and its data and is displayed as the
            ‘Account Owner’ within the account.{" "}
          </p>
          <p>
            "Usage Fee" means a monetary fee for Your use of and access to the
            Service, based on your level of usage or pricing plan, plus any
            additional costs associated with overages or add-ons activated
            within the Sendatradie service, payable by You, in accordance with
            the fee schedule set out on the Website or within Your Sendatradie
            service.
          </p>
          <p>
            "You" means the Subscriber, and where the context permits, an End
            Customer. "Your" has a corresponding meaning.
          </p>
          <p>
            "Website" means the Internet site at the domain www.Sendatradie.com
            or any other site operated by Sendatradie.
          </p>
          <b>USE OF PLATFORM</b>
          <p>
            Sendatradie grants You the right to access and use the Service via
            an online web-based platform with particular user roles available to
            You. This right is non-exclusive, non-transferable, and limited by
            and subject to this Agreement. You acknowledge and agree that,
            subject to any applicable written agreement between the Subscriber
            and the End Customers, or any other applicable laws:
          </p>
          <p>
            • the Subscriber determines who is an End Customer and what level of
            access to the relevant organisation and Service that End Customer
            has;
          </p>
          <p>
            • the Subscriber is responsible for all End Customers’ use of the
            Service;
          </p>
          <p>
            • the Subscriber controls each End Customer’s level of access to the
            relevant organisation and Service at all times and can revoke or
            change an End Customer’s access, or level of access, at any time and
            for any reason, in which case that person or entity will cease to be
            an End Customer or shall have that different level of access, as the
            case may be;
          </p>
          <p>
            • if there is any dispute between a Subscriber and an End Customer
            regarding access to any organisation or Service, the Subscriber
            shall decide what access or level of access to the relevant Data or
            Service that End Customer shall have, if any.
          </p>
          <p>
            The use of Sendatradie means by extension you agree to the Google
            Terms of Service and the Google Privacy Policy.
          </p>
          <b>YOUR OBLIGATIONS</b>
          <p>Payment obligations:</p>
          <p>
            Sendatradie will continue invoicing You in accordance with your
            pricing plan and service usage until this Agreement is terminated.
            All Sendatradie invoices will be sent to You, or to a Billing
            Contact whose details are provided by You, by email. You must pay
            all amounts specified in any invoice by the due date.
          </p>
          <p>
            You are solely liable for usage and accrued charges by You, or
            anyone else, that occurs on Your account, and You must pay all
            amounts specified in any invoice by the due date.
          </p>
          <p>
            Additionally, we will charge You, and You shall pay, any and all
            additional costs, fines, or penalties we incur from a government or
            regulatory body or telecommunication provider as a result of Your
            use of the Services.
          </p>
          <p>Preferential pricing or discounts:</p>
          <p>
            You may from time to time be offered preferential pricing or
            discounts to the Usage Fees through a special offer, or as a result
            of the number of organisations that You have added to the Service or
            that have been added with Your authority or as a result of Your use
            of the Service ('Organisations'). Eligibility for such preferential
            pricing or discounts is conditional upon Your acceptance of
            responsibility for payment of any Usage Fees in relation to all of
            Your Organisations. Without prejudice to any other rights that
            Sendatradie may have under these Terms or at law, Sendatradie
            reserves the right to render invoices for the full (non-discounted)
            Usage Fees due or suspend or terminate Your use of the Service in
            respect of any or all of Your Organisations in the event that any
            invoices for those Usage Fees are not paid in full by the due date
            for payment.
          </p>
          <p>General obligations:</p>
          <p>
            You must only use the Service for Your own lawful business purposes,
            in accordance with these Terms and any notice sent by Sendatradie or
            condition posted on the Website, and applicable laws. You may use
            the Service on behalf of others or in order to provide services to
            others but if You do so You must ensure that You are authorised to
            do so and that all persons for whom or to whom services are provided
            comply with and accept all terms of this Agreement that apply to
            You.
          </p>
          <p>Access conditions:</p>
          <p>
            You must ensure that all usernames and passwords required to access
            the Service are kept secure and confidential. You must immediately
            notify Sendatradie of any unauthorised use of Your passwords or any
            other breach of security. Sendatradie may reset Your password and
            You must take any other actions that Sendatradie reasonably deems
            necessary to maintain or enhance the security of Sendatradie's
            computing systems and networks and Your access to the Services.
          </p>
          <p>
            As a condition of these Terms, when accessing and using the
            Services, You must:
          </p>
          <p>
            • ensure all Your contact information provided in connection with
            the administration and use of the Service is always current, true
            and correct;
          </p>
          <p>
            • not attempt to undermine the security or integrity of
            Sendatradie's computing systems or networks or, where the Services
            are hosted by a third party, that third party's computing systems
            and networks;
          </p>
          <p>
            • not use, or misuse, the Services in any way which may impair the
            functionality of the Services or Website, or other systems used to
            deliver the Services or impair the ability of any other user to use
            the Services or Website;
          </p>
          <p>
            • not attempt to gain unauthorised access to any materials other
            than those to which You have been given express permission to access
            or to the computer system on which the Services are hosted;
          </p>
          <p>
            • not transmit, or input into the Website, any: files that may
            damage any other person's computing devices or software, content
            that may be offensive, or material or Data in violation of any law
            (including Data or other material protected by copyright or trade
            secrets which You do not have the right to use); and
          </p>
          <p>
            • not attempt to modify, copy, adapt, reproduce, disassemble,
            decompile or reverse engineer any computer programs used to deliver
            the Services or to operate the Website.
          </p>
          <p>Usage Limitations:</p>
          <p>
            Use of the Service may be subject to limitations, including but not
            limited to monthly transaction volumes and the number of calls You
            are permitted to make against Sendatradie’s application programming
            interface.
          </p>
          <p>
            Specifically, Sendatradie’s phone communication service does not
            support phone calls to:
          </p>
          <p>
            • Emergency services. You agree you will not attempt to use the
            Service to connect to government-sponsored emergency telephone
            numbers (such as 000 in Australia), used to dispatch professional
            emergency responders. Sendatradie or its service providers cannot be
            held responsible or liable for any claim, damage or loss arising
            from or relating to the inability to use the Service to contact
            emergency services; and
          </p>
          <p>
            • International, premium, surcharged or special phone numbers i.e.
            calls to phone numbers in another country, or special numbers which
            involve surcharges (such as 1300 and 1800 phone numbers in
            Australia).
          </p>
          <p>Communication Conditions:</p>
          <p>
            As a condition of these Terms, if You use any communication tools
            available through the Website and Services, You agree only to use
            such communication tools for lawful and legitimate purposes. You
            must not use any such communication tool in a manner that:
          </p>
          <p>(a) is illegal, fraudulent, harassing or abusive in nature; </p>
          <p>(b) is in violation of laws or regulations relating to:</p>
          <p>
            the transmission of data or recording of phone calls and other
            communications;{" "}
          </p>
          <p>spamming; or</p>
          <p>
            unsolicited advertising and marketing, or transmission of
            communications;{" "}
          </p>
          <p>
            (c) generates inquiries from a law enforcement, government, or
            regulatory agency or triggers such an agency to request the
            suspension of the Service to You.{" "}
          </p>
          <p>
            (d) may damage any other person's computing devices or software;{" "}
          </p>
          <p>
            (e) involves transmission of material in violation of any law
            (including material that is protected by copyright or trade secrets
            which You do not have the right to use).
          </p>
          <p>
            When You make and/or record any communication on the Website or
            through the Services, You represent that You are permitted to make
            and/or record such communications, and you will comply with all
            applicable laws and regulations prior to doing so and secure all
            required consents. You agree to indemnify Sendatradie and its
            service providers for any claim arising out of or related to your
            acts or omissions in connection to providing required notices and
            obtaining consent for making and/or recording phone calls, sending
            SMS and email, or other communications using the Services.
          </p>
          <p>
            Sendatradie is under no obligation to ensure that your use of
            communications through the Website and Services are legitimate or
            that they are related only to the use of the Services. You must
            exercise caution when using the Service’s communication tools.
            Sendatradie reserves the right to remove any communication at any
            time in its sole discretion.
          </p>
          <b>INDEMNITY</b>
          <p>
            You indemnify Sendatradie against: all claims, costs, damage and
            loss arising from Your breach of any of these Terms or any
            obligation You may have to Sendatradie, including (but not limited
            to) any costs relating to the recovery of any Usage Fees that are
            due but have not been paid by You.
          </p>
          <b>SPAM ACT</b>
          <p>
            Sendatradie has a strict anti-spam policy. Our SMS messaging
            services and email services must only be used in accordance with the
            Spam Act of 2003 and Spam Regulations administered by the Australian
            Communications and Media Authority (ACMA). Sendatradie does not
            allow sending unsolicited SMS or email messages. You must have
            consent to send SMS messages to each recipient before sending an SMS
            message to them, in accordance with the Spam Act. Any marketing
            messages must give the recipient the option to opt-out of further
            marketing messages.
          </p>
          <b>CONFIDENTIALITY AND PRIVACY</b>
          <p>Confidentiality: </p>
          <p>
            Unless the relevant party has the prior written consent of the other
            or unless required to do so by law:
          </p>
          <p>
            •Each party will preserve the confidentiality of all Confidential
            Information of the other obtained in connection with these Terms.
            Neither party will, without the prior written consent of the other,
            disclose or make any Confidential Information available to any
            person, or use the same for its own benefit, other than as
            contemplated by these Terms.
          </p>
          <p>
            •Each party's obligations under this clause will survive termination
            of these Terms.
          </p>
          <p>
            The provisions of this clause shall not apply to any information
            which:
          </p>
          <p>
            • is or becomes public knowledge other than by a breach of this
            clause;
          </p>
          <p>
            • is received from a third party who lawfully acquired it and who is
            under no obligation restricting its disclosure;
          </p>
          <p>
            • is in the possession of the receiving party without restriction in
            relation to disclosure before the date of receipt from the
            disclosing party; or
          </p>
          <p>
            • is independently developed without access to the Confidential
            Information.
          </p>
          <p>Privacy:</p>
          <p>
            Sendatradie maintains a privacy policy that sets out the parties’
            obligations in respect of personal information . You should read
            that <a href="/privacy-policy">policy</a> and You will be taken to
            have accepted that policy when You accept these Terms.
          </p>
          <p></p>
          <b>INTELLECTUAL PROPERTY</b>
          <p>General:</p>
          <p>
            Title to, and all Intellectual Property Rights in the Services, the
            Website and any documentation relating to the Services remain the
            property of Sendatradie (or its licensors).
          </p>
          <p></p>
          <p>Ownership of Data:</p>
          <p>
            Title to, and all Intellectual Property Rights in, the Data remain
            Your property. However, Your access to the Data is contingent on
            full payment of the Sendatradie Usage Fee when due. You grant
            Sendatradie a licence to use, copy, transmit, store, and back-up
            Your information and Data for the purposes of enabling You to access
            and use the Services and for any other purpose related to provision
            of services to You.
          </p>
          <p>Backup of Data:</p>
          <p>
            Sendatradie adheres to its best practice policies and procedures to
            prevent data loss, including a daily system data back-up regime, but
            does not make any guarantees that there will be no loss of Data.
            Sendatradie expressly excludes liability for any loss of Data no
            matter how caused.
          </p>
          <p>Third-party applications and your Data: </p>
          <p>
            If You enable third-party applications for use in conjunction with
            the Services, You acknowledge that Sendatradie may allow the
            providers of those third-party applications to access Your Data as
            required for the interoperation of such third-party applications
            with the Services. Sendatradie shall not be responsible for any
            disclosure, modification or deletion of Your Data resulting from any
            such access by third-party application providers.
          </p>
          <b>WARRANTIES AND ACKNOWLEDGEMENTS</b>
          <p>Authority:</p>
          <p>
            You warrant that where You have registered to use the Service on
            behalf of another person, You have the authority to agree to these
            Terms on behalf of that person and agree that by registering to use
            the Service You bind the person on whose behalf You act to the
            performance of any and all obligations that You become subject to by
            virtue of these Terms, without limiting Your own personal
            obligations under these Terms.
          </p>
          <p>Acknowledgement:</p>
          <p>You acknowledge that:</p>
          <p>
            • You are authorised to use the Services and to access the
            information and Data that You input into the Services, including any
            information or Data input into the Services by any person you have
            authorised to use the Service. You are also authorised to access the
            processed information and Data that is made available to You through
            Your use of the Services (whether that information and Data is Your
            own or that of anyone else).
          </p>
          <p>
            • Sendatradie has no responsibility to any person other than You and
            nothing in this Agreement confers, or purports to confer, a benefit
            on any person other than You.
          </p>
          <p>
            • If You use the Services or access the Services on behalf of or for
            the benefit of anyone other than yourself (whether a body corporate
            or otherwise) you agree that:
          </p>
          <p></p>
          <p></p>
          <p>
            - You are responsible for ensuring that You have the right to do so;
          </p>
          <p>
            - You are responsible for authorising any person who is given access
            to information or Data, and you agree that Sendatradie has no
            obligation to provide any person access to such information or Data
            without Your authorisation and may refer any requests for
            information to You to address; and
          </p>
          <p>
            - You will indemnify Sendatradie against any claims or loss relating
            to Sendatradie's refusal to provide any person access to Your
            information or Data in accordance with these Terms, or Sendatradie
            making available information or Data to any person with Your
            authorisation.
          </p>
          <p>
            • The provision of, access to, and use of, the Services is on an "as
            is " basis and at Your own risk.
          </p>
          <p>
            • Sendatradie does not warrant that the use of the Service will be
            uninterrupted or error free.
          </p>
          <p>
            • Among other things, the operation and availability of the systems
            used for accessing the Service, including public telephone services,
            computer networks and the Internet, can be unpredictable and may
            from time to time interfere with or prevent access to the Services.
            Sendatradie is not in any way responsible for any such interference
            or prevention of Your access or use of the Services.
          </p>
          <p>
            • It is Your sole responsibility to determine that the Services meet
            the needs of Your business and are suitable for the purposes for
            which they are used.
          </p>
          <p>No warranties:</p>
          <p>
            Sendatradie gives no warranty about the Services. Without limiting
            the foregoing, Sendatradie does not warrant that the Services will
            meet Your requirements or that it will be suitable for any
            particular purpose. To avoid doubt, all implied conditions or
            warranties are excluded in so far as is permitted by law, including
            (without limitation) warranties of merchantability, fitness for
            purpose, title and non-infringement.
          </p>
          <p>Consumer guarantees: </p>
          <p>
            You warrant and represent that You are acquiring the right to access
            and use the Services for the purposes of a business and that, to the
            maximum extent permitted by law, any statutory consumer guarantees
            or legislation intended to protect non-business consumers in any
            jurisdiction does not apply to the supply of the Services, the
            Website or these Terms.
          </p>
          <b>LIMITATION OF LIABILITY</b>
          <p>
            To the maximum extent permitted by law, Sendatradie excludes all
            liability and responsibility to You (or any other person) in
            contract, tort (including negligence), or otherwise, for any loss
            (including loss of information, Data, profits and savings) or damage
            resulting, directly or indirectly, from any use of, or reliance on,
            the Service.
          </p>
          <p>
            If You suffer loss or damage as a result of Sendatradie's negligence
            or failure to comply with these Terms, any claim by You against
            Sendatradie arising from Sendatradie's negligence or failure will be
            limited in respect of any one incident, or series of connected
            incidents, to the Usage Fees paid by You in the previous 12 months.
          </p>
          <p>
            If You are not satisfied with the Service, Your sole and exclusive
            remedy is to terminate these Terms in accordance with the
            Termination clause.
          </p>
          <p></p>
          <p></p>
          <b>TERMINATION</b>
          <p>Trial policy:</p>
          <p>
            You can evaluate the Services under a Free Trial, in accordance with
            this Agreement and the limitations of the trial, with no obligation
            to continue to use the Services.
          </p>
          <p>No-fault termination: </p>
          <p>
            These Terms will continue while You continue to use the Service,
            provided You continue to pay the prescribed Usage Fee when due,
            unless either party terminates these Terms by giving notice to the
            other party at least 30 days before the end of the relevant payment
            period. If You terminate these Terms You shall be liable to pay all
            relevant Usage Fees on a pro-rata basis for each day of the then
            current period up to and including the day of termination of these
            Terms.
          </p>
          <p>Breach:</p>
          <p>If:</p>
          <p>
            • You breach any of these Terms (including, without limitation, by
            non-payment of any Usage Fees) and do not remedy the breach within 7
            days after receiving notice of the breach if the breach is capable
            of being remedied; or
          </p>
          <p>
            • You breach any of these Terms and the breach is not capable of
            being remedied (which includes (without limitation) any payment of
            Usage Fees that are more than 30 days overdue); or
          </p>
          <p>
            • You or Your business become insolvent or Your business goes into
            liquidation or has a receiver or manager appointed of any of its
            assets or if You become insolvent, or make any arrangement with Your
            creditors, or become subject to any similar insolvency event in any
            jurisdiction; then
          </p>
          <p>
            Sendatradie may take any or all of the following actions, at its
            sole discretion:
          </p>
          <p>
            • Terminate this Agreement and Your use of the Services and the
            Website;
          </p>
          <p>
            • Suspend for any definite or indefinite period of time, Your use of
            the Services and the Website;
          </p>
          <p>• Suspend or terminate access to all or any Data.</p>
          <p>
            For the avoidance of doubt, if payment of any invoice for Usage Fees
            due in relation to the Service is not made in full by the relevant
            due date, Sendatradie may: suspend or terminate Your use of the
            Service, the authority for all or any of Your Organisations to use
            the Service, or Your rights of access to all or any Data.
          </p>
          <p>Accrued Rights:</p>
          <p>
            Termination of these Terms is without prejudice to any rights and
            obligations of the parties accrued up to and including the date of
            termination. On termination of this Agreement You will:
          </p>
          <p>
            • remain liable for any accrued charges and amounts which become due
            for payment before or after termination; and
          </p>
          <p></p>
          <p></p>
          <p>• immediately cease to use the Services.</p>
          <b>THIRD PARTY PRODUCTS AND SERVICES</b>
          <p>
            Sendatradie is designed to be used in conjunction with Google Maps
            and other services. Accordingly, your use of Sendatradie is also
            bound by the Google Maps/Google Earth Additional Terms of Service
            (including the Google Privacy Policy).
          </p>
          <b>HELP </b>
          <p>Technical Problems:</p>
          <p>
            In the case of technical problems You must make all reasonable
            efforts to investigate and diagnose problems before contacting
            Sendatradie. If You still need technical help, please check the
            support provided online by Sendatradie on the Website or failing
            that email us at support@Sendatradie.com.
          </p>
          <p>Service availability:</p>
          <p>
            Whilst Sendatradie intends that the Services should be available 24
            hours a day, seven days a week, it is possible that on occasions the
            Services or Website may be unavailable to permit maintenance or
            other development activity to take place.
          </p>
          <p>
            If for any reason Sendatradie has to interrupt the Services for
            longer periods than Sendatradie would normally expect, Sendatradie
            will use reasonable endeavours to publish in advance details of such
            activity on the Website.
          </p>
          <b>GENERAL</b>
          <p>Entire agreement: </p>
          <p>
            These Terms, together with the Sendatradie Privacy Policy and the
            terms of any other notices or instructions given to You under these
            Terms of Use, supersede and extinguish all prior agreements,
            representations (whether oral or written), and understandings and
            constitute the entire agreement between You and Sendatradie relating
            to the Services and the other matters dealt with in these Terms.
          </p>
          <p>Waiver: </p>
          <p>
            If either party waives any breach of these Terms, this will not
            constitute a waiver of any other breach. No waiver will be effective
            unless made in writing.
          </p>
          <p>Delays: </p>
          <p>
            Neither party will be liable for any delay or failure in performance
            of its obligations under these Terms if the delay or failure is due
            to any cause outside its reasonable control. This clause does not
            apply to any obligation to pay money.
          </p>
          <p>No Assignment:</p>
          <p>
            You may not assign or transfer any rights to any other person
            without Sendatradie's prior written consent.
          </p>
          <p>Governing law and jurisdiction</p>
          <p>
            Australian law governs this Agreement and You submit to the
            exclusive jurisdiction of the courts of Australia, and specifically
            Victoria in the event of commencement of litigation, for all
            disputes arising out of or in connection with this Agreement.
          </p>
          <p>Severability: </p>
          <p>
            If any part or provision of these Terms is invalid, unenforceable or
            in conflict with the law, that part or provision is replaced with a
            provision which, as far as possible, accomplishes the original
            purpose of that part or provision. The remainder of this Agreement
            will be binding on the parties.
          </p>
          <p>Notices:</p>
          <p>
            Any notice given under these Terms by either party to the other must
            be in writing by email and will be deemed to have been given on
            transmission. Notices to Sendatradie must be sent to
            support@Sendatradie.com or to any other email address notified by
            email to You by Sendatradie. Notices to You will be sent to the
            email address which You provided when setting up Your access to the
            Service and/or the email address listed as the Account Owner.
          </p>
          <p>Rights of Third Parties:</p>
          <p>
            A person who is not a party to these Terms has no right to benefit
            under or to enforce any term of these Terms.
          </p>
          <p></p>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
}
