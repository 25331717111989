import React, { useEffect, useState } from "react";
import { CustomersLoadingDialog } from "./../Jobs/pages/Jobs/customers-loading-dialog/CustomersLoadingDialog.js";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useSelector, useDispatch } from "react-redux";
import CustomBootstrapTable from "./../../../_metronic/_helpers/CustomBootstrapTable.js";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../_metronic/_helpers";
import CustomAxios from "./../../../utils/axios.js";
import moment from "moment";

export default function LoginList() {
  const columns = [
    {
      text: "EMAIL",
      dataField: "email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    { text: "IP", dataField: "ip", sortCaret: sortCaret, headerSortingClasses },
    {
      text: "TIME",
      dataField: "date_time",
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "STATUS",
      dataField: "status",
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "ACTION",
      dataField: "action",
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];
  const [ids, setIds] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [rawData, setRawData] = React.useState([]);
  const [search, setSearch] = useState("");
  const userData = useSelector((state) => state.auth.user);
  const [showEditModal, setShowEditModal] = React.useState(false);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    //hideconsole.log(rawData);
    var newData = [...rawData];
    search &&
      (newData = newData.filter(
        (item) =>
          item?.email?.toUpperCase()?.includes(search.trim().toUpperCase()) ||
          item?.ip?.toString()?.includes(search.trim()) ||
          item?.date_time?.toString()?.includes(search.trim()) ||
          item?.action
            ?.toUpperCase()
            ?.toString()
            ?.includes(search.trim().toUpperCase()) ||
          item?.status?.toUpperCase()?.includes(search.trim().toUpperCase())
      ));
    //hideconsole.log(newData);
    setData(newData);
  }, [search, rawData]);

  const getData = () => {
    CustomAxios.call("post", `client/getLoginList`, null, []).then((res) => {
      res.data.data?.forEach(
        (row) =>
          (row.date_time = moment
            .utc(row.date_time)
            .tz(userData.time_zone)
            .format("yyyy-MM-DD HH:mm:ss"))
      );
      setRawData(res.data.data);
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <br />
          <Card>
            <CardHeader title="Login List"></CardHeader>
            <CardBody>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <small className="form-text text-muted">
                    <b>Search</b>
                  </small>
                  <input
                    type="text"
                    className="form-control"
                    name="search"
                    placeholder="search here"
                    value={search}
                    onChange={(event) => {
                      setSearch(event.target.value);
                    }}
                  />
                </div>
              </div>

              <CustomersLoadingDialog />
              <>
                <CustomBootstrapTable
                  data={data}
                  columns={columns}
                  //   isSelectable
                  //   ids={ids}
                  //   setIds={setIds}
                />
              </>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
