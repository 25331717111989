import React from "react";

export function LoadingData({ loading }) {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        {loading ? (
          <>
            {" "}
            <span
              className="spinner-border spinner-border-lg ml-1"
              role="status"
              aria-hidden="true"
            ></span>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
