import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import { Switch } from "@material-ui/core";
import { Button, ButtonGroup, Grid } from "@material-ui/core";
import { Input } from "@mui/material";
import { toast } from "react-toastify";
export function ReccommendedWorker() {
  const [toggle, setToggle] = useState(false);
  const [radius, setRadius] = useState();
  const [editing, setEditing] = useState(false);
  useEffect(() => {
    axios
      .get("https://www.api.sendatradie.com/company/recommendWorkerSettings")
      .then((res) => {
        //hideconsole.log(res.data.result);
        setToggle(res.data.result[0].toggle);
        //hideconsole.log(res.data.result[0].toggle);
        setRadius(res.data.result[0].radius);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = async () => {
    axios
      .post("https://www.api.sendatradie.com/company/editReccommendWorkerSettings", {
        toggle: toggle,
        radius: radius,
      })
      .then((res) => {
        toast.success("Changes save successfully");
      })
      .catch((err) => toast.error("Failed to save changes"));
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <br />
          <Card>
            <CardHeader title="Worker Recommendation Settings"></CardHeader>
            <CardBody>
              <div className="form-group row">
                <div className="col-lg-4">Enable Worker Recommendation</div>
                <div className="col-lg-6">
                  <Switch
                    checked={toggle}
                    onChange={async (event) => {
                      setToggle(event.target.checked);
                      await handleChange();
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-4">Recommendation Radius (km)</div>
                <div className="col-lg-1">
                  <input
                    type="number"
                    className="form-control"
                    step="1"
                    value={radius}
                    onChange={async ({ target: { value } }) => {
                      const numberValue = parseInt(value, 10);
                      value === "" ? setRadius(0) : setRadius(numberValue);
                      await handleChange();
                    }}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
