import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import axios from "axios";
import CustomBootstrapTable from "./../../../_metronic/_helpers/CustomBootstrapTable.js";
import { CustomFieldsSettingsModal } from "./CustomFieldsSettingsModal.js";
import { forEach } from "lodash";
export function CustomFieldsSettings() {
  const [ids, setIds] = useState([]);
  const [FieldsArr, setFieldsArr] = useState([]);
  const [displayedFields, setDisplayedFields] = useState([]);
  const [show, setShow] = useState(false);
  const [row, setRow] = useState();
  const [res1, setRes1] = useState();
  const [res2, setRes2] = useState();
  const [fieldLabel, setFieldLabel] = useState("");
  const [tab, setTab] = useState(null);
  const [trigger, setTrigger] = useState(0);

  const tabOptionJob = [
    { value: 0, label: "Job" },
    { value: 1, label: "Customer" },
    { value: 2, label: "Files" },
    { value: 3, label: "Notes" },
  ];
  const tabOptionClient = [{ value: 0, label: "Client Info" }];

  const columns = [
    { dataField: "field_label", text: "Field Name" },
    {
      dataField: "page_id",
      text: "Location",
      formatter: (cellContent, row) => {
        let str = cellContent ? cellContent.substring(1) : "";
        str = str.length
          ? str[0].toUpperCase() + str.slice(1).toLowerCase()
          : str;

        return str;
      },
    },
    {
      dataField: "tab_id",
      text: "Tab",
      formatter: (cellContent, row) => {
        if (row.page_id === "/jobs") {
          let filtered = [
            { value: 0, label: "Job" },
            { value: 1, label: "Customer" },
            { value: 2, label: "Files" },
            { value: 3, label: "Notes" },
          ].filter((item) => {
            return item.value == cellContent;
          });

          if (filtered.length > 0) {
            let label = filtered[0].label;
            return label;
          } else {
            return "-1";
          }
        } else if (row.page_id === "/clients") {
          let filtered = [{ value: 0, label: "Client Info" }].filter((item) => {
            return item.value == cellContent;
          });

          if (filtered.length > 0) {
            let label = filtered[0].label;
            return label;
          } else {
            return "-1";
          }
        } else {
          return cellContent;
        }
      },
    },
  ];

  const getData = () => {
    let request1 = axios.post("https://www.api.sendatradie.com/company/getCustomFields", {
      page_id: "/jobs",
    });
    let request2 = axios.post("https://www.api.sendatradie.com/company/getCustomFields", {
      page_id: "/clients",
    });
    Promise.all([request1, request2])
      .then((res) => {
        const temp1 = JSON.parse(res[0].data.data[0]?.fields);

        const temp2 = JSON.parse(res[1].data.data[0]?.fields);

        setRes1(temp1);
        setRes2(temp2);
        let newFieldArr = [];
        if (res[0].data.data[0]?.fields) {
          newFieldArr = [...temp1]; // calculate your newFieldArr
        }

        if (res[1].data.data[0]?.fields) {
          newFieldArr = [...newFieldArr, ...temp2]; // update value of newFieldArr
        }

        const FieldsArrWithID = newFieldArr.map((item, id) => ({
          ...item,
          id,
        })); // map over new copy of array
        setFieldsArr(FieldsArrWithID);
        setDisplayedFields(
          FieldsArrWithID.filter((item) => {
            return item.active;
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, [trigger]);

  useEffect(() => {
    getData();
  }, []);

  const handleSave = (reqArr) => {
    let arr1 = [];
    let arr2 = [];
    for (let counter = 0; counter < reqArr.length; counter++) {
      delete reqArr[counter].id;
      if (counter < res1.length) arr1.push(reqArr[counter]);
      else arr2.push(reqArr[counter]);
    }
    //hideconsole.log(arr1, arr2);
    const arr1String = JSON.stringify(arr1);
    const arr2String = JSON.stringify(arr2);

    //hideconsole.log("on delete", arr1, arr2);

    let req1 = axios.post("https://www.api.sendatradie.com/company/editedCustomFields", {
      fields: arr1String,
      page_id: "/jobs",
    });
    let req2 = axios.post("https://www.api.sendatradie.com/company/editedCustomFields", {
      fields: arr2String,
      page_id: "/clients",
    });

    Promise.all([req1, req2])
      .then((res) => {
        //hideconsole.log("req1 response", res[0]);
        //hideconsole.log("req2 response", res[1]);
        setTrigger(!trigger);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    let FieldsArrCopy = JSON.parse(JSON.stringify(FieldsArr));

    FieldsArrCopy.forEach((item, index) => {
      if (ids.includes(item.id)) {
        item.active = 0;
      }
    });

    handleSave(FieldsArrCopy);
  };

  const handleSubmitModal = () => {
    let FieldsArrCopy = JSON.parse(JSON.stringify(FieldsArr));
    for (let counter = 0; counter < FieldsArrCopy.length; counter++) {
      let obj = FieldsArrCopy[counter];
      if (obj.id == row.id) {
        obj.field_label = fieldLabel;
        obj.tab_id = tab.value;
      }
    }
    //hideconsole.log(FieldsArrCopy);
    handleSave(FieldsArrCopy);
    handleHide();
  };

  const handleHide = () => {
    setTab(null);
    setFieldLabel("");
    setRow();
    setShow(false);
  };

  const handleEditModal = () => {
    let myVar = FieldsArr.filter((item) => {
      return item.id == ids[0];
    })[0];
    setRow(myVar);
    setFieldLabel(myVar.field_label);
    if (myVar.page_id == "/jobs") {
      setTab(
        tabOptionJob.filter((item) => {
          return item.value == myVar.tab_id;
        })[0]
      );
    }
    if (myVar.page_id == "/clients") {
      setTab(
        tabOptionClient.filter((item) => {
          return item.value == myVar.tab_id;
        })[0]
      );
    }
    setShow(true);
  };

  return (
    <>
      <CustomFieldsSettingsModal
        show={show}
        setShow={setShow}
        handleHide={handleHide}
        row={row}
        fieldLabel={fieldLabel}
        setFieldLabel={setFieldLabel}
        tab={tab}
        setTab={setTab}
        handleSubmitModal={handleSubmitModal}
      />
      <div className="row">
        <div className="col-md-12">
          <br />
          <Card>
            <CardHeader title="Custom Fields Settings"></CardHeader>

            <CardBody>
              <div className="mt-0">
                <div className="row align-items-center  tour_buttons form-group-actions margin-top-20 margin-bottom-20">
                  <div className="col-xl-12 ">
                    <button
                      type="button"
                      className="btn btn-light-primary font-weight-bolder font-size-sm mr-3"
                      style={{ width: 208 }}
                      disabled={ids?.length != 1}
                      onClick={handleEditModal}
                    >
                      <i className="fa fa-edit"></i> Edit
                    </button>
                    <button
                      type="button"
                      className="btn btn-light-primary font-weight-bolder font-size-sm mr-3"
                      style={{ width: 208 }}
                      disabled={ids?.length == 0}
                      onClick={handleDelete}
                    >
                      <i className="fa fa-trash"></i> Delete
                    </button>
                  </div>
                </div>
              </div>

              <CustomBootstrapTable
                data={displayedFields}
                ids={ids}
                setIds={setIds}
                columns={columns}
                isSelectable
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
