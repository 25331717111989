import React, { useState, useEffect } from "react";
//import styled from 'styled-components'
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useRowSelect,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table } from "react-bootstrap";
import { Button, ButtonGroup, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/";
import { EditIcon } from "@material-ui/icons/EditOutlined";
import { Modal } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
          borderBottom: "1px solid",
        }}
      />
    </span>
  );
}

const IndeterminateRadio = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);
export function TableSendatradie({ columns, data }) {
  const classes = useStyles();
  const [showEdit, setShowEdit] = useState(false);

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = () => setShowEdit(true);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, selectedRowIds },
    state,
    rows,
    prepareRow,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },

    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateRadio {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateRadio {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const first =
    visibleColumns.length % 2 == 0
      ? visibleColumns.length / 2
      : Math.ceil(visibleColumns.length / 2);
  const second =
    visibleColumns.length % 2 == 0
      ? visibleColumns.length / 2
      : Math.ceil(visibleColumns.length / 2) - 1;
  return (
    <>
      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header>
          <Modal.Title>Edit Job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>
            <code>
              {JSON.stringify({
                "selectedFlatRows[].original": selectedFlatRows.map(
                  (d) => d.original
                ),
              })}
            </code>
          </pre>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outlined"
            onClick={handleCloseEdit}
            style={{ marginRight: "10px" }}
          >
            Close
          </Button>
          <Button variant="contained" onClick={handleCloseEdit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Table responsive striped bordered hover size="sm" {...getTableProps()}>
                <thead>
                    <tr>
                        <th colSpan={visibleColumns.length - 1} style={{ border: "0px" }}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                disabled={Object.keys(selectedRowIds).length == 0}
                                className={classes.button}
                                onClick={handleShowEdit}
                            >
                                Edit Job
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                disabled={Object.keys(selectedRowIds).length == 0}
                                className={classes.button}
                            >
                                Manage Recipt
                            </Button>
                        </th>
                        <th
                            colSpan="1"
                            style={{
                                textAlign: "left",
                                float: "right",
                                border: "0px",
                            }}
                        >
                            <GlobalFilter
                                preGlobalFilteredRows={preGlobalFilteredRows}
                                globalFilter={state.globalFilter}
                                setGlobalFilter={setGlobalFilter}
                            />
                        </th>
                    </tr>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    <span>{column.isSorted ? (column.isSortedDesc ? " ⬇" : " ⬆") : ""}</span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                })}
                            </tr>
                        )
                    })}
                    <tr>
                        <td colSpan={first}>
                            <span>
                                Page{" "}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>{" "}
                            </span>
                            <span>
                                | Go to page:{" "}
                                <input
                                    type="number"
                                    defaultValue={pageIndex + 1}
                                    min={0}
                                    max={pageOptions.length}
                                    onChange={(e) => {
                                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                                        gotoPage(page)
                                    }}
                                    style={{ width: "100px" }}
                                />
                            </span>{" "}
                            <select
                                style={{ minHeight: "30px" }}
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value))
                                }}
                            >
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </td>
                        <td colSpan={second}>
                            <ButtonGroup
                                size="small"
                                aria-label="Small outlined button group"
                                style={{ float: "right" }}
                            >
                                <Button variant="contained" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                    {"<<"}
                                </Button>{" "}
                                <Button variant="contained" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                    {"<"}
                                </Button>{" "}
                                <Button variant="contained" onClick={() => nextPage()} disabled={!canNextPage}>
                                    {">"}
                                </Button>{" "}
                                <Button
                                    variant="contained"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    {">>"}
                                </Button>{" "}
                            </ButtonGroup>
                        </td>
                    </tr>
                </tbody>
            </Table> */}
    </>
  );
}
