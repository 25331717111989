import React from "react";
import unpaid from "../../../Invoice/assets/svg/unpaid.svg";
import paid from "../../../Invoice/assets/svg/paid.svg";

function QuotesModalPart(QuotesData) {
  const Total = (arr) => {
    const total = arr.arr.items.reduce(
      (prev, cur) => prev + parseInt(cur.quantity) * parseInt(cur.amount),
      0
    );
    //hideconsole.log(total);

    // records.filter(({gender}) => gender === 'BOYS')
    // .reduce((sum, record) => sum + record.value)
    //QuotesData[0].credit_fee
    const subTotal1 = arr.arr.items
      .filter((items) => items.tax === "yes")
      .reduce(
        (prev, cur) =>
          prev +
          parseInt(cur.quantity) * parseInt(cur.amount) +
          (cur.quantity * cur.amount * QuotesData.QuotesData[0].tax) / 100,
        0
      );
    const subTotal2 = arr.arr.items
      .filter((items) => items.tax === "no")
      .reduce(
        (prev, cur) => prev + parseInt(cur.quantity) * parseInt(cur.amount),
        0
      );
    const subTotal = subTotal1 + subTotal2;
    const taxPercent = arr.arr?.credit_fee;
    //hideconsole.log(taxPercent);

    const totalPercentage = (taxPercent * total) / 100;
    const itemtaxwithoutPercnetage = (taxPercent * subTotal) / 100;
    const finalTaxToshow = total + (totalPercentage - itemtaxwithoutPercnetage);
    // //hideconsole.log(
    //   total,
    //   totalPercentage,
    //   finalTaxToshow,
    //   itemtaxwithoutPercnetage
    // );

    // for getting time

    // const finalTaxPercentage=taxPercent-itemtaxwithoutPercnetage

    return (
      <>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ padding: "10px 10px" }}>Sub Total</td>
          <td style={{ padding: "10px 10px" }}>{total ? total : 0}</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ padding: "10px 10px" }}>Tax </td>
          <td style={{ padding: "10px 10px" }}>
            {subTotal && total ? subTotal - total : 0}{" "}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ padding: "10px 10px" }}>Credit Card Fee </td>
          <td style={{ padding: "10px 10px" }}>
            {taxPercent && subTotal ? (taxPercent * total) / 100 : 0}
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ padding: "10px 10px" }}>Total</td>
          <td style={{ padding: "10px 10px" }}>
            {subTotal && total
              ? subTotal + (taxPercent * total) / 100
                ? subTotal + (taxPercent * total) / 100
                : 0
              : 0}
          </td>
        </tr>
      </>
    );
  };
  return (
    <div id="quotes_preview">
      {QuotesData ? (
        <table style={{ margin: "17px", width: "95%" }}>
          {QuotesData
            ? QuotesData.QuotesData.map((data, index) => {
                return (
                  <tbody key={index}>
                    <th>{data?.company_name}</th>
                    <tr>
                      <th style={{ textAlign: "left" }}>Quote to:</th>
                      <th style={{ textAlign: "right" }} colSpan="4">
                        QUOTE
                      </th>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "30px" }}>
                        <ul
                          style={{
                            listStyle: "none",
                            paddingLeft: "10px",
                          }}
                        >
                          <li>{data.name}</li>
                          <li>{data.address}</li>
                          <li>{data.email}</li>
                        </ul>
                      </td>
                      {/* <td colSpan="3" style={{ textAlign: "right" }}>
                        <img
                          style={{ height: "80px" }}
                          src={data.status == "Quote" ? unpaid : paid}
                          alt="unpaid Logo"
                        />
                      </td> */}
                    </tr>

                    <tr>
                      <th
                        style={{
                          borderTop: "3px solid #777",
                          paddingTop: "10px",
                          textAlign: "left",
                        }}
                        scope="col"
                      >
                        Description
                      </th>
                      <th
                        style={{
                          borderTop: "3px solid #777",
                          paddingTop: "10px",
                          textAlign: "left",
                        }}
                        scope="col"
                      >
                        Qty
                      </th>
                      <th
                        style={{
                          borderTop: "3px solid #777",
                          paddingTop: "10px",
                          textAlign: "left",
                        }}
                        scope="col"
                      >
                        Price
                      </th>
                      <th
                        style={{
                          borderTop: "3px solid #777",
                          paddingTop: "10px",
                          textAlign: "left",
                        }}
                        scope="col"
                      >
                        GST
                      </th>
                      <th
                        style={{
                          borderTop: "3px solid #777",
                          paddingTop: "10px",
                          textAlign: "left",
                        }}
                        scope="col"
                      >
                        Amount
                      </th>
                    </tr>
                    {data && data.items && data.items.length > 0
                      ? data.items.map((value, dataIndex) => {
                          return (
                            <tr key={dataIndex}>
                              <td
                                style={{
                                  padding: "10px 10px",
                                  borderBottom: "1px solid #ddd",
                                }}
                              >
                                {value.description}
                              </td>
                              <td
                                style={{
                                  padding: "10px 10px",
                                  borderBottom: "1px solid #ddd",
                                }}
                              >
                                {value.quantity}
                              </td>
                              <td
                                style={{
                                  padding: "10px 10px",
                                  borderBottom: "1px solid #ddd",
                                }}
                              >
                                {value.amount}
                              </td>
                              <td
                                style={{
                                  padding: "10px 10px",
                                  borderBottom: "1px solid #ddd",
                                }}
                              >
                                {value.tax}
                              </td>
                              <td
                                style={{
                                  padding: "10px 10px",
                                  borderBottom: "1px solid #ddd",
                                }}
                              >
                                {value.quantity * value.amount
                                  ? value.tax === "yes"
                                    ? value.quantity * value.amount +
                                      (value.quantity *
                                        value.amount *
                                        QuotesData.QuotesData[0].tax) /
                                        100
                                    : value.quantity * value.amount
                                  : 0}
                              </td>
                            </tr>
                          );
                        })
                      : null}

                    {QuotesData ? (
                      <>
                        <Total arr={QuotesData.QuotesData[0]} />
                      </>
                    ) : null}
                    <th
                      style={{
                        paddingTop: "10px",
                        textAlign: "left",
                      }}
                      scope="col"
                    >
                      Terms & Condition
                    </th>
                    <tr>
                      <td colSpan="4" style={{ padding: "10px 10px" }}>
                        {data.t_c}
                      </td>
                    </tr>
                  </tbody>
                );
              })
            : null}
        </table>
      ) : null}
    </div>
  );
}
export default QuotesModalPart;
