import React, { useState, useEffect } from "react";
import * as requestFromServer from "./../Jobs/_redux/customers/customersCrud.js";
import { Template } from "./Template";
import { toast } from "react-toastify";

export function ReviewSms(props) {
  const [SMSTemplate, setSMSTemplate] = useState({
    Message: "",
  });

  const [countVAl, setCountVAL] = useState({
    messageLength: 160,
    currentMsglength: 0,
    remainingLength: 0,
    MessageCount: 0,
  });

  const [buttonValue, setbuttonValue] = useState([
    "{Name}",
    "{Company}",
    "{Google Link}",
    "{Facebook Link}",
  ]);

  const [countForRemove, setCountForRemove] = useState(0);

  const onSaveForSMSTemplate = () => {
    const data = SMSTemplate;
    requestFromServer
      .PostSMSReviewTemplate(data)
      .then((response) => {
        //hideconsole.log(response.data.result, "response.SMSTemplatedata.result.");
        toast.success("SMS Template successfully saved");
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
          toast.error("Failed");
        } else {
          toast.error("Failed");
          //   toasterMessage("error", error.response.data.message);
        }
      });
  };

  useEffect(() => {
    getDataForSmsReview();
  }, []);

  const getDataForSmsReview = () => {
    const data = SMSTemplate;
    requestFromServer
      .getSMSReviewTemplate()
      .then((response) => {
        //hideconsole.log(response.data.result, "response.SMSTemplatedata.result.");
        if (response.data.result.length) {
          setSMSTemplate({ Message: response.data.result[0].template });

          var msgCountAsNum = 160;
          let lengthOfevent = response.data.result[0].template.length;
          const split = response.data.result[0].template.length
            ? response.data.result[0].template.split(" ")
            : "";
          const CountStep = split.filter((count) => {
            return (
              count === `{Name}` ||
              count === `{Company}` ||
              count === `{Link}` ||
              count === `{Customer_Name}` ||
              count === `{Coustomer_Phone}` ||
              count === `{Coustomer_Address}` ||
              count === `{Additional_Information}` ||
              count === `{Google_Map}` ||
              count === `{appointment_Time}`
            );
          });

          if (CountStep.length > 1) {
            setCountForRemove(
              CountStep.toString().length - CountStep.length + 1
            );

            const calculateval =
              CountStep.toString().length -
              CountStep.length +
              1 -
              CountStep.length;
            const currentMsglength = lengthOfevent - calculateval;
            const messageLengthRound = Math.ceil(
              currentMsglength / msgCountAsNum
            );
            const messageLength = msgCountAsNum * messageLengthRound;
            setCountVAL({
              messageLength: messageLength,
              currentMsglength: currentMsglength,
              remainingLength: messageLength - currentMsglength,
              MessageCount: messageLengthRound,
            });
          } else if (CountStep.length === 1) {
            setCountForRemove(CountStep.toString().length);
            const calculateval =
              lengthOfevent - CountStep.toString().length + 1;
            const currentMsglength = lengthOfevent - calculateval;
            const messageLengthRound = Math.ceil(
              currentMsglength / msgCountAsNum
            );
            const messageLength = msgCountAsNum * messageLengthRound;
            setCountVAL({
              messageLength: messageLength,
              currentMsglength: currentMsglength,
              remainingLength: messageLength - currentMsglength,
              MessageCount: messageLengthRound,
            });
          } else {
            setCountForRemove(0);
            const currentMsglength = lengthOfevent;
            const messageLengthRound = Math.ceil(
              currentMsglength / msgCountAsNum
            );
            const messageLength = msgCountAsNum * messageLengthRound;
            setCountVAL({
              messageLength: messageLength,
              currentMsglength: currentMsglength,
              remainingLength: messageLength - currentMsglength,
              MessageCount: messageLengthRound,
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <br />
          <Template
            count={countForRemove}
            setCount={setCountForRemove}
            buttonValue={buttonValue}
            setMessage={setSMSTemplate}
            messages={SMSTemplate}
            Title="SMS Review Template"
            type="showPaper"
            numberCount={countVAl}
            setnumberCount={setCountVAL}
            onSaveData={onSaveForSMSTemplate}
          />
        </div>
      </div>
    </>
  );
}
