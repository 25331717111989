/**
 * Base component for Insights section
 */


//Imports
import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../_metronic/_partials/controls";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
//Map component
import { MapComponent } from "./MapComponent.js";
//Overlay for map when no jobs found in range
import NoJobsOverlay from "./NoJobsOverlay.js";
//Datetime helpers
import { initDateObject } from "./../../../utils/dateHelpers.js";


/**
 * Creates and renders Insights section
 * @returns Insights JSX to render
 */
export function InsightIndex() {

  const dayBefore = initDateObject(-1);
  const currentDate = initDateObject(0);

  //Date range specified by user
  const [startDate, setStartDate] = useState(dayjs(dayBefore));
  const [endDate, setEndDate] = useState(dayjs(currentDate));

  //True if jobs exist within range of dates, else false
  const [isJobs, setIsJobs] = useState(false);

  //No dates in the future
  let maxEndDate = dayjs(new Date());
  let maxStartDate = endDate.subtract(1, "day");

  //Ensures initial fetch happens with correct dates
  useEffect(() => {
    setStartDate(dayjs(dayBefore));
    setEndDate(dayjs(currentDate));
  }, []);



  //Returns Insights JSX
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Card>
          <CardHeader title="Insights" />
          <CardBody>
            <div className="row mb-3">
              <div className="col-3">
                <DatePicker
                  label="From"
                  onChange={(e) => setStartDate(e)}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="MM/DD/YYYY HH:mm:ss"
                  value={startDate}
                  maxDate={maxStartDate}
                />
              </div>
              <div className="col-3">
                <DatePicker
                  label="To"
                  onChange={(e) => setEndDate(e)}
                  value={endDate}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="MM/DD/YYYY HH:mm:ss"
                  minDate={startDate ? startDate.add(1, "day") : null}
                  maxDate={maxEndDate}
                />
              </div>
            </div>
            <div style={{ height: "60vh", width: "100%", position: "relative" }}>
              <MapComponent
                startDate={startDate}
                endDate={endDate}
                setIsJobs={setIsJobs}
              />
              {/* If no jobs, render overlay */}
              {!isJobs && <NoJobsOverlay />}
            </div>
          </CardBody>
        </Card>
      </LocalizationProvider>
    </>
  );
}
