import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import FacebookLogin from "react-facebook-login";
import axios from "axios";
import { useSelector } from "react-redux";
export function SocialAccounts() {
  const companyId = useSelector((state) => state.auth.user.company_id);
  const [token, setToken] = useState(null);

  const getPageId = async (fbToken) => {
    const response = await axios.get(
      `https://graph.facebook.com/me/accounts?access_token=${fbToken}`
    );
    //hideconsole.log(response);

    // Assuming the user manages at least one page
    if (response.data && response.data.data && response.data.data.length > 0) {
      return response.data.data[0].id; // Here is the page ID
    }

    return null;
  };

  const message = "this is test";

  const fbResponseHandler = (res) => {
    //hideconsole.log("fb response is", res);
    //hideconsole.log("fb accessTokn is", res.accessToken);
    axios.post("https://www.api.sendatradie.com/company/fbToken", {
      companyId: companyId,
      type: "fb",
      token: res,
    });
  };

  const makePostRequest = async (accessToken, pageId) => {
    try {
      const response = await axios.post(
        `https://graph.facebook.com/${pageId}/feed`,
        {
          message: message,
          access_token: accessToken,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      //hideconsole.log("Test post response:", response.data);
    } catch (error) {
      console.error("Error making test post:", error);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <br />
          <Card>
            <CardHeader title="Social Accounts"></CardHeader>
            <CardBody>
              <FacebookLogin
                appId="7754267691305336"
                autoLoad={false}
                fields="name,email,picture"
                callback={fbResponseHandler}
                scope="public_profile,email,pages_read_engagement,pages_manage_posts"
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
