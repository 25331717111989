import { Switch } from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import CustomBootstrapTable from "./../../../_metronic/_helpers/CustomBootstrapTable.js";
import { saveSetting } from "./../Auth/_redux/settingAction.js";
import * as requestFromServer from "./../Jobs/_redux/customers/customersCrud.js";
import EditNotificationModal from "./EditNotificationModal.js";

function SettingNotificationtable() {
  const columns = [
    { text: "ACTION", dataField: "action" },
    { text: "NOTIFICATION FOR", dataField: "notify" },
    { text: "NOTIFIED BY", dataField: "notify_by" },
    { text: "DESCRIPTION", dataField: "description" },
    { text: "STATUS", dataField: "status" },
  ];
  const [data, setData] = React.useState([]);
  const [ids, setIds] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [roleOptions, setRoleOptions] = React.useState([]);
  const [showEditModal, setShowEditModal] = React.useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    requestFromServer
      .getNotificationSettings()
      .then((res) => {
        setRoleOptions(res.data.roles);
        res.data?.data?.forEach((element) => {
          element.status = (
            <Switch
              defaultChecked={!!element.status}
              onChange={handleStatus}
              color="primary"
              id={element.id.toString()}
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          );
        });
        setData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleStatus = (e) => {
    e.persist();
    //hideconsole.log(e);
    requestFromServer
      .changeNotificationStatus(e.target.id, { checked: e.target.checked })
      .then((res) => {
        if (res.status != 200) {
          //hideconsole.log("error");
        }
      });
  };

  const handleEditModalClose = () => {
    setShowEditModal(false);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-light-primary font-weight-bolder font-size-sm"
        disabled={ids.length != 1}
        onClick={() => setShowEditModal(true)}
      >
        <i className="fa fa-edit"></i> Edit
      </button>

      <CustomBootstrapTable
        data={data}
        columns={columns}
        isSelectable
        ids={ids}
        setIds={setIds}
        isLoading={isLoading}
      />

      <EditNotificationModal
        id={ids}
        show={showEditModal}
        onHide={handleEditModalClose}
        roleOptions={roleOptions}
        getData={getData}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return { settings: state.settings };
};
export default connect(mapStateToProps, { saveSetting })(
  SettingNotificationtable
);
