/**
 * Contains render for map, map popups, and cost UI
 */

//Imports
import React, { useState, useEffect, useRef } from "react";
import GoogleMapReact from "google-map-react";
import { BASE_URL } from "./../../../utils/config.js";
import axios from "axios";

/**
 * Creates JSX render of Map Component
 * @param {Date} param0.startDate Starting date for insights range
 * @param {Date} param0.endDate Ending date for insights range
 * @param {*} param0.setIsJobs Sets prop if jobs exist in range or not
 * @returns Render of Map Component
 */
export function MapComponent({ startDate, endDate, setIsJobs }) {
  //Geo-location data for map in JSON format
  const [geoJson, setGeoJson] = useState(null);
  //Array of data for each suburb's insights
  const [suburbsData, setSuburbsData] = useState(new Map());
  //Highest and lowest earnings found for suburbs
  const [maxEarnings, setMaxEarnings] = useState(0);
  const [minEarnings, setMinEarnings] = useState(0);
  //RNG key for google map
  const [mapKey, setMapKey] = useState(Math.random());

  //Refs to keep track of the currently open InfoWindow and active suburb
  const openInfoWindowRef = useRef(null);
  const [activeSuburb, setActiveSuburb] = useState(null);

  //Endpoint for jobs by date range
  const JOBS_BY_DATE_ENDPOINT = "company/finishedJobsLastWeek";

  /**
   * Gather insights when selected date range is changed
   */
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}${JOBS_BY_DATE_ENDPOINT}`,
          {
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD"),
          }
        );

        const fetchedData = response.data.data;

        let suburbDataTemp = new Map();
        let highestEarningsTemp = 0;
        let lowestEarningsTemp = Number.POSITIVE_INFINITY;
        let numJobs = 0;

        fetchedData.forEach((sub) => {
          if (sub.suburb != null) {
            suburbDataTemp.set(sub.suburb, {
              jobTypeCount: JSON.parse(sub.job_type_concat),
              totalJobs: sub.total_jobs,
              totalMoney: sub.total_money,
            });
            if (sub.total_money > 0) {
              highestEarningsTemp = Math.max(
                highestEarningsTemp,
                sub.total_money
              );
              lowestEarningsTemp = Math.min(
                lowestEarningsTemp,
                sub.total_money
              );
            }
            numJobs += sub.total_jobs;
          }
        });

        // Handle case where no suburbs have positive earnings
        if (lowestEarningsTemp === Number.POSITIVE_INFINITY) {
          lowestEarningsTemp = 0;
        }
        //console.log("Suburb data: ", suburbDataTemp);
        setMaxEarnings(highestEarningsTemp.toFixed(2));
        setMinEarnings(lowestEarningsTemp.toFixed(2));
        setSuburbsData(suburbDataTemp);
        setIsJobs(numJobs !== 0);
        setMapKey(Math.random());
      } catch (error) {
        console.error("Error fetching finished jobs data:", error);
      }
    };

    fetchJobs();
  }, [startDate, endDate]);

  /**
   * Fetch geo data on initial render
   */
  useEffect(() => {
    /**
     * Geo data for map
     */
    const fetchGeoJsonData = async () => {
      try {
        const response = await fetch("/suburbPolygonJson/vic.json");
        const data = await response.json();
        setGeoJson(data);
      } catch (error) {
        console.error("Error fetching GeoJSON data:", error);
      }
    };
    fetchGeoJsonData();
  }, []);

  /**
   * Calculate a suburb's shade
   * @param {*} sub The suburb object
   * @returns The hexcode of the suburb's shade
   */
  function getSuburbShade(sub) {
    let subInfo = suburbsData.get(sub);
    // Default to white if no information is found or total earnings is 0
    if (!subInfo || subInfo.totalMoney == 0) {
      return `#FFFFFF`;
    }

    let num = subInfo.totalMoney;
    // Handle cases where maxEarnings equals minEarnings to avoid division by zero
    let ratio =
      maxEarnings !== minEarnings
        ? (num - minEarnings) / (maxEarnings - minEarnings)
        : 0.5;
    let invertedRatio = 1 - ratio;
    let greenIntensity = Math.floor(invertedRatio * 255);
    let greenHex = greenIntensity.toString(16).padStart(2, "0");

    return `#00${greenHex}00`;
  }

  /**
   * Construct and initialize the map popup
   * @param {*} sub
   * @returns
   */
  const initMapPopup = (sub) => {
    let subInfo = suburbsData.get(sub);

    let subTotal = 0;
    let subJobs = 0;
    let jobTypeList = "";

    if (subInfo) {
      subTotal = subInfo.totalMoney;
      subJobs = subInfo.totalJobs;

      jobTypeList = subInfo.jobTypeCount
        ?.map((item) => {
          const key = Object.keys(item)[0];
          const value = item[key];
          // Only show job types in popup with more than 0 job types
          if (value > 0) {
            return `<li style="margin-bottom: 5px;"><b>${key
              .split(",")[1]
              .toUpperCase()}</b>: <span style="color: black; font-family: Arial, sans-serif;">${value}</span></li>`;
          }
          return ""; // Return an empty string for job types with 0 values
        })
        .join("");
    }

    return `
    <div style="padding: 10px; border: 1px solid #ccc; border-radius: 5px; background-color: #f9f9f9; font-family: Arial, sans-serif;">
      <div style="margin-bottom: 10px;">
        <h3 style="margin: 0; color: #333;">${sub.toUpperCase()}</h3>
      </div>
      <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
        <b>Total Earnings:</b>
        <span style="color: black;">$${subTotal.toFixed(2)}</span>
      </div>
      <div style="display: flex; justify-content: space-between; margin-bottom: 10px;">
        <b>Total Jobs:</b>
        <span style="color: black;">${subJobs}</span>
      </div>
      <div style="margin-top: 20px;">
        <ul style="list-style-type: none; padding: 0; margin: 0;">
          ${jobTypeList}
        </ul>
      </div>
    </div>
  `;
  };

  //Function to handle closing the current InfoWindow
  const closeOpenInfoWindow = () => {
    if (openInfoWindowRef.current) {
      openInfoWindowRef.current.close();
      openInfoWindowRef.current = null;
      setActiveSuburb(null);
      console.log("Closed the open info window");
    } else {
      console.log("No open info window to close");
    }
  };

  /**
   * Render the Income Statistic for selected date range
   * @param {*} map
   * @param {*} maps
   */
  const drawIncomeStatistic = (map, maps) => {
    const colorLineWrapperDiv = document.createElement("div");
    colorLineWrapperDiv.style.display = "flex";
    colorLineWrapperDiv.style.flexDirection = "column";
    colorLineWrapperDiv.style.alignItems = "center";
    colorLineWrapperDiv.style.padding = "10px";

    colorLineWrapperDiv.style.backgroundColor = "#f4f4f4";
    colorLineWrapperDiv.style.backgroundImage =
      "repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(0,0,0,0.05) 10px, rgba(0,0,0,0.05) 20px)";

    const maxMoneyLabelDiv = document.createElement("div");
    maxMoneyLabelDiv.innerText = `Highest Earnings: $${maxEarnings}`;
    maxMoneyLabelDiv.style.marginBottom = "5px";
    maxMoneyLabelDiv.style.fontWeight = "bold";
    maxMoneyLabelDiv.style.fontSize = "16px";
    colorLineWrapperDiv.appendChild(maxMoneyLabelDiv);

    const colorLineDiv = document.createElement("div");
    colorLineDiv.style.background =
      "linear-gradient(to top, #FFFFFF 0%, #00FF00 50%, #000000 100%)";
    colorLineDiv.style.height = "150px";
    colorLineDiv.style.width = "5px";
    colorLineWrapperDiv.appendChild(colorLineDiv);

    const minMoneyLabelDiv = document.createElement("div");
    minMoneyLabelDiv.innerText = `Lowest Earnings: $${minEarnings}`;
    minMoneyLabelDiv.style.marginTop = "5px";
    minMoneyLabelDiv.style.fontWeight = "bold";
    minMoneyLabelDiv.style.fontSize = "16px";
    colorLineWrapperDiv.appendChild(minMoneyLabelDiv);

    map.controls[maps.ControlPosition.TOP_LEFT].push(colorLineWrapperDiv);
  };

  /**
   * Setup handling of Google API
   * @param {*} map
   * @param {*} maps
   * @returns
   */
  const afterGoogleApiLoaded = (map, maps) => {
    if (!geoJson || !Array.isArray(geoJson)) {
      console.error(
        "GeoJSON data is not loaded or in an unexpected format. GeoJSON:",
        geoJson
      );
      return;
    }

    geoJson.forEach((item) => {
      if (item.geometry && Array.isArray(item.geometry.coordinates[0])) {
        const suburb = item.properties.Suburb_Name;
        let coords = item.geometry.coordinates[0];

        const coordsMapped = coords.map((coord) => ({
          lat: coord[1],
          lng: coord[0],
        }));

        let suburbPolygon = new maps.Polygon({
          paths: coordsMapped,
          strokeColor: "#000000",
          strokeOpacity: 0.2,
          strokeWeight: 1,
          fillColor: getSuburbShade(suburb),
          fillOpacity: 0.8,
        });

        const contentString = initMapPopup(suburb);
        const infowindow = new maps.InfoWindow({
          content: contentString,
          ariaLabel: suburb,
          position: coordsMapped[0],
        });

        suburbPolygon.addListener("click", () => {
          if (activeSuburb === suburb) {
            closeOpenInfoWindow();
          } else {
            if (openInfoWindowRef.current) {
              openInfoWindowRef.current.close();
            }
            openInfoWindowRef.current = infowindow;
            setActiveSuburb(suburb);
            console.log(`Opening info window for suburb: ${suburb}`);
            if (
              suburbsData.get(suburb) &&
              suburbsData.get(suburb).totalJobs > 0
            ) {
              infowindow.open(map);
            }
          }
        });

        suburbPolygon.setMap(map);
      }
    });

    drawIncomeStatistic(map, maps);
  };

  return (
    <div style={{ height: "60vh", width: "100%" }}>
      <GoogleMapReact
        key={mapKey}
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        center={{ lat: -37.8136, lng: 144.9631 }}
        zoom={10}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => afterGoogleApiLoaded(map, maps)}
      />
    </div>
  );
}
