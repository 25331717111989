import React, { useEffect, useState } from "react";
import { ChooseWorker } from "./ChooseWorker.js";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { CreateLeave } from "./CreateLeave.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { IconButton } from "@material-ui/core";
import { id } from "date-fns/locale";
import axios from "axios";
import { toast } from "react-toastify";
export function HandleLeaves({ workerOptions, setTracker }) {
  const [fetchedLeaves, setFetchedLeaves] = useState([]);
  const [createLeave, setCreateLeave] = useState(false);
  const [adding, setAdding] = useState(false);
  const [worker, setWorker] = useState(null);

  const fetchData = async () => {
    axios
      .post("https://www.api.sendatradie.com/company/getWorkerLeaves", {
        workerId: worker.value,
      })
      .then((res) => {
        //hideconsole.log(res.data.data[0]);
        const leavesArr = JSON.parse(res.data.data[0].leave_dates);
        //hideconsole.log("fetched leaves Data", leavesArr);
        setFetchedLeaves(leavesArr);
      });
  };

  useEffect(async () => {
    if (worker) {
      await fetchData();
    }
  }, [worker]);

  const handleLeaveDelete = async (e) => {
    let fetchedLeavesTemp = [...fetchedLeaves];
    fetchedLeavesTemp = fetchedLeavesTemp.filter((item) => {
      return item.date != e.date;
    });

    axios
      .post("https://www.api.sendatradie.com/company/addLeave", {
        leavesData: JSON.stringify(fetchedLeavesTemp),
        workerId: worker.value,
      })
      .then(async (res) => {
        //hideconsole.log("leave deleted");
        toast.success("Leave Deleted");
        await fetchData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setTracker(1);
  }, []);

  return (
    <>
      <div className="mt-4 mb-4">
        <ChooseWorker workerOptions={workerOptions} setWorker={setWorker} />
      </div>
      {worker ? (
        <>
          {" "}
          {fetchedLeaves.map((item, key) => {
            let dateString = item.date;

            // Creating a date object from the string
            let dateObj = new Date(dateString);

            // Formatting the date
            let formattedDate = dateObj.toLocaleDateString("en-GB", {
              day: "numeric",
              month: "long",
              year: "numeric",
            });

            //hideconsole.log(formattedDate);
            return (
              <Card key={key}>
                <CardBody>
                  {" "}
                  <div
                    style={{
                      fontFamily: "sans-serif",
                      fontWeight: "bold",
                    }}
                    className="row"
                  >
                    <div className="col-10">{formattedDate}</div>

                    <div className="col-2">
                      {" "}
                      <IconButton
                      // onClick={() => {
                      //   handleDayDelete(item);
                      // }}
                      // disabled={deleteInProgress}
                      >
                        <DeleteForeverIcon
                          style={{ color: "solid black" }}
                          onClick={() => {
                            handleLeaveDelete(item);
                          }}
                        />
                      </IconButton>{" "}
                    </div>
                  </div>{" "}
                  <div> {/* {item.start} - {item.end} */}</div>{" "}
                </CardBody>
              </Card>
            );
          })}
          {adding ? (
            <CreateLeave
              setAdding={setAdding}
              fetchedLeaves={fetchedLeaves}
              worker={worker}
              fetchData={fetchData}
            />
          ) : (
            <>
              <div className="row d-flex justify-content-center">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setAdding(true);
                  }}
                >
                  <AddCircleIcon fontSize="large" />
                </IconButton>
              </div>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
