import React from "react";

export function VerificationUponSignUp() {
  return (
    <>
      <div className="bg-white">
        <div
          style={{ height: "calc(100% - 170px)" }}
          className="d-flex align-items-center flex-column"
        >
          <div className="container d-flex flex-column align-items-center">
            {" "}
            <div className="col-4 mt-15">
              <img
                alt="Responsive image"
                src="/media/logos/login_logo.webp"
                className="img-fluid"
                // style={{ height: "10vm" }}
              ></img>
            </div>
          </div>
          <div className="sub_head_reg" style={{ zIndex: "99" }}>
            A verification link has been sent to the registration email. Please
            check your mailbox
          </div>
        </div>
      </div>
    </>
  );
}
