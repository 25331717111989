import { Switch } from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import CustomBootstrapTable from "./../../../../_metronic/_helpers/CustomBootstrapTable.js";
import { saveSetting } from "./../../Auth/_redux/settingAction.js";
import * as requestFromServer from "./../../Jobs/_redux/customers/customersCrud.js";
import CustomAxios from "./../../../../utils/axios.js";
import { LeadsFilter } from "./LeadsFilter.js";
import { TablesGrouping } from "./tableGrouping.js";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";

function LeadsTable(props) {
  const [columns, setColumns] = React.useState([{ text: "", dataField: "" }]);
  const [ids, setIds] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [rawData, setRawData] = React.useState([]);
  const [filters, setFilters] = React.useState({
    status: 2,
    search: "",
  });
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (props?.settings?.settings) {
      var newColumns = [];
      Object.keys(props.settings.settings).forEach((key) => {
        if (props.settings.settings[key].active) {
          newColumns.push({
            dataField: key,
            text: key
              .replace(/_view/g, " ")
              .replace(/_/g, " ")
              .toUpperCase(),
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses,
          });
          setColumns(newColumns);
        }
      });
    }
  }, [props.settings]);

  useEffect(() => {
    if (location.hash == "#leadsChange") {
      getData();
      history.push("/manage/leads");
    }
  }, [location]);

  const getData = () => {
    setIsLoading(true);
    CustomAxios.call("post", `company/getLeadsList`, null, null).then((res) => {
      setRawData(res.data.result);
      res.data.result.forEach((element) => {
        element.rawStatus = element.status;
        element.status = (
          <Switch
            defaultChecked={!!element.status}
            onChange={(e) => handleStatus(e)}
            color="primary"
            id={element.id.toString()}
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        );
      });
      setData(res.data.result);
      setIsLoading(false);
    });
  };

  const handleSearchFilter = (value) => {
    setFilters({ ...filters, search: value });
  };

  const handleStatusFilter = (value) => {
    setFilters({ ...filters, status: value });
  };

  useEffect(() => {
    let newData = [...rawData];
    if (filters.search) {
      newData = newData.filter(
        (item) =>
          item.name?.toUpperCase().includes(filters.search.toUpperCase()) ||
          item.description
            ?.toUpperCase()
            .includes(filters.search.toUpperCase()) ||
          item.payment_type
            ?.toUpperCase()
            .includes(filters.search.toUpperCase())
      );
    }
    if (filters.status == 0 || filters.status == 1) {
      newData = newData.filter((item) => item.rawStatus == filters.status);
    }
    setData(newData);
  }, [filters]);

  useEffect(() => {
    getData();
  }, []);

  const handleStatus = (e) => {
    //hideconsole.log(e.target.id);
    e.persist();
    e.stopPropagation();
    requestFromServer
      .changeLeadsStatus(e.target.id, { checked: e.target.checked })
      .then((res) => {
        //hideconsole.log("handleStatus res", res);
        // //hideconsole.log(rawData);
        // let newRawData = [...rawData];
        // newRawData.find((item) => item.id == e.target.id).rawStatus = e.target
        //   .checked
        //   ? 1
        //   : 0;
        // newRawData.find((item) => item.id == e.target.id).status = (
        //   <Switch
        //     defaultChecked={
        //       !!parseInt(
        //         newRawData.find((item) => item.id == e.target.id).rawStatus
        //       )
        //     }
        //     onChange={(e) => handleStatus(e)}
        //     color="primary"
        //     id={e.target.id}
        //     name="checkedB"
        //     inputProps={{ "aria-label": "primary checkbox" }}
        //   />
        // );
        // setRawData(newRawData);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error");
      });
  };

  return (
    <>
      <LeadsFilter
        handleSearch={handleSearchFilter}
        status={filters.status}
        setStatus={handleStatusFilter}
      />
      <TablesGrouping value={ids} getData={getData} />

      <CustomBootstrapTable
        data={data}
        columns={columns}
        isSelectable
        ids={ids}
        setIds={setIds}
        isLoading={isLoading}
      />
    </>
  );
}
const mapStateToProps = (state) => {
  return { settings: state.settings };
};
export default connect(mapStateToProps, { saveSetting })(LeadsTable);
