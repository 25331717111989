import React, { useState } from "react";

import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
function OtpverificationPage() {
  const [OTP, setotp] = useState();
  //   function validateotp
  const history = useHistory();
  const handleSubmit = () => {
    if (OTP.length === 5) {
      //hideconsole.log("submited", OTP);
      const email = localStorage.getItem("forgotemail");
      //hideconsole.log(email);
      const otp = OTP;
      axios
        .post("https://www.api.sendatradie.com/login/verifyOtp", { otp, email })
        .then((res) => {
          //hideconsole.log(res);
          history.push("/auth/newAuth");
        })
        .catch((err) => {
          toast.error("Invalid OTP");
          console.log(err);
        });
      //opt vrify
    } else {
      toast.error("OTP length error");
    }
  };

  return (
    <>
      <div
        style={{ height: "calc(100% - 170px)" }}
        className="d-flex align-items-center flex-column"
      >
        <h1 style={{ fontWeight: "900", fontSize: "3rem" }}>Verify OTP</h1>
        <div className="w-50">
          <div className="mt-4 w-100">
            <input
              className="__input_auth"
              placeholder="Enter OTP"
              type="number"
              name="OTP"
              //   validate={validateOtpNumber}
              //   pattern="[0-9]5"
              value={OTP}
              onChange={(e) => {
                setotp(e.target.value);
              }}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <div
              className="login_button w-50 text-center"
              onClick={
                OTP
                  ? handleSubmit
                  : () => {
                      toast.error("OTP is empty");
                    }
              }
            >
              <span className="login_font">Submit</span>
            </div>
            <div
              className="login_button w-50 text-center"
              style={{ backgroundColor: "darkgray" }}
              onClick={() => {
                localStorage.setItem("forgotemail", "");
                history.push("/auth/forgotpassword");
              }}
            >
              <span className="login_font">Back</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default OtpverificationPage;
