// SettingNotification.js
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import * as requestFromServer from "./../Jobs/_redux/customers/customersCrud.js";
import { toast } from "react-toastify";

export default function SettingsBankingPage() {
  const [bsb, setBsb] = useState("");
  const [acc, setAcc] = useState("");

  useEffect(() => {
    getBankingDetails();
  }, []);

  function getBankingDetails() {
    requestFromServer.getBankingDetails().then((res) => {
      //hideconsole.log(res);
      setBsb(res.data.bsb);
      setAcc(res.data.account_number);
    });
  }

  function handleChangeBankingDetails() {
    requestFromServer
      .setBankingDetails({ bsb: bsb, acc: acc })
      .then((res) => {
        //hideconsole.log(res);
        toast.success("Saved");
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occurred.");
      });
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <br />
          <Card>
            <CardHeader title="Banking Details"></CardHeader>
            <CardBody>
              <div className="form-group col-4 pl-0">
                <label>BSB</label>

                <input
                  value={bsb}
                  onChange={(e) => {
                    setBsb(e.target.value);
                  }}
                  style={{ border: "1px solid #0000001f" }}
                  className="form-control"
                  placeholder="BSB"
                />

                <label className="mt-3">Account Number</label>
                <input
                  value={acc}
                  onChange={(e) => {
                    setAcc(e.target.value);
                  }}
                  style={{ border: "1px solid #0000001f" }}
                  className="form-control"
                  placeholder="Account Number"
                />

                {/* <div>&nbsp;</div> */}
                <button
                  type="button"
                  // variant="contained"
                  onClick={handleChangeBankingDetails}
                  // color="primary"
                  className="btn btn-primary mt-5"
                >
                  Save
                </button>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
