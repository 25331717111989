// EditNewModal.js
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Switch } from "@material-ui/core";
import * as requestFromServer from "./../Jobs/_redux/customers/customersCrud.js";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

function EditNewModal({ onHide, show, allData, editDataId }) {
  const SETTINGS_URL = "https://www.api.sendatradie.com/settings";
  const [type, setType] = React.useState([]);
  const [typeValue, setTypeValue] = React.useState([]);
  const [Name, setname] = useState("");
  const [id, setId] = useState(null);
  const [Status, setStatus] = useState(false);
  const [display_order, setDisplay_order] = useState("");
  const history = useHistory();

  const handleSubmitRequest = () => {
    const TypeValueMap =
      typeValue && typeValue.length
        ? typeValue.map((data, index) => {
            return data.value;
          })
        : null;
    const isvalueAll = TypeValueMap ? TypeValueMap.includes(0) : false;
    const data = {
      status: Status ? Status : false,
      group_id: isvalueAll ? [0] : TypeValueMap,
      name: Name ? Name : "",
      display_order: display_order,
      id: id,
    };

    if (data.name) {
      axios
        .post(`${SETTINGS_URL}/editJobtype`, data)
        .then((res) => {
          //hideconsole.log(res);
          toast.success("Saved.");
          handleClose();
          history.push("/settings/jobtype#jobTypeChange");
        })
        .catch((err) => {
          console.log(err);
          handleClose();
          toast.error("Failed");
        });
      //hideconsole.log("handleSubmitRequest", data);
    } else {
      toast.error("Please fill Name field");
    }
  };

  useEffect(() => {
    if (show) {
      setId(editDataId);
      const filterData = allData.filter((data) => {
        //hideconsole.log(data);
        return data.id === editDataId;
      });
      //hideconsole.log(filterData);
      setname(filterData[0].name);
      setDisplay_order(filterData[0].display_order);
      setStatus(filterData[0].status === "active");
    }
  }, [show]);

  useEffect(() => {
    requestFromServer.getJobsType().then((response) => {
      if (response.data.result.length) {
        const val = response.data.result;
        const dataval = [...val, { value: 0, label: "All" }];
        setType(dataval);
      }
    });
  }, []);

  function handleChangeType(event) {
    setTypeValue(event);
  }

  const handleClose = () => {
    onHide();
    setTypeValue([]);
    setname("");
    setStatus(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit Job Type
          </Modal.Title>
          <button
            type="button"
            className="btn btn-light btn-close mr-0"
            aria-label="Close"
            onClick={handleClose}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <div className="mb-3 col-12">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Name
              </label>
              <input
                type="text"
                name="Name"
                value={Name}
                onChange={(e) => {
                  setname(e.target.value);
                }}
                className="form-control"
                id="Name"
                placeholder="Name"
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="exampleFormControlInput1" className="form-label">
                Display Order
              </label>
              <input
                type="number"
                name="display_order"
                value={display_order}
                onChange={(e) => {
                  setDisplay_order(e.target.value);
                }}
                className="form-control"
                id="display_order"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              onClick={handleSubmitRequest}
              className="btn btn-primary btn-elevate"
            >
              Edit
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditNewModal;
