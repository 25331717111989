import React from "react";
import { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import { AddFlowItem } from "./AddFlowItem.js";
import Stack from "@mui/system/Stack";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import { FlowRender } from "./FlowRender.js";

const Item = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#262B32" : "#fff",
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: 4,
}));

export function RegistrationEmailFlowIndex() {
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [chosenTemplate, setChosenTemplate] = useState(null);
  const [flow, setFlow] = useState([]);
  const [sendAfter, setSendAfter] = useState([1, "-1"]);
  const sendAfterOptions = [
    { value: "1", label: "Minute(s)" },
    { value: "2", label: "Hour(s)" },
    { value: "3", label: "Day(s)" },
    { value: "4", label: "Week(s)" },
    { value: "5", label: "Month(s)" },
  ];
  const fetchTemplates = async () => {
    axios
      .get("https://www.api.sendatradie.com/company/sendaTradieEmailTemplate")
      .then((res) => {
        const mappedTemplatesArray = res.data.data.map((item) => {
          return { value: item.id, label: item.name };
        });
        //hideconsole.log(mappedTemplatesArray);
        setTemplates(mappedTemplatesArray);
      })
      .catch((err) => {
        //hideconsole.log("caught error", err);
      });
  };

  const flowAltered = async (tempFlow) => {
    const tempFlowJSON = JSON.stringify(tempFlow);
    //hideconsole.log("tempflowJSON is", tempFlowJSON);
    axios
      .post("https://www.api.sendatradie.com/company/registrationEmailFlow", {
        tempFlowJSON: tempFlowJSON,
      })
      .then(async (res) => {
        await fetchFlow();
        //hideconsole.log("Posted Flow");
      })
      .catch((err) => {
        //hideconsole.log("Error Occurred");
      });
  };

  const handleAddFlowSave = async () => {
    //hideconsole.log("handleAddFlowSave called");
    const validation = addFlowValidation();
    if (validation) {
      const tempFlow = [...flow];
      tempFlow.push({ sendAfter, chosenTemplate });
      await flowAltered(tempFlow);

      toast.success("Saved");
      setAdding(false);
      setSendAfter([1, "-1"]);
      setChosenTemplate(null);
    }
  };

  const handleFlowDelete = async (key) => {
    setDeleteInProgress(true);
    const tempFlow = [...flow];
    tempFlow.splice(key, 1);
    await flowAltered(tempFlow);
    toast.success("Saved");
    setDeleteInProgress(false);
  };

  const addFlowValidation = () => {
    if (chosenTemplate == null) {
      toast.error("Tempalte Not Selected");
      return false;
    } else if (sendAfter[1] == "-1") {
      toast.error("Send After Not Set");
      return false;
    } else {
      return true;
    }
  };

  useEffect(async () => {
    await fetchTemplates();
  }, []);

  const fetchFlow = async (req, res) => {
    axios
      .get("https://www.api.sendatradie.com/company/registrationEmailFlow")
      .then((res) => {
        //hideconsole.log("fetched flow is");
        const fetchedFlow = JSON.parse(res.data.data.flow);
        //hideconsole.log(fetchedFlow);
        setFlow(fetchedFlow);
      })
      .catch((err) => {
        //hideconsole.log("got error", err);
      });
  };

  useEffect(async (req, res) => {
    await fetchFlow();
  }, []);

  useEffect(() => {
    //hideconsole.log("Template selected");
  }, [chosenTemplate]);

  const [adding, setAdding] = useState(false);

  const handleAddPress = () => {
    //hideconsole.log("handleAddPress pressed");
  };
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <br />
          <Card>
            <CardHeader title="Registration Email Flow"></CardHeader>
            <CardBody>
              {!adding ? (
                <>
                  <FlowRender
                    flow={flow}
                    sendAfterOptions={sendAfterOptions}
                    handleFlowDelete={handleFlowDelete}
                    deleteInProgress={deleteInProgress}
                    setDeleteInProgress={setDeleteInProgress}
                  />
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      id="tour_new_job"
                      className="btn btn-primary tour_new_job col-2 ml-2"
                      onClick={() => {
                        setAdding(true);
                      }}
                    >
                      Add Flow Item
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <AddFlowItem
                    setAdding={setAdding}
                    templates={templates}
                    setChosenTemplate={setChosenTemplate}
                    chosenTemplate={chosenTemplate}
                    sendAfterOptions={sendAfterOptions}
                    setSendAfter={setSendAfter}
                    sendAfter={sendAfter}
                    handleAddFlowSave={handleAddFlowSave}
                    flow={flow}
                    setFlow={setFlow}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
