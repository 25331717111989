import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./SendieMessage.scss";

const SendieMessage = ({
  personalMessage,
  imageSrc,
  imageAlt = "user-img",
}) => {
  const [displayedMessage, setDisplayedMessage] = useState("");

  useEffect(() => {
    setDisplayedMessage(personalMessage);
    const lines = personalMessage.split("\n");
    let lineIndex = 0;
    let charIndex = 0;

    console.log("lines: ", lines, "\nnum lines: ", lines.length);

    // const typeLine = () => {
    //   if (lineIndex < lines.length) {
    //     if (charIndex < lines[lineIndex].length) {
    //       console.log("Beginning line check");
    //       setDisplayedMessage((prev) => {
    //         const latestCharacterElement = document.querySelector(
    //           ".latest-character"
    //         );
    //         console.log("Latest Character: ", latestCharacterElement);
    //         if (latestCharacterElement) {
    //           console.log("Removing latest-character from old el");
    //           latestCharacterElement.classList.remove("latest-character");
    //         }

    //         // Update the displayed message
    //         const updatedMessage = prev + lines[lineIndex][charIndex];
    //         console.log("Updated Message: ", updatedMessage);
    //         charIndex++;
    //         console.log("Incremented char index");
    //         // Use a timeout to allow React to update the DOM
    //         setTimeout(() => {
    //           const messageContainer = document.getElementById("message-id");
    //           console.log("The message's container: ", messageContainer);
    //           if (messageContainer) {
    //             const newCharElement = messageContainer.lastChild;
    //             console.log("", newCharElement);
    //             //   if (newCharElement) {
    //             //     newCharElement.classList.add("latest-character");
    //             //   }
    //           }
    //         }, 0);

    //         return updatedMessage;
    //       });
    //     } else {
    //       setDisplayedMessage((prev) => prev + "\n");
    //       charIndex = 0;
    //       lineIndex++;
    //       setTimeout(typeLine, 100);
    //     }
    //   }
    // };

    // typeLine();
  }, [personalMessage]);

  return (
    <div className="sendie-message">
      <div className="user-img-container">
        <img className="user-img" alt={imageAlt} src={imageSrc} />
        <div className="speech-bubble">
          <p id="message-id" className="personal-message">
            {displayedMessage}
          </p>
        </div>
      </div>
    </div>
  );
};

SendieMessage.propTypes = {
  personalMessage: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
};

export default SendieMessage;
