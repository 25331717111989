import React from "react";
import Select from "react-select";
export function ChooseWorker({ workerOptions, setWorker }) {
  return (
    <>
      <div className="row mb-2">
        <div className="col-3 d-flex align-items-center ">Select User</div>
        <div className="col-6">
          <Select
            options={workerOptions}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 99999 }),
            }}
            onChange={(e) => {
              setWorker(e);
            }}
            isDisabled={workerOptions == null}
          />
        </div>
      </div>
    </>
  );
}
