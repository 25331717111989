import React from "react";
//import styled from 'styled-components'

import { LeadsHeader } from "./Leadsheader.js";
import { CustomersUIProvider } from "./../../Jobs/pages/Jobs/CustomersUIContext.js";
import { CustomersLoadingDialog } from "../../../../app/modules/Jobs/pages/Jobs/customers-loading-dialog/CustomersLoadingDialog";

const customersUIEvents = {
  openEditCustomerDialog: (id) => {
    //   history.push(`/Jobs/${id}/edit`);
  },
};

export function TableSendatradieLead() {
  return (
    <>
      <CustomersUIProvider customersUIEvents={customersUIEvents}>
        <CustomersLoadingDialog />
        <LeadsHeader />
      </CustomersUIProvider>
    </>
  );
}
