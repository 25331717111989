import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { hi } from "date-fns/locale";
import { connect } from "formik";
import * as clientPortalAuth from "./ClientPortalAuth.js";
import * as auth from "./../Auth/_redux/authRedux.js";
export function ClientPortalLogin() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [otpBeingSent, setOtpBeingSent] = useState(false);
  const [logginIn, setLoggingIn] = useState(false);
  const [otp, setOtp] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otpValidated, setOtpValidated] = useState(false);

  const history = useHistory();

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Email field cannot be empty.");
      return;
    }

    // validate entered email
    const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!emailRegExp.test(email)) {
      toast.error("Invalid email format.");
      return;
    }
    setOtpBeingSent(true);
    axios
      .post("https://www.api.sendatradie.com/client/sendCustomerOtp", { email })
      .then((response) => {
        if (response.data.success) {
          setShowOtpInput(true);
          toast.success("Please check your email");
        }
      })
      .catch((error) => {
        toast.error("Enter a valid email!!");
        console.error("Error Sending Password", error);
      });
  };

  const handleOtpSubmit = (e) => {
    setLoggingIn(true);
    e.preventDefault();

    axios
      .post("https://www.api.sendatradie.com/client/verifyOtp", { email, otp })
      .then((response) => {
        if (response.data.success) {
          setOtpValidated(true);
          if (response.data.accessToken) {
            dispatch(auth.actions.login(response.data.accessToken));
          }

          // Set data in sessionStorage
          dispatch(clientPortalAuth.actions.clientportallogin(response.data)); // Set session expire after 30 minutes
          history.push("/clientPortal/dashboard");
        }
      })
      .catch((error) => {
        toast.error("Invalid Password!!");
        console.error("Error Verifying OTP", error);
        setLoggingIn(false);
      });
  };

  return (
    <>
      <div
        className="container-fluid"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#ffffff",
        }}
      >
        <div className="col-12">
          <div className="container col-4">
            <img
              alt="logo"
              src="/media/logos/login_logo.webp"
              className="img-fluid"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
          </div>
          <div className="row col-md-4 m-auto" style={{ marginTop: "40px" }}>
            <div className="col">
              <form
                onSubmit={handleEmailSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  className="__input_auth client_portal_ip"
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {!showOtpInput ? (
                  <div>
                    <button
                      className="login_button"
                      style={{ marginTop: "40px" }}
                      type="submit"
                    >
                      {otpBeingSent ? (
                        <>
                          <span className="login_font">Get Password</span>
                          <div
                            className="spinner-border spinner-border-sm text-light ml-3"
                            role="status"
                            aria-hidden="true"
                          ></div>
                        </>
                      ) : (
                        <div className="login_font ">Get Password</div>
                      )}
                    </button>
                  </div>
                ) : (
                  <div></div>
                )}
              </form>
              {showOtpInput ? (
                <form
                  onSubmit={handleOtpSubmit}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <input
                    className="__input_auth client_portal_ip"
                    type="password"
                    placeholder="Password"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                  <button
                    className="login_button border-0"
                    style={{ marginTop: "40px" }}
                    type="submit"
                  >
                    {logginIn ? (
                      <>
                        <span className="login_font">Login</span>
                        <div
                          className="spinner-border spinner-border-sm text-light ml-3"
                          role="status"
                          aria-hidden="true"
                        ></div>
                      </>
                    ) : (
                      <div className="login_font ">Login</div>
                    )}
                  </button>
                </form>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
