import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import axios from "axios";

export function EditFeatureAvailability({
  show,
  onHide,
  chosenItem,
  featurePermissions,
  getPermissions,
  id,
}) {
  const handleSubmit = async () => {
    axios
      .post("https://www.api.sendatradie.com/settings/featurePermissions", {
        id: id,
        pack_id: selectedPackages,
      })
      .then(async (res) => {
        onHide();
        toast.success("Saved.");
        await getPermissions();
        //hideconsole.log("chnaged feature permissions");
      })
      .catch((err) => {
        toast.error("Edit Failed");
        onHide();
        console.log(err);
      });
  };
  const [selectOptions, setSelectOptions] = useState([
    { value: "1", label: "Lite" },
    { value: "2", label: "Starter" },
    { value: "3", label: "Standard" },
    { value: "4", label: "Ultimate" },
    { value: "5", label: "Trial" },
  ]);

  const [selectedPackages, setSelectedPackages] = useState();

  useEffect(() => {
    if (chosenItem) {
      //hideconsole.log(chosenItem.pack_id);
      setSelectedPackages(chosenItem.pack_id);
    }
  }, [chosenItem]);

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Edit Feature Availability
          </Modal.Title>
          <button
            type="button"
            className="btn btn-light btn-close mr-0"
            aria-label="Close"
            onClick={onHide}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div>
              <label>Description</label>
              <input
                className="form-control"
                type="text"
                disabled
                value={chosenItem && chosenItem.name}
              ></input>
            </div>
            <div className="mt-4">
              <label>Available In Pack</label>
              <Select
                classNamePrefix="mySelect"
                onChange={(e) => {
                  setSelectedPackages(e.map((item) => item.value).toString());
                }}
                value={selectOptions?.filter((item) => {
                  return selectedPackages?.split(",").includes(item.value);
                })}
                options={selectOptions}
                isMulti
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary btn-elevate"
            >
              Edit
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
