import React, { useState } from "react";
import { CustomersUIProvider } from "./../Jobs/pages/Jobs/CustomersUIContext.js";
import { CustomersLoadingDialog } from "./../Jobs/pages/Jobs/customers-loading-dialog/CustomersLoadingDialog.js";
import SettingNotificationtable from "./SettingNotificationtable.js";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";

const customersUIEvents = {
  openNotificationDailog: (id) => {
    //   history.push(`/Jobs/${id}/edit`);
  },
};

export function SettingNotification() {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <br />
          <Card>
            <CardHeader title="Notification"></CardHeader>
            <CardBody>
              <CustomersUIProvider customersUIEvents={customersUIEvents}>
                <CustomersLoadingDialog />
                <SettingNotificationtable />
              </CustomersUIProvider>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
