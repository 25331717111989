import React, { useState } from "react";
import * as requestFromServer from "./../Jobs/_redux/customers/customersCrud.js";
import GenerateModal from "../Template/GenerateModal";

export function ReplyBox2({
  reply,
  showReplyBox,
  setShowReplyBox,
  postReply,
  review,
}) {
  const [replyTxt, setReplyTxt] = useState(reply);
  const [generating, setGenerating] = useState(false);
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");
  const [prompt, setPrompt] = useState("");

  const generateWithAI = () => {
    setGenerating(true);
    requestFromServer
      .generateReviewReply({ review: review, prompt: prompt })
      .then((res) => {
        setGenerating(false);
        setText(res.data.email);
        // settextEditorValue(res.data.email);
        //hideconsole.log(res.data.email);
      })
      .catch((err) => {
        console.log(err);
        setGenerating(false);
      });
  };

  const textAreaStyle = {
    width: "100%",
    boxSizing: "border-box",
    padding: "10px",
    margin: "0",
    resize: "vertical",
    minHeight: "100px",
  };

  const handleReplySubmit = () => {
    postReply(replyTxt);
    setReplyTxt("");
    setShowReplyBox(false);
  };

  return (
    <>
      {reply && !showReplyBox && (
        <div
          style={{
            backgroundColor: "#f5f5f5",
            padding: "10px",
            borderRadius: "4px",
            marginTop: "10px",
          }}
        >
          <p>{reply}</p>
        </div>
      )}
      {showReplyBox && (
        <div style={{ marginTop: "10px" }}>
          <textarea
            placeholder="Write a reply..."
            value={replyTxt}
            onChange={(e) => setReplyTxt(e.target.value)}
            style={textAreaStyle}
          />
        </div>
      )}
      <div className="mt-4">
        <div className="d-flex justify-content-end">
          {reply === "" ? (
            <>
              {showReplyBox ? (
                <>
                  <div className="mr-3">
                    <button
                      type="button"
                      className="btn btn-light font-weight-bolder font-size-sm"
                      onClick={() => {
                        setShowReplyBox(false);
                        setReplyTxt("");
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
              {showReplyBox ? (
                <button
                  type="button"
                  className="btn btn-light-primary font-weight-bolder font-size-sm mr-3"
                  onClick={() => {
                    generateWithAI();
                    setShow(true);
                  }}
                >
                  Generate with AI
                  {/* {generating ? <div className="spinner-grow spinner-grow-sm" /> : ""} */}
                </button>
              ) : (
                ""
              )}
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm "
                onClick={() => {
                  if (showReplyBox) {
                    handleReplySubmit();
                  } else {
                    setShowReplyBox(true);
                  }
                }}
              >
                Reply
              </button>
            </>
          ) : (
            <>
              {!showReplyBox && (
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bolder font-size-sm"
                    onClick={() => {
                      setShowReplyBox(true);
                      setReplyTxt(reply);
                    }}
                  >
                    Update Reply
                  </button>
                </div>
              )}
              {showReplyBox && (
                <>
                  <div className="mr-3">
                    <button
                      type="button"
                      className="btn btn-light font-weight-bolder font-size-sm"
                      onClick={() => {
                        setShowReplyBox(false);
                        setReplyTxt(reply);
                      }}
                    >
                      Cancel
                    </button>
                  </div>

                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bolder font-size-sm mr-3"
                    onClick={() => {
                      generateWithAI();
                      setShow(true);
                    }}
                  >
                    Generate with AI
                    {/* {generating ? <div className="spinner-grow spinner-grow-sm" /> : ""} */}
                  </button>
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bolder font-size-sm"
                    onClick={handleReplySubmit}
                    disabled={generating}
                  >
                    Update Reply
                  </button>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <GenerateModal
        text={text}
        generating={generating}
        show={show}
        onHide={() => setShow(false)}
        prompt={prompt}
        setPrompt={setPrompt}
        generateWithAI={generateWithAI}
        settextEditorValue={setReplyTxt}
      />
    </>
  );
}
