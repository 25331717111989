import React, { useState, useEffect } from "react";
import { Badge } from "react-bootstrap";
import DoneIcon from "@material-ui/icons/Done";
import PlanSelectDetailDailog from "./PlanSelectDetail/PlanSelectDetailDailog.js";
import { useSelector } from "react-redux";
import { Switch } from "@material-ui/core";
import * as requestFromServer from "./../Jobs/_redux/customers/customersCrud.js";
import { toast } from "react-toastify";

export const PlansView = ({
  data,
  type,
  getCallData,
  currentPlan,
  setAutoRefill,
  autoRefill,
  getData,
  showPaymentMethodModal,
}) => {
  const [selectedPlan, setSelectedPlan] = useState({});
  const [modalOpenFor, setModalOpenFor] = useState(false);
  const [planUntil, setPlanUntil] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const [openPaymentMethod, setOpenPaymentMethod] = useState(false);

  const handleSelectPlan = (plan, index) => {
    if (user.pack_id == plan.id && type == "plan") {
      showPaymentMethodModal(true);
    } else if (type == "plan" && plan.id == 4) {
      window.open(
        "https://www.sendatradie.com/request-a-demo/",
        "_blank",
        "noopener,noreferrer"
      );
    } else {
      setSelectedPlan(plan);
      setModalOpenFor(true);
    }
  };

  const handleModalCloseForSelectPlan = () => {
    setSelectedPlan({});
    setModalOpenFor(false);
  };

  async function setPlanRefill(e) {
    const data = {
      description: type == "SMSplan" ? "SMSPack" : "CallPack",
      active: e.target.checked,
    };
    const setPlanRefill = await requestFromServer.setPlanRefill(data);
    if (setPlanRefill) {
      toast.success("Updated");
    }
  }

  // useEffect(() => {
  //   //hideconsole.log(data);
  //   //hideconsole.log(type);
  // }, []);

  return (
    <div>
      <div className="row g-10">
        {data.map((item, index) => {
          let dataOfList;
          if (type === "plan") {
            var mapObj = {
              "{": "",
              '"': "",
              '""': "",
              "}": "",
            };
            var re = new RegExp(Object.keys(mapObj).join("|"), "gi");
            const ArrInfo = JSON.parse(item.info); //item.info.split(",");
            dataOfList = JSON.parse(item.info);
            // =
            //   ArrInfo &&
            //   ArrInfo.map((item, i) => {
            //     return item.replace(re, function(matched) {
            //       return mapObj[matched];
            //     });
            //   });
          }
          return (
            <div
              className={type == "plan" ? "col-xl-3" : "col-xl-4"}
              key={index}
            >
              <div className="d-flex h-100 align-items-center">
                <div
                  className="w-100 h-100 d-flex justify-content-between flex-column flex-center rounded-3 bg-opacity-75 py-15 px-10"
                  style={{ backgroundColor: "rgba(243, 246, 249, 0.75)" }}
                >
                  <div>
                    <h1 className="text-dark mb-5 font-weight-bolder text-center">
                      {item.name}
                    </h1>

                    {type === "plan" ? (
                      item.id == 4 ? (
                        <>
                          <div className="d-flex text-center align-items-center justify-content-center mb-3">
                            <h2 className="font-size-large font-weight-bold text-primary text-center align-text-bottom mb-0">
                              Contact Us
                            </h2>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="d-flex text-center align-items-end justify-content-center mb-3"
                            style={{ overflow: "auto", whiteSpace: "nowrap" }}
                          >
                            <span className="text-primary align-bottom">$</span>{" "}
                            <h2 className="font-size-large font-weight-bold text-primary align-text-bottom mb-0">
                              {item.annually_price}
                            </h2>
                            <span>/ Mon billed Annually</span>
                          </div>
                          <p className="text-center">
                            ${item.monthly_price} billed Monthly{" "}
                          </p>
                        </>
                      )
                    ) : (
                      <>
                        <h5 className="text-center">${item.price}</h5>
                      </>
                    )}
                    {type == "plan" && item.id == 4 ? (
                      ""
                    ) : (
                      <p className="card-text text-center mb-0">Price in AUD</p>
                    )}
                    <div className="w-100 mb-10 font-weight-bold">
                      {type === "plan" ? (
                        dataOfList &&
                        dataOfList.map((infoCat, i) => {
                          return (
                            <div key={i}>
                              <div className="d-flex flex-row mt-5 justify-content-between align-items-center">
                                <div className="flex-grow-1">{infoCat}</div>
                                <span className="badge badge-light-success rounded-circle p-1">
                                  <DoneIcon
                                    fontSize="small"
                                    style={{
                                      fontSize: "16px",
                                      stroke: "#flawleff2i",
                                      strokeWidth: "3px",
                                      fontWeight: "600",
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <>
                          <div className="d-flex flex-row mt-5 justify-content-between align-items-center">
                            <div className="flex-grow-1">
                              {" "}
                              {type == "CallPlan"
                                ? `Call Masking (${item.amount} Minutes)`
                                : type == "StoragePlan"
                                ? `Storage (${item.amount} MB)`
                                : `SMS Messages (${item.amount})`}
                            </div>
                            <span className="badge badge-light-success rounded-circle p-1">
                              <DoneIcon
                                fontSize="small"
                                style={{
                                  fontSize: "16px",
                                  stroke: "#flawleff2i",
                                  strokeWidth: "3px",
                                }}
                              />
                            </span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      handleSelectPlan(item, index);
                    }}
                    className="w-100 text-center btn btn-primary"
                  >
                    {(type == "plan" && item.id == user.pack_id) ||
                    ((type == "CallPlan" || type == "SMSplan") &&
                      item.id == currentPlan) ? (
                      <div>
                        <DoneIcon
                          fontSize="small"
                          style={{
                            fontSize: "16px",
                            stroke: "#flawleff2i",
                            strokeWidth: "6px",
                          }}
                        />{" "}
                        Current Plan
                      </div>
                    ) : (
                      "Select"
                    )}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
        <PlanSelectDetailDailog
          AllPlan={data}
          type={type}
          setSelectedPlan={setSelectedPlan}
          selectedPlan={selectedPlan}
          show={modalOpenFor}
          getData={getData}
          onHide={handleModalCloseForSelectPlan}
          setPlanUntil={setPlanUntil}
        />
      </div>
      {(type == "SMSplan" || type == "CallPlan") && currentPlan ? (
        <div
          className="row mt-3 align-items-center"
          style={{ marginLeft: "0.5px" }}
        >
          <Switch
            variant="contained"
            onChange={(e) => {
              setAutoRefill(e.target.checked);
              setPlanRefill(e);
            }}
            color="primary"
            checked={autoRefill}
          ></Switch>
          Refill when there are less than 5{" "}
          {type == "SMSplan" ? "messages" : "minutes"} remaining.
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
