import React, { useState } from "react";
import { useEffect } from "react";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import ShareIcon from "@mui/icons-material/Share";
import { IconButton } from "@material-ui/core";
import { BsStarFill } from "react-icons/bs";
import { ReplyBox2 } from "./ReplyBox2.js";
import axios from "axios";
import { toast } from "react-toastify";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import Rating from "@mui/material/Rating";

export function DisplayReview({ review, locationID }) {
  //hideconsole.log("review is", review);

  const [profile, setProfile] = useState(review.reviewer.profilePhotoUrl);

  const postReply = async (text) => {
    axios
      .post("https://www.api.sendatradie.com/company/reviewReply", {
        locationID: locationID,
        reviewID: review.reviewId,
        reviewText: text,
      })
      .then((res) => {
        setReply(text);
        setShowReplyBox(false);
        toast.success("Reply Posted Successfully");
      })
      .catch((err) => {
        //hideconsole.log("Failed to post reply");
        toast.error("Posting Reply Failed");
      });
  };

  const [reply, setReply] = useState(
    review?.reviewReply?.comment ? review?.reviewReply?.comment : ""
  );

  const [showReplyBox, setShowReplyBox] = useState(false);

  const numberMap = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    EIGHT: 8,
    NINE: 9,
    TEN: 10,
  };

  const [starSvg, setStartSvg] = useState(null);

  const genDateFmt = (dt) => {
    var date = new Date(dt);
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract the month name, day, and year from the date object
    var monthName = monthNames[date.getMonth()];
    var day = date.getDate();
    var year = date.getFullYear();

    // Format the date string
    var formattedDate = `${monthName} ${day}, ${year}`;

    // Output the formatted date string
    //hideconsole.log(formattedDate);
    return formattedDate;
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <br />
          <Card>
            <CardBody>
              <div className="d-flex justify-content-start">
                <div className="d-flex align-items-center">
                  {profile && (
                    <img
                      src={profile}
                      alt="Profile"
                      className="rounded-circle mr-3"
                      style={{ width: "40px", height: "40px" }}
                    />
                  )}
                  <div className="d-flex flex-column">
                    <b>{review.reviewer.displayName}</b>
                    <small>{`${genDateFmt(review.createTime)}`}</small>
                    <Rating
                      name="size-small"
                      value={numberMap[review.starRating]}
                      size="small"
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-5 ml-3">{`${review.comment}`}</div>
              <div className="ml-3">
                {" "}
                <ReplyBox2
                  reply={reply}
                  showReplyBox={showReplyBox}
                  setShowReplyBox={setShowReplyBox}
                  postReply={postReply}
                  review={review}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
