import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { render } from "react-dom";
import axios from "axios";
import { Modal, Container } from "react-bootstrap";
import EmailEditor from "react-email-editor";
import { toast } from "react-toastify";

export function TemplateGenerator({ setCreateNewTemplate, fetchData }) {
  const [showSaveAs, setShowSaveAs] = useState(false);
  const [designHTML, setDesignHTML] = useState("");
  const [designString, setDesignString] = useState("");
  const [saveName, setSaveName] = useState("");
  const [subject, setSubject] = useState("");

  const handleSave = async () => {};
  const emailEditorRef = useRef(null);
  const saveTemplate = async () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      // //hideconsole.log("exportHtml", html);
      setDesignString(JSON.stringify(design));
      setDesignHTML(html);
    });
    handleSave(designHTML, designString);
    setShowSaveAs(true);
  };

  const saveTemplateFinal = async () => {
    const data = {
      design: designString,
      html: designHTML,
      name: saveName,
      subject: subject,
    };
    //hideconsole.log(designString);
    axios
      .post("https://www.api.sendatradie.com/company/sendaTradieEmailTemplate", data)
      .then(async (res) => {
        toast.success("Saved Successfully");
        //hideconsole.log("posted template successfully");
        await fetchData();
      })
      .catch((err) => {
        toast.error("Save Failed");
        //hideconsole.log("failed to post template");
      });

    setShowSaveAs(false);
    setDesignHTML("");
    setDesignString("");
    setSaveName("");
    setCreateNewTemplate(false);
  };

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  const onReady = () => {
    // editor is ready
    //hideconsole.log("onReady");
  };

  return (
    <>
      <div className="row col-12 d-flex align-items-center mt-3 mb-3">
        <div className="col-2">Subject </div>

        <div className="col-10">
          <input
            type="text"
            name="subject"
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value);
            }}
            className="form-control col-4"
          ></input>
        </div>
      </div>
      <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
      <div className="row mt-3">
        {!showSaveAs ? (
          <>
            <button
              type="button"
              id="tour_new_job"
              className="btn btn-primary tour_new_job"
              onClick={saveTemplate}
            >
              Save Template
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              id="tour_new_job"
              className="btn btn-primary tour_new_job  mr-2"
              onClick={saveTemplateFinal}
            >
              Save As
            </button>
            <input
              type="text"
              name="name"
              value={saveName}
              onChange={(e) => {
                setSaveName(e.target.value);
              }}
              className="form-control col-4"
            ></input>
          </>
        )}
        <button
          type="button"
          id="tour_new_job"
          className="btn btn-primary tour_new_job col-2 ml-2"
          onClick={() => {
            setDesignHTML("");
            setDesignString("");
            setSaveName("");
            setCreateNewTemplate(false);
          }}
        >
          Go Back
        </button>
      </div>
    </>
  );
}
