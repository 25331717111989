import React, { useState, useEffect } from "react";

import "./StepManager";
import { FaRegCircle } from "react-icons/fa";

import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import ac from "../../icons/air-conditioner.svg";
import carpet from "../../icons/carpet.png";
import key from "../../icons/key.png";
import clean from "../../icons/sweeping.png";
import tap from "../../icons/water-tap.png";
import StepHeader from "./StepHeader";

function Profession({ profession, setProfession, name }) {
  const [hovered, setHovered] = useState(null);

  const WorkData = [
    {
      icon: () => {
        return <img src={key} style={{ width: "50px", height: "50px" }} />;
      },
      name: "Locksmith",
    },
    // {
    //   icon: () => {
    //     return <img src={carpet} style={{ width: "50px", height: "50px" }} />;
    //   },
    //   name: "Carpet Cleaning",
    // },
    {
      icon: () => {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            className="bi bi-door-open"
            viewBox="0 0 16 16"
          >
            <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z" />
            <path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z" />
          </svg>
        );
      },
      name: "Garage Door Service",
    },
    {
      icon: () => {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            className="bi bi-truck"
            viewBox="0 0 16 16"
          >
            <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
          </svg>
        );
      },
      name: "Junk Removal",
    },
    {
      icon: () => {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            className="bi bi-tools"
            viewBox="0 0 16 16"
          >
            <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.356 3.356a1 1 0 0 0 1.414 0l1.586-1.586a1 1 0 0 0 0-1.414l-3.356-3.356a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0zm9.646 10.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708zM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11z" />
          </svg>
        );
      },
      name: "Appliance Repair",
    },
    {
      icon: () => {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            className="bi bi-plug"
            viewBox="0 0 16 16"
          >
            <path d="M6 0a.5.5 0 0 1 .5.5V3h3V.5a.5.5 0 0 1 1 0V3h1a.5.5 0 0 1 .5.5v3A3.5 3.5 0 0 1 8.5 10c-.002.434-.01.845-.04 1.22-.041.514-.126 1.003-.317 1.424a2.083 2.083 0 0 1-.97 1.028C6.725 13.9 6.169 14 5.5 14c-.998 0-1.61.33-1.974.718A1.922 1.922 0 0 0 3 16H2c0-.616.232-1.367.797-1.968C3.374 13.42 4.261 13 5.5 13c.581 0 .962-.088 1.218-.219.241-.123.4-.3.514-.55.121-.266.193-.621.23-1.09.027-.34.035-.718.037-1.141A3.5 3.5 0 0 1 4 6.5v-3a.5.5 0 0 1 .5-.5h1V.5A.5.5 0 0 1 6 0zM5 4v2.5A2.5 2.5 0 0 0 7.5 9h1A2.5 2.5 0 0 0 11 6.5V4H5z" />
          </svg>
        );
      },
      name: "Electrical Contracting",
    },
    {
      icon: () => {
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50px"
            height="50px"
            fill="currentColor"
            className="bi bi-person-fill"
            viewBox="0 0 16 16"
          >
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
          </svg>
        );
      },
      name: "Handyman",
    },
    {
      icon: () => {
        return <img src={tap} style={{ width: "50px", height: "50px" }} />;
      },
      name: "Plumbing",
    },
    {
      icon: () => {
        return <img src={ac} style={{ width: "50px", height: "50px" }} />;
      },
      name: "HVAC",
    },
    {
      icon: () => {
        return <img src={clean} style={{ width: "50px", height: "50px" }} />;
      },
      name: "Cleaning Services",
    },
    {
      icon: () => {
        return <FaRegCircle size="64" />;
      },
      name: "Other",
    },
  ];

  return (
    <>
      <StepHeader
        title="What kind of work do you do?"
        subtitle="This helps us customize Sendatradie for your needs"
        relativeImgPath="/icons/sendie3.webp"
      />
      <div className="row justify-content-center particular_box">
        {WorkData &&
          WorkData.map((data, index) => {
            const isActive = profession === data.name;
            const isHovered = hovered === index;
            return (
              <div
                key={index}
                className={
                  isActive
                    ? "selected-profession-box col-md-3 col-lg-2 ml-4 mt-4"
                    : "box_value col-md-3 col-lg-2 ml-4 mt-4"
                }
                onClick={() => setProfession(data.name)}
                onMouseEnter={() => setHovered(index)}
                onMouseLeave={() => setHovered(null)}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {data.icon()}
                </div>
                <p className="label_step">{data.name}</p>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default Profession;
