import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="d-flex justify-content-center text-align-center bg-white">
      <div className=" d-flex flex-column justify-content-center">
        <div class="container">
          <h1>Privacy Policy</h1>
          <b>1. Overview</b>
          <p>
            Sendatradie is a software platform for trade contractors (“Tradie”
            and “Tradies”) to assist them to manage their business more
            effectively. It’s cloud-based Software as a Service (SaaS) allowing
            Tradies to manage key aspects of their business from anywhere that
            has internet access, including job sites that the Tradie visits.
            Office staff of the Tradie can also access the platform to operate
            their business.{" "}
          </p>
          <p>
            Sendatradie is committed to maintaining the privacy of your personal
            information.
          </p>
          <p></p>
          <p>
            The primary purpose of this policy is to communicate to you how
            Sendatradie deals with your personal information. It explains how we
            collect, use and disclose your personal information, as well as
            other information or data associated with you and your use of our
            services. It also informs you about rights and responsibilities you
            have regarding your personal information, in association with your
            use of our services.
          </p>
          <p></p>
          <p></p>
          <p>
            We believe in informed consent at Sendatradie. We therefore
            encourage you to read this policy carefully, to help you make an
            informed decision about sharing your information with us.
          </p>
          <p></p>
          <p></p>
          <p>
            By accessing or using our website or software, you consent to the
            terms of this Privacy Policy as well as our{" "}
            <a href="/terms-and-conditions">Terms of Service</a>.
          </p>
          <p></p>
          <p></p>
          <b>Defined terms</b>
          <p>
            “You” & “Your”: “You” may be a visitor to one of our websites, or a
            new or existing Subscriber of our Service, or an End Customer of a
            Subscriber.
          </p>
          <p>
            “Sendatradie”, “we”, “us”, “our”: Sendatradie Pty Ltd & its
            affiliates.
          </p>
          <p>
            “Service”: our Software as a Service (SaaS) allows Tradies to manage
            key aspects of their business, including from job sites that the
            Tradie visits. Staff of the Tradie can also access the platform from
            the office while the Trade is onsite.
          </p>
          <p>
            “Subscriber”, “Subscribers”: Tradies or staff under their direction
            that use the Sendatradie platform.{" "}
          </p>
          <p>
            “End Customer”, “End Customers”: Customers of Tradies that engage
            with the Tradie on proposed or actual jobs, hence either directly or
            indirectly engage with the Sendatradie platform used by the
            Subscriber Tradie.{" "}
          </p>
          <p>‍</p>
          <p>
            This policy does not apply to third-party websites, products, or
            services even if they link to our Services, and You should consider
            the privacy practices and policies of those third parties carefully.
          </p>
          <p></p>
          <p></p>
          <p>
            If You disagree with the practices described in this policy, You
            should (a) take the necessary steps to remove cookies from Your
            computer after leaving our website, and (b) discontinue Your use of
            our Services.{" "}
          </p>
          <p>‍</p>
          <b>2. Information We Collect</b>
          <p>
            Sendatradie collects personal, business and non-identifiable
            information about You. This privacy policy is primarily concerned
            with how we deal with Your personal information.
          </p>
          <p></p>
          <p></p>
          <p>
            Personal information is information that can be used to identify an
            individual person, in particular by reference to an identifier such
            as a name, contact information or address. Financial information
            that is collected for the purpose of billing is not held by
            Sendatradie, rather, it is anonymised and encrypted on a third-party
            Stripe financial platform. For more information, please go to
            www.stripe.com{" "}
          </p>
          <p>‍</p>
          <b>Personal information</b>
          <p>
            We may collect personal information, and other information, when
            You:
          </p>
          <p></p>
          <p></p>
          <p>‍Register to use the Service</p>
          <p>‍Demo or trial the Service</p>
          <p>Use the Service, on any device</p>
          <p>Communicate with us</p>
          <p>Visit our website</p>
          <p>Post to our blog</p>
          <p>Like or interact with us on social media</p>
          <p></p>
          <p></p>
          <p>
            When you create a Sendatradie account, we may ask for and collect
            personal information, such as Your:
          </p>
          <p></p>
          <p></p>
          <p>Name</p>
          <p>Email</p>
          <p>Address</p>
          <p>Phone number/s</p>
          <p>Staff names, email addresses, and phone numbers</p>
          <p></p>
          <p>
            This personal information is voluntarily provided and can be edited
            and updated in the settings of your account with the Service. You
            may choose not to provide some of Your personal information; however
            this may prevent or severely constrain our ability to provide You
            with the Service and/or Your ability to use the Service.
          </p>
          <p>‍</p>
          <b>End-customer personal information</b>
          <p>
            If you’re a customer of one of our Tradie Subscribers, the
            subscriber will likely need to collect information about you when
            you interact with the Subscribers business through the Sendatradie
            Service. For example, if you initiate a transaction with the
            Subscriber through the Sendatradie Service such as booking a job,
            the Subscriber may collect information about you, such as your name,
            address, phone number/s, email, as well as other information you
            provide for the purpose of the dealings or completing the
            transaction.
          </p>
          <p>
            If you believe a Sendatradie Subscriber has entered your personal
            information into their Sendatradie account, you will need to contact
            that Sendatradie Subscriber directly regarding any questions or
            requests you have around the storage, use and retention of your
            information. For the avoidance of doubt, all Subscribers to
            Sendatradie agree and are bound by the 13 Australian Privacy
            Principles, and the Privacy Act 1988.{" "}
          </p>
          <p>‍‍</p>
          <b>Other information</b>
          <p>
            We may also automatically collect non-personal information that Your
            computer or mobile device provides to us in connection with Your
            visit to our website, communication with us, or trial or use of the
            Service, such as:
          </p>
          <p>‍</p>
          <p>Type of computer or mobile device</p>
          <p>Device operating system</p>
          <p>Unique device identifier</p>
          <p>Browser type</p>
          <p>Browsing activity</p>
          <p>IP Address</p>
          <p>MAC address</p>
          <p>
            Physical location (including geolocation, beacon based location,
            and/or GPS location)
          </p>
          <p>‍</p>
          <p>
            We may also store usage data such as the date and time the
            application on your device accesses our servers, and what
            information and files have been downloaded to the application based
            on your device number.
          </p>
          <p>‍</p>
          <b>Special categories of data </b>
          <p>
            Sendatradie does not knowingly attempt to solicit or receive special
            categories of data, such as personal information relating to race,
            ethnic origin, political association, trade union membership,
            religious beliefs, criminal records or health information.
          </p>
          <p>‍</p>
          <b>3. How Information May Be Used</b>
          <p></p>
          <p>
            Sendatradie collects Your personal information so that we can
            provide You with the Service and any related services You request.
            In doing so, Sendatradie may use the personal information we have
            collected from You for purposes related to the Service, including
            to:
          </p>
          <p></p>
          <p></p>
          <p>
            Provide the Service to You, including any additional features,
            ‘Add-ons’ or connections You activate as part of your use of the
            Service
          </p>
          <p>Respond to Your enquiries and requests</p>
          <p>Administer our provision of the Service to You</p>
          <p>Identify You as a Subscriber in our system</p>
          <p>Identify You as an End Customer in our system</p>
          <p>Verify Your identity</p>
          <p>
            Communicate with You in provision of technical support and any other
            issues You might experience in using the Service
          </p>
          <p>
            Send You training, marketing or promotional communications, related
            to the Service
          </p>
          <p>
            Send You information important to Your use of the Service, such as
            changes to our terms, conditions and policies
          </p>
          <p>
            Seek feedback from You in the interest of improving our services,
            including for the purpose of seeking a testimonial or completing a
            client survey
          </p>
          <p>
            Aggregate personal and other information into non-identifiable,
            anonymous data to help improve our Services
          </p>
          <p>Enforce our Terms of Service & policies</p>
          <p>
            Facilitate our internal business operations, including the
            fulfilment of any legal requirements
          </p>
          <p>
            Prosecute or defend in a court, arbitration, or similar legal
            proceeding
          </p>
          <p>‍</p>
          <p>
            Your use of the Service means You consent to the collection, holding
            and usage of personal and other information for these purposes, and
            any other purpose for which you have provided express permission, on
            an ongoing basis until termination of Your use of the Service,
            subject to the terms of this policy and our Terms of Service.
          </p>
          <p></p>
          <p></p>
          <b>4. How We Disclose Data</b>
          <p>
            Sendatradie is committed to the confidentiality of your information
            and account data, and will only disclose Your personal and other
            information to third parties for limited purposes relating to
            provision of the Service to You, for the improvement of our Service,
            and under certain circumstances, as described below. We may disclose
            Your personal and other information with:
          </p>
          <p></p>
          <p></p>
          <p>
            <b>Sendatradie affiliates</b>: we may disclose Your information to
            entities that we control, for their internal business purposes and
            as part of our provision of the Service to You.
          </p>
          <p>‍</p>
          <p>
            <b>Third party service providers</b>: we may disclose Your
            information or account data to third party service providers for the
            purpose of providing You with the Service, improving our Service,
            and other aforementioned purposes for how we use Your information,
            including services such as website hosting, data analysis, payment
            processing services, information technology and related
            infrastructure provision, customer service, email delivery, SMS/text
            delivery, credit card processing, auditing, phone communications,
            and other similar services.{" "}
          </p>
          <p>
            <b>Disclosure</b>: we may disclose Subscriber information, and
            Subscriber staff information to End Customer as part of our
            provision and Your use of the Service. For example, Sendatradie
            sends emails and text messages to both Subscribers and End Customers
            which may contain personal and other information.
          </p>
          <p>‍</p>
          <p>
            <b>Authorised third-parties</b>, such as when You connect Your
            Sendatradie account to a third-party ‘Add-on’ e.g. organisations
            like accounting packages (Xero, QuickBooks Online, MYOB and Sage) or
            timesheet solutions. The use of Your personal data by an authorised
            third party is subject to the third party’s privacy policy.
          </p>
          <p>‍</p>
          <p>
            <b>Independent Sendatradie Partners</b> which You have agreed to,
            sought or requested contact with for the purpose of Sendatradie
            consultation, training and setup.
          </p>
          <p>‍</p>
          <p>
            <b>
              Law enforcement agencies, legal systems and regulatory authorities
            </b>
            : we may be required to disclose Your information, without Your
            consent, in order to comply with any court orders, subpoenas, or
            other legal processes or investigations, if such disclosure is
            required by law. Where possible and appropriate, we will notify You
            if we are required by law to disclose Your personal information.
          </p>
          <p>‍</p>
          <p>
            <b>Successors</b>: we may disclose Your information in the case of a
            sale, merger, consolidation, liquidation, reorganisation, or
            acquisition. In that event, any acquirer will be subject to the
            obligations of this privacy policy.
          </p>
          <p>‍</p>
          <p>
            <b>
              People browsing our message boards, chat, profile pages, blogs and
              other services
            </b>{" "}
            to which You are able to post information and materials, including
            “Testimonials, Ratings and Reviews” and “Public Forum.” If You
            submit or agree to testimonials, ratings or reviews to the Service,
            any Personal Information You include will be displayed in the
            Service. If you want Your testimonial removed, please contact us at
            privacy@Sendatradie.com.
          </p>
          <p>‍</p>
          <p>
            Your contacts associated with your social media accounts,
            Sendatradie’s social media contacts, and browsers of the applicable
            social media, in connection with any of Your social media activity
            related to Sendatradie.
          </p>
          <p>‍</p>
          <p>
            Any other entity for which You have provided express consent for
            disclosure of Your information.
          </p>
          <p>‍</p>
          <b>5. Security</b>
          <p>
            Sendatradie takes reasonable physical, technical and administrative
            precautions to protect Your information from loss, theft, or
            unauthorised access, alteration or disclosure.
          </p>
          <p></p>
          <p></p>
          <p>
            Sendatradie's servers are secured by SSL Certificates, so all data
            transferred between You and Sendatradie is encrypted where required
            by law.
          </p>
          <p>‍</p>
          <b>Notification of breach</b>
          <p>
            Should Sendatradie become aware of any accidental, unauthorised or
            unlawful destruction, loss, alteration, disclosure, or breach of
            personal information, if such a breach represents a risk to the
            rights and freedoms of natural persons, Sendatradie will notify You
            of the breach as soon as possible.
          </p>
          <p></p>
          <b>Subscribername & password security</b>
          <p>
            It is your responsibility to protect the security of your
            Sendatradie Subscribername and password. Sendatradie staff, and
            independent Sendatradie Partners, do not have access to your
            password and will never ask for your password. Your Sendatradie
            password should be unique.
          </p>
          <p>‍</p>
          <b>6. Advertising & Choice</b>
          <p>
            Sendatradie will not sell or otherwise disclose Your information to
            marketers or promotional third parties without your consent.
          </p>
          <p></p>
          <p></p>
          <p>
            Sendatradie may use your information for the purpose of sending you
            training, marketing or promotional communications, related to the
            Service, or which we determine may be of interest to you due to
            being relevant to Your business or industry.
          </p>
          <p></p>
          <p></p>
          <p>
            You may opt out of these communications via ‘unsubscribe’
            mechanisms. However, note that we may still send You communications
            important to Your use and our provision and administration of the
            Service to You.
          </p>
          <p>‍</p>
          <b>7. Access to Your Information & Retention of Information</b>
          <p>
            You may access your information via Your Sendatradie account or
            through a request to us.
          </p>
          <p></p>
          <p></p>
          <p>
            You have the ability to access, alter or correct Your personal
            information within Your Sendatradie account. It is Your
            responsibility to ensure the personal information You provide us is
            complete, accurate and up to date.
          </p>
          <p></p>
          <p>
            You can request, at no charge, all personal and other information we
            hold about you in writing at privacy@Sendatradie.com. Following an
            information request, if you believe any of the information we hold
            about you is unnecessary, inaccurate, out of date or incomplete,
            please contact us at privacy@Sendatradie.com.
          </p>
          <p></p>
          <p>
            To request removal of Your personal information from any websites or
            forums controlled by Sendatradie, please request this in writing
            through privacy@Sendatradie.com .
          </p>
          <p></p>
          <p>
            We will process Your requests as soon as reasonably practicable. If
            it is not reasonable, practical, or appropriate for us to process
            Your request in the manner you have requested, or we determine it to
            be frivolous or vexatious, we will advise you accordingly.{" "}
          </p>
          <p></p>
          <p>
            You may close Your Sendatradie account at any time. This will
            terminate Your access to the Service and result in the immediate
            deletion or anonymization of your account data, including historic
            backups, stored in various locations around the world. However, note
            that Sendatradie may have a variety of obligations to retain some or
            all of Your personal and other information for certain periods.
          </p>
          <p>‍</p>
          <b>8. Use of Services by Minors</b>
          <p>
            You may not use the Service if you are under 16 years of age, unless
            Sendatradie is provided with the appropriate parental or legal
            guardian consent.
          </p>
          <p></p>
          <b>
            9. Responsibilities for processing and controlling personal
            information
          </b>
          <p>
            Through provision of the Service to You, Sendatradie has an
            obligation to process Your personal and other information, and
            account data, as directed by You as part of Your use of the Service,
            and in accordance with this policy and our Terms of Service. Under
            this policy and as a data controller, Sendatradie has certain
            obligations to ensure the privacy of Your personal and other
            information.
          </p>
          <p></p>
          <p>
            In turn, Your use of the Service involves You collecting, holding
            and using certain personal and other information about Your
            customers and any other personal data. You determine and control the
            entry, use and retention of any personal information within your
            account. You should be aware of Your responsibilities as a data
            controller when collecting, holding and using personal information
            about Your customers, and the corresponding rights of Your customers
            or data subjects.
          </p>
          <p>‍</p>
          <p>
            If You collect, hold and use a third party's personal information as
            part of Your use of the Service then You warrant to us that You have
            the third party’s consent to do so.
          </p>
          <p></p>
          <b>11. Updates to this Privacy Policy and Notifications</b>
          <p>
            Sendatradie reserves the right to change this policy at any time,
            and any amended policy is effective upon the posting on this
            website. In the event of any significant update/s, Sendatradie will
            communicate these changes to You via email, notification via the
            website, or notification through Your account.
          </p>
          <p></p>
          <p>
            You agree that electronic disclosures and notices have the same
            meaning and effect as if we had provided You with hard copy
            disclosures. Disclosures and notices in relation to this policy or
            Your information shall be considered to be received by You within 24
            hours of the time they are posted to our website or sent to You via
            email or notification through Your account.
          </p>
          <p>‍</p>
          <b>Contact Us</b>
          <p>
            If You have any questions or concerns about this privacy policy or
            how your personal information is handled, please contact our Privacy
            Officer delegate at privacy@Sendatradie.com.
          </p>
          <p>‍</p>
          <p>
            If you are dissatisfied with our handling of a query or request
            raised under this privacy policy, you may consider submitting a
            complaint to your country's data protection authority.
          </p>
          <p></p>
          <p></p>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
}
