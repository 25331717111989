import React, { useEffect, useState } from "react";
import axios from "axios";
import { DisplayReview } from "./DisplayReview.js";
import { LoadingData } from "./../../pages/LoadingData.js";

export function DisplayLocationReviews({ chosenLocation }) {
  const [loadingData, setLoadingData] = useState(true);
  const [reviews, setReviews] = useState([]);
  //hideconsole.log(chosenLocation.name);
  useEffect(() => {
    axios
      .post("https://www.api.sendatradie.com/company/fetchReviews", {
        name: chosenLocation.name,
      })
      .then((res) => {
        //hideconsole.log(res?.data?.data?.reviews);
        setReviews(res?.data?.data?.reviews);
        setLoadingData(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <LoadingData loading={loadingData} />
      {reviews?.map((item, key) => {
        return (
          <DisplayReview
            key={key}
            review={item}
            locationID={chosenLocation.name}
          />
        );
      })}
    </>
  );
}
