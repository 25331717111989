import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import Select from "react-select";
import CancelIcon from "@material-ui/icons/Cancel";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
  geocodeByAddress,
} from "react-google-places-autocomplete";
export function NewBookingModal({
  show,
  onHide,
  start,
  end,
  typeOptions,
  selectedCompany,
  selectedTimezone,
}) {
  //hideconsole.log("typeOptions", typeOptions);
  const history = useHistory();
  ////hideconsole.log("date is", moment(start).month());
  //hideconsole.log("company", selectedCompany);
  const customer = useSelector(
    (state) => state.clientPortalAuth.userData.customer
  );
  const [isDeleteModal, setisDeleteModal] = React.useState(false);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const defaultState = { notes: "", type: null };
  const [storeSize, setStoreSize] = useState(0);
  const [state, setState] = useState(defaultState);
  const [files, setFiles] = React.useState([]);
  const [address, setAddress] = React.useState("");
  const [filesBlob, setFilesBlob] = React.useState([]);

  const handleImgDelete = (index) => {
    //hideconsole.log(index);

    let DataBlobObj = [...filesBlob];
    let DataObj = [...files];

    DataBlobObj.splice(index, 1);
    DataObj.splice(index, 1);
    setFiles(DataObj);
    setFilesBlob(DataBlobObj);
  };
  function handleHide() {
    setState({ ...defaultState });
    setFiles([]);
    setFilesBlob([]);
    setLat(null);
    setLng(null);
    setStoreSize(0);
    setAddress("");
    onHide();
    history.push("/clientPortal/dashboard");
  }

  const handleSubmit = () => {
    const data = {
      start: moment(start).format("yyyy-MM-DD HH:mm:ss"),
      end: moment(end).format("yyyy-MM-DD HH:mm:ss"),
    };
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    files &&
      files.length &&
      files.map((file) => {
        formData.append("files", file ? file : null);
      });

    let jsonObject = {};
    // console.log(selectedTimezone);
    // console.log(moment.utc(moment.tz(start, selectedTimezone)));
    // return;
    jsonObject["customer_id"] = customer.id;
    jsonObject["email"] = customer.email;
    jsonObject["name"] = customer.name;
    jsonObject["address"] = address;
    jsonObject["company_id"] = selectedCompany;
    jsonObject["job_type"] = state.type;
    jsonObject["notes"] = state.notes;
    jsonObject["schedule_appointment"] = true;
    jsonObject["local_appointment_time"] = data.start;
    jsonObject["appointment_time"] = moment
      .utc(moment.tz(start, selectedTimezone))
      .format("yyyy-MM-DD HH:mm:ss");

    jsonObject["latitude"] = lat;
    jsonObject["longitude"] = lng;

    if (state.type == null) {
      toast.error("Select a Service");
      return;
    } else if (address == "") {
      toast.error("Address field cant be empty");
      return;
    }
    // const data = {
    //   company_id: 2,
    //   customer_id: 148640,
    //   name: "test",
    //   phone: "0485867866",
    //   email: "testre@t.com",
    //   address: "34 Areca Drive, Kawungan QLD, Australia",
    //   job_type: "57",
    //   schedule_appointment: true,
    //   local_appointment_time: "2023-11-23 14:48",
    //   appointment_time: "2023-11-23 03:48:00",
    //   latitude: -25.2996461,
    //   longitude: 152.8471691,
    //   notes: "test notes",
    // };
    //hideconsole.log(jsonObject);
    axios
      .post("https://www.api.sendatradie.com/client/requestBooking", jsonObject)
      .then((response) => {
        if (response) {
          toast.success("New Booking Request Succesful");
          handleHide();
        }
      })
      .catch((error) => {
        //hideconsole.log("New Booking Request Failed");
        console.log(error);
      });

    return;
  };

  const handleFiles = (e) => {
    //setting all files in state
    if (e.target.files.length == 0 || e.target.files == null) return;
    if (!e.target.files[0].type.startsWith("image/")) {
      toast.error("Only image files allowed");
      return;
    }

    if (
      e.target.files[0].size > 25 * 1024 * 1024 ||
      storeSize + e.target.files[0].size > 25 * 1024 * 1024
    ) {
      toast.error("Maximum upload limit exceeded");
      return;
    }

    let ImgArray = [];
    let ImgBlobArray = [];
    Object.keys(e.target.files).map((data, index) => {
      const blobUrl = URL.createObjectURL(e.target.files[data]);
      ImgBlobArray = [...ImgBlobArray, blobUrl];
      ImgArray = [...ImgArray, e.target.files[data]];
    });

    setFiles([...files, ...ImgArray]);
    setFilesBlob([...filesBlob, ...ImgBlobArray]);
    setStoreSize(storeSize + e.target.files[0].size);
    //hideconsole.log("ImgArray ==========>", ImgArray);
    //hideconsole.log("ImgBlobArray ==========>", ImgBlobArray);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            New Booking
          </Modal.Title>
          <button
            type="button"
            className="btn btn-light btn-close mr-0"
            aria-label="Close"
            onClick={handleHide}
          >
            x
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row">
            <div className="col-lg-12">
              <label>Service</label>
              <Select
                classNamePrefix="mySelect"
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                }}
                //value={typeOptions.find((opt) => opt.value == state?.type)}
                onChange={(e) => {
                  setState({ ...state, type: e.value });
                }}
                options={typeOptions}
                placeholder="Select..."
                required
              />
            </div>
          </div>
          <div className="form-group row">
            {/* Adddress */}
            <div className="col-lg-12">
              <label>Address</label>
              <GooglePlacesAutocomplete
                inputClassName="form-control"
                initialValue={address}
                name="address"
                className="address"
                autocompletionRequest={{
                  componentRestrictions: {
                    country: "au",
                  },
                }}
                minLengthAutocomplete={5}
                onSelect={(result) => {
                  const { description, place_id } = result;
                  geocodeByPlaceId(place_id)
                    .then((results) => getLatLng(results[0]))
                    .then(({ lat, lng }) => {
                      setLat(lng);
                      setLng(lat);
                    })
                    .catch((error) => console.error(error));
                  setAddress(description);
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-12">
              <label>Notes</label>
              <textarea
                className="form-control"
                type="text"
                value={state.notes}
                onChange={(e) => setState({ ...state, notes: e.target.value })}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12 mt-2">
              <label>Time</label>
              <input
                className="form-control"
                type="text"
                value={moment(start).format("DD-MM-yyyy HH:mm")}
                disabled
                // onChange={(e) => setState({ ...state, name: e.target.value })}
              ></input>
            </div>
          </div>
          {/*  <div className="row form-group">
            <div className="col-lg-12 mt-lg-0 mt-xs-5 p-0">
              <div className="col-lg-6 mb-2 mt-xs-2 mt-lg-0">
                <input
                  type="file"
                  className="mt-1"
                  multiple
                  onChange={(e) => {
                    handleFiles(e);
                  }}
                ></input>
                <div style={{ fontSize: "0.8em", fontWeight: "bold" }}>
                  Only image files allowed with max size of 25 MB
                </div>
              </div>
            </div>

            <div className="form-group row">
              {filesBlob && filesBlob.length
                ? filesBlob.map((item, index) => {
                    if (item) {
                      return (
                        <div key={index} className="col-lg-4">
                          <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 bg-light">
                            <CancelIcon
                              fontSize="large"
                              onClick={() => {
                                handleImgDelete(index);
                                //hideconsole.log("onClick");
                              }}
                            />
                          </div>
                          <img
                            src={
                              item.startsWith("jobsFile/")
                                ? `https://storage.googleapis.com/platform-295512.appspot.com/${item}`
                                : `${item}`
                            }
                            alt="img"
                            style={{
                              maxHeight: "100%",
                              maxWidth: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      );
                    }
                  })
                : null}
            </div>
                </div> */}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={handleHide}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>

            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary ml-3 btn-elevate"
            >
              Book
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
