import React, { useState, useEffect } from "react";
import * as requestFromServer from "./../modules/Jobs/_redux/customers/customersCrud.js";
import { PlansView } from "../modules/Plans";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
export const StoragePlan = () => {
  const [StoragePlans, setStoragePlans] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    requestFromServer
      .getStoragePlans()
      .then((response) => {
        //hideconsole.log(response.data.result, "response.result.");
        if (response.data.result.length) {
          setStoragePlans(response.data.result);
        } else {
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });
  };

  return (
    <div className="card">
      <div className="card-body p-lg-17">
        <div className="d-flex flex-column">
          <div className="mb-10 text-center">
            {/* <h1 class="fs-2hx fw-bold mb-5">Choose Your Plan</h1> */}
            {/* <div class="text-gray-400 fw-semibold fs-5">
              If you need more info about our pricing, please check
              <a href="#" class="link-primary fw-bold">
                Pricing Guidelines
              </a>
              .
            </div> */}
          </div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <PlansView data={StoragePlans} type="StoragePlan" currentPlan={0} />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </div>
  );
};
