import React, { useState } from "react";

import { TableSendatradieLead } from "./../modules/Manageleads/Component/LeadTable.js";

export function ManageLeads(props) {
  //suhbeader.setTitle("My Custom title");
  const [value, setValue] = React.useState(0);
  //const [permissionPage, setPermissionPage] = React.useState({});

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <br />
          {value === 0 && <TableSendatradieLead />}
        </div>
      </div>
    </>
  );
}
