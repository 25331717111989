import React from "react";
import { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";

export function EmailMarketingIndex() {
  const [templates, setTemplates] = useState([]);
  const [chosenTemplate, setChosenTemplate] = useState(null);
  const [companiesData, setCompaniesData] = useState(null);
  const [chosenCompanies, setChosenCompanies] = useState([]);
  const [sending, setSending] = useState(false);
  const fetchTemplates = async () => {
    axios
      .get("https://www.api.sendatradie.com/company/sendaTradieEmailTemplate")
      .then((res) => {
        const mappedTemplatesArray = res.data.data.map((item) => {
          return { value: item.id, label: item.name };
        });

        setTemplates(mappedTemplatesArray);
      })
      .catch((err) => {
        //hideconsole.log("caught error", err);
      });
  };

  const fetchCompanies = async () => {
    axios
      .get("https://www.api.sendatradie.com/company/fetchCompanies")
      .then((res) => {
        const formattedCompaniesData = res.data.data.map((item) => {
          return { value: item.id, label: item.name, email: item.email };
        });

        setCompaniesData(formattedCompaniesData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSend = async () => {
    setSending(true);
    axios
      .post("https://www.api.sendatradie.com/company/emailMarketing", {
        chosenCompanies,
        chosenTemplate,
      })
      .then((res) => {
        toast.success("Sent.");
        //hideconsole.log("marketting mail sent");
        setSending(false);
      })
      .catch((err) => {
        toast.error("Failed To Send Mail");
        setSending(false);
        console.log(err);
      });
  };

  useEffect(async () => {
    await fetchTemplates();
    await fetchCompanies();
  }, []);

  useEffect(async () => {
    //hideconsole.log(chosenCompanies);
  }, [chosenCompanies]);

  return (
    <>
      <Card>
        <CardHeader title="Email Marketing"></CardHeader>
        <CardBody>
          <div className="row mb-2">
            <div className="col-3 d-flex align-items-center ">
              Chose Email Template
            </div>
            <div className="col-6">
              <Select
                className="prefix"
                options={templates}
                onChange={(e) => {
                  setChosenTemplate(e);
                }}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 d-flex align-items-center">
              Select Companies
            </div>
            <div className="col-6">
              <Select
                isMulti
                className="prefix"
                value={chosenCompanies}
                onChange={(selected) => {
                  setChosenCompanies(selected || []); // Update the state with the new selection
                }}
                options={companiesData}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 99999 }),
                }}
              />
            </div>
          </div>
          <div className="row mt-8">
            <div className="col-3 d-flex align-items-center">
              <button
                type="button"
                id="tour_new_job"
                className="btn btn-primary tour_new_job"
                onClick={onSend}
                disabled={sending}
              >
                Send
                {sending && (
                  <span className="mr-4 ml-2 spinner spinner-white"></span>
                )}
              </button>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
