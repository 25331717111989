/**
 * Helper functions for datetime handling
 */

//Imports
import moment from "moment";


//Datetime format to set Date objects to
const dateTimeFormat = "MM/DD/YYYY HH:mm:ss";


/**
 * Creates Date object
 * @param {*} dayOffset Negative number offsets to previous date, and positive offsets to future date
 * @returns {*} Date object
 */
export function initDateObject(dayOffset) {
    let dateObj = new Date();
    dateObj.setDate(dateObj.getDate() + dayOffset);
    dateObj = moment(dateObj).format(dateTimeFormat);
    return dateObj;
}