import React, { useState } from "react";
import StepHeader from "./StepHeader";
import "./CodeVerificationForm.scss";

const CodeVerificationForm = ({
  setVerificationCode,
  verificationCode,
  verificationError,
  name,
}) => {
  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setVerificationCode([
      ...verificationCode.map((d, idx) => (idx === index ? element.value : d)),
    ]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  return (
    <>
      <StepHeader
        title="Enter Verification Code"
        subtitle="Please enter the 8-digit code sent to your email"
        relativeImgPath="/icons/sendie3.webp"
      />
      <div className="code-input-container">
        <div className="code-inputs">
          {verificationCode.map((data, index) => {
            return (
              <input
                className="code-input"
                type="text"
                name="code"
                maxLength="8"
                key={index}
                value={data}
                onChange={(e) => handleChange(e.target, index)}
                onFocus={(e) => e.target.select()}
                autoComplete="off"
                autoCorrect="off"
              />
            );
          })}
        </div>
      </div>
      {verificationError && <p className="error-text">{verificationError}</p>}
    </>
  );
};

export default CodeVerificationForm;
