import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { render } from "react-dom";
import axios from "axios";
import { Modal, Container } from "react-bootstrap";
import EmailEditor from "react-email-editor";
import { toast } from "react-toastify";

export function EditTemplate({ data, fetchData, id, setDoEdit }) {
  const emailEditorRef = useRef(null);
  //hideconsole.log(data, id);

  const [showSaveAs, setShowSaveAs] = useState(false);
  const [designHTML, setDesignHTML] = useState("");
  const [designString, setDesignString] = useState("");
  const [saveName, setSaveName] = useState("");

  const saveTemplate = async () => {
    emailEditorRef.current.editor.exportHtml(async (data) => {
      const { design, html } = data;
      // //hideconsole.log("exportHtml", html);
      setDesignString(JSON.stringify(design));
      setDesignHTML(html);
      axios
        .post("https://www.api.sendatradie.com/company/updateSendaTradieEmailTemplate", {
          id: id,
          design: JSON.stringify(design),
          html: html,
        })
        .then(async (res) => {
          //hideconsole.log("edit successful");
          toast.success("Saved.");

          setDesignHTML("");
          setDesignString("");
          setSaveName("");
          setDoEdit(false);
          await fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const onLoad = () => {
    if (data && data.design) {
      const designBuffer = Buffer.from(data.design);
      const designString = designBuffer.toString("utf8");
      //hideconsole.log(designString);

      const design = JSON.parse(designString);
      emailEditorRef.current.editor.loadDesign(design);
    }
  };

  const onReady = () => {
    // editor is ready
    //hideconsole.log("onReady");
  };

  return (
    <>
      <div>
        <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
        <div className="row mt-3">
          <button
            type="button"
            className="btn btn-light  mr-3 ml-auto"
            onClick={() => {
              setDoEdit(false);
            }}
          >
            Go Back
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={saveTemplate}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}
