import React, { useState, useEffect } from "react";
import {
  Divider,
  Stepper,
  Step,
  StepLabel,
  Button,
  Modal,
  Backdrop,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";
import axios from "axios";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CodeVerificationForm from "./CodeVerificationForm";
import GeneralInfo from "./SignUpStep1";
import Profession from "./SignUpStep2";
import JobTypes from "./SignUpStep3";
import {
  registerCompanyReq,
  registerUser,
  getCountry,
} from "../../../_redux/authCrud";
import * as auth from "../../../_redux/authRedux";
import { useLocation } from "react-router";
import AuthHeader from "../../AuthHeader";
import {
  STEP_LABELS,
  STEP,
  FindCurrentStep,
  AuthFlowType,
} from "../../../../../../utils/authHelpers";
import SendieMessage from "../../SendieMessage.js";
import "./SignUpSteps.scss";

const publicIp = require("public-ip");

function StepManager(props) {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(STEP.ONE_GENERAL);
  const [isChange, setChange] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isUserClickOnRegister, setClickOnRegister] = useState(true);
  const [AllData, setAllData] = useState({});
  const [name, setName] = useState("");
  const [Phone, setPhone] = useState("");
  const [profession, setProfession] = useState("");
  const [helpWith, setHelpWith] = useState(["None"]);
  const [companyName, setCompanyName] = useState("");
  const [companyAbn, setCompanyAbn] = useState("");
  const [geo, setGeo] = useState("");
  const [country, setCountry] = useState(13);
  const [countryOptions, setCountryOptions] = useState([]);
  const [jobTypes, setJobTypes] = useState([
    { name: "", status: true },
    { name: "", status: true },
  ]);
  const [verificationCode, setVerificationCode] = useState(
    new Array(8).fill("")
  );
  const [verificationError, setVerificationError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [sendieMessage, setSendieMessage] = useState(
    "Hey there, I'm Sendie. Let's get started! 👋"
  );

  const location = useLocation();

  useEffect(() => {
    getCountry()
      .then((res) => {
        if (res.data.length > 0) {
          setCountryOptions(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (geo === "") {
      publicIp.v4().then((res) => {});
      (async () => {
        await publicIp
          .v4()
          .then(function(response) {
            axios
              .get(`https://ipapi.co/${response}/json`)
              .then((response2) => {
                setGeo(response2.data);
              })
              .catch((error) => {
                return error;
              });
          })
          .catch(function(error) {
            return null;
          });
      })();
    }
  }, [geo]);

  useEffect(() => {
    if (isChange) {
      const allData = localStorage.getItem("registerData");
      setAllData({});
      if (allData) {
        const jsonData = JSON.parse(allData);
        setAllData(jsonData);
        setChange(false);
        setActiveStep(jsonData.activeStep);
      }
    }
  }, [isChange]);

  useEffect(() => {
    if (history.location.search) {
      const searchParams = history.location.search;
      const splitsearch = searchParams.split("code=");
    }
  }, [history.location.search]);

  const validateStep = (step) => {
    switch (step) {
      case STEP.ONE_GENERAL:
        return name.trim() !== "" && Phone.trim() !== "" && country !== "";
      case STEP.TWO_PROFESSION:
        return profession.trim() !== "";
      case STEP.THREE_JOB_TYPES:
        return jobTypes.some((jobType) => jobType.name.trim() !== "");
      case STEP.FOUR_CODE:
        return verificationCode.every((code) => code.trim() !== "");
      default:
        return false;
    }
  };

  const getValidationMessage = (step) => {
    switch (step) {
      case STEP.ONE_GENERAL:
        return "Name, Phone, and Country are required.";
      case STEP.TWO_PROFESSION:
        return "Profession is required.";
      case STEP.THREE_JOB_TYPES:
        return "At least one Job Type is required.";
      case STEP.FOUR_CODE:
        return "Verification Code is required.";
      default:
        return "";
    }
  };

  useEffect(() => {
    updateSendieMessage(activeStep);
  }, [activeStep]);

  const updateSendieMessage = (step) => {
    switch (step) {
      case STEP.ONE_GENERAL:
        setSendieMessage("Hey there, I'm Sendie. Let's get started! 👋");
        break;
      case STEP.TWO_PROFESSION:
        setSendieMessage(`It's nice to meet you ${name}!`);
        break;
      case STEP.THREE_JOB_TYPES:
        setSendieMessage(`Great to see a ${profession} here!`);
        break;
      case STEP.FOUR_CODE:
        setSendieMessage(`Almost there, ${name}! 🏁🎉`);
        break;
      default:
        setSendieMessage("Welcome to the registration process!");
        break;
    }
  };

  const registerCompany = () => {
    enableLoading();
    const searchParams = history.location.search ? history.location.search : "";
    const splitsearchCode = searchParams
      ? searchParams.split("refer_code=")
      : null;

    registerCompanyReq(
      AllData.companyAbn,
      AllData.companyPhone,
      AllData.companyName,
      geo.timezone,
      geo.currency,
      geo.country,
      AllData.helpWIth ? AllData.helpWIth : [],
      profession,
      AllData,
      AllData.country,
      splitsearchCode ? splitsearchCode[1] : null,
      verificationCode?.join(""),
      AllData.jobTypes
    )
      .then((result) => {
        const datetime = new Date(
          new Date().toLocaleString("en", { timeZone: "Australia/Melbourne" })
        );
        const now =
          datetime.getFullYear() +
          "-" +
          (datetime.getMonth() + 1) +
          "-" +
          datetime.getDate() +
          " " +
          datetime.getHours() +
          datetime.getMinutes() +
          datetime.getSeconds();
        registerUser(
          AllData.name,
          AllData.password,
          AllData.email,
          AllData.Phone,
          now,
          "",
          result.data.i,
          AllData.status,
          AllData.country.value,
          splitsearchCode ? splitsearchCode[1] : null,
          verificationCode?.join("")
        )
          .then(({ data: { accessToken } }) => {
            props.register(accessToken);
            disableLoading();
            setClickOnRegister(true);
          })
          .catch((error) => {
            console.log(error);
            let data = error.message || error;
            if (error && error.response && error.response.data) {
              if (error.response.data.error === "Invalid verification code") {
                setVerificationError("Invalid verification code");
              } else {
                data = error.response.data.data;
              }
            } else {
              data = "User cannot register";
            }
            toast.error(data);
            disableLoading();
            setClickOnRegister(true);
          });
      })
      .catch((error) => {
        console.log(error);
        let data = error.message || error;
        if (error && error.response && error.response.data) {
          data = error.response.data.data;
        } else {
          data = "User cannot register";
        }
        toast.error(data);
        disableLoading();
        setClickOnRegister(true);
      });
  };

  const handleNext = () => {
    if (validateStep(activeStep)) {
      if (activeStep === STEP.ONE_GENERAL) {
        const data = {
          ...AllData,
          country: country,
          name: name,
          Phone: Phone,
          companyName: companyName,
          companyAbn: companyAbn,
          activeStep: STEP.TWO_PROFESSION,
        };

        localStorage.setItem("registerData", JSON.stringify(data));
        setChange(true);
        setActiveStep(STEP.TWO_PROFESSION);
      } else if (activeStep === STEP.TWO_PROFESSION) {
        const data = {
          ...AllData,
          profession: profession,
          activeStep: STEP.THREE_JOB_TYPES,
        };
        localStorage.setItem("registerData", JSON.stringify(data));
        setChange(true);
        setActiveStep(STEP.THREE_JOB_TYPES);
      } else if (activeStep === STEP.THREE_JOB_TYPES) {
        const data = {
          ...AllData,
          jobTypes: jobTypes
            .filter((jobType) => jobType.name.trim() !== "")
            .map((jobType) => jobType.name.trim()),
          activeStep: STEP.FOUR_CODE,
        };
        localStorage.setItem("registerData", JSON.stringify(data));
        setChange(true);
        sendConfirmationCode();
        setActiveStep(STEP.FOUR_CODE);
      } else if (activeStep === STEP.FOUR_CODE) {
        if (isUserClickOnRegister) {
          setClickOnRegister(false);
          registerCompany();
        }
      }
    } else {
      toast.error("Please fill all required fields correctly.");
    }
  };

  function sendConfirmationCode() {
    const requestBody = {
      email: AllData.email,
    };

    axios
      .post(
        `https://www.api.sendatradie.com/register/sendRegistrationConfirmation`,
        requestBody
      )
      .then((response) => {
        console.log("Registration confirmation sent:", response.data);
      })
      .catch((error) => {
        console.error("Error sending registration confirmation:", error);
      });
  }

  const handleBack = () => {
    if (activeStep > STEP.ONE_GENERAL) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case STEP.ONE_GENERAL:
        return (
          <GeneralInfo
            setName={setName}
            Phone={Phone}
            setPhone={setPhone}
            name={name}
            country={country}
            setCountry={setCountry}
            companyName={companyName}
            companyAbn={companyAbn}
            setCompanyName={setCompanyName}
            setCompanyAbn={setCompanyAbn}
            optionForCountry={countryOptions}
          />
        );
      case STEP.TWO_PROFESSION:
        return (
          <Profession
            setProfession={setProfession}
            profession={profession}
            name={name}
          />
        );
      case STEP.THREE_JOB_TYPES:
        return (
          <JobTypes
            setJobTypes={setJobTypes}
            jobTypes={jobTypes}
            profession={profession}
          />
        );
      case STEP.FOUR_CODE:
        return (
          <CodeVerificationForm
            setVerificationCode={setVerificationCode}
            verificationCode={verificationCode}
            verificationError={verificationError}
            name={name}
          />
        );
      default:
        return "Unknown stepIndex";
    }
  }

  return (
    <>
      {location.pathname.includes("/auth/signup/step") && (
        <AuthHeader content={FindCurrentStep(activeStep)} />
      )}

      <div className="main-content pb-5 bg-light">
        <div className="d-flex align-items-center flex-column main_container_signup_step">
          <div
            className="stepper_class"
            style={{ background: "none", color: "white" }}
          >
            <Stepper activeStep={activeStep} style={{ background: "none" }}>
              {STEP_LABELS.map((label, index) => (
                <Step key={index}>
                  <StepLabel
                    sx={{
                      "& .MuiStepLabel-label": { color: "white" },
                      "& .MuiStepLabel-iconContainer": { color: "white" },
                    }}
                    StepIconProps={{
                      style: { color: "white" },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>

        <div className="step-container">
          <SendieMessage
            personalMessage={sendieMessage}
            imageSrc="/icons/sendie3.webp"
          ></SendieMessage>
          {getStepContent(activeStep)}
          <Divider />
          <div className="button-container">
            <Button
              variant="contained"
              onClick={handleBack}
              style={{
                visibility:
                  activeStep === STEP.ONE_GENERAL ? "hidden" : "visible",
                height: "50px",
                width: "100px",
                padding: "0",
                margin: "0",
              }}
            >
              Back
            </Button>
            <Tooltip
              title={
                !validateStep(activeStep)
                  ? getValidationMessage(activeStep)
                  : ""
              }
              placement="top"
              arrow
              disableHoverListener={validateStep(activeStep)}
              classes={{
                tooltip: "custom-tooltip",
                arrow: "custom-tooltip-arrow",
              }}
            >
              <span>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={!validateStep(activeStep) || loading}
                  style={{
                    height: "50px",
                    width: "100px",
                    padding: "0",
                    margin: "0",
                  }}
                >
                  {activeStep === STEP.FOUR_CODE ? "Finish" : "Next"}
                  {loading ? (
                    <span
                      className="spinner-border spinner-border-sm ml-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : (
                    ""
                  )}
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(StepManager));
