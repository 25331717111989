import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { TextField } from "@mui/material";
import { headerSortingClasses, sortCaret } from "../../../_metronic/_helpers";
import CustomBootstrapTable from "./../../../_metronic/_helpers/CustomBootstrapTable.js";
import { useSelector } from "react-redux";
import { Paper, Tabs, Tab } from "@material-ui/core/";
import * as clientPortalAuth from "./ClientPortalAuth.js";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { NewQuoteModal } from "./NewQuoteModal.js";
import { InvoiceModal } from "./InvoiceModal.js";
export function QuotesAndInvoices() {
  const props = useSelector((state) => state.clientPortalAuth.userData);
  const [onMobile, setOnMobile] = React.useState(false);
  const [newBookingModal, setNewBookingModal] = React.useState(false);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [rawQuotesData, setrawQuotesData] = React.useState(props.quotes);
  const [rawInvoicesData, setrawInvoicesData] = React.useState(props.invoices);
  const [quotesData, setQuotesData] = React.useState(props.quotes);
  const [invoicesData, setInvoicesData] = React.useState(props.invoices);
  const date = new Date();
  const [ids, setIds] = React.useState([]);
  const [invoiceModalUrl, setInvoiceModalUrl] = React.useState("#");
  const [invoiceModal, setInvoiceModal] = React.useState(false);
  const [invoiceIds, setInvoiceIds] = React.useState([]);
  const [search, setSearch] = React.useState(null);
  const [QuotesDate, setQuotesDate] = React.useState(new Date());
  const [newQuoteNotes, setNewQuoteNotes] = React.useState("");
  const [fileUpload, setFileUpload] = React.useState("");
  const [service, setService] = React.useState("");
  const companies = [];
  const company_ids = [];
  const history = useHistory();
  const InitialValue = {
    company_name: "",
    date: "",
    note: "",
  };
  const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };
  const [columns, setColumns] = React.useState([
    {
      text: "Company",
      dataField: "name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Action",
      dataField: "id",
      formatter: (cell, row) => {
        const baseUrl = "https://platform.sendatradie.com/clientPortal/quotes/";
        return (
          <button
            className="btn btn-sm btn-light tour_job_id"
            onClick={() => window.open(baseUrl + cell, "_blank")}
          >
            View Quote
          </button>
        );
      },
    },
    {
      text: "Date",
      dataField: "date_time",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ]);
  const [invoiceColumns, setInvoiceColumns] = React.useState([
    {
      text: "Company",
      dataField: "company_name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Action",
      dataField: "invoice_file",
      formatter: (cell, row) => {
        const baseUrl =
          "https://platform-295512.appspot.com.storage.googleapis.com/";
        return (
          <button
            className="btn btn-sm btn-light tour_job_id"
            onClick={() => {
              setInvoiceModalUrl(
                baseUrl + cell + "#navpanes=0&scrollbar=0&download=true"
              );
              setInvoiceModal(true);
            }}
          >
            View Invoice
          </button>
        );
      },
    },
    {
      text: "Date",
      dataField: "date_time",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ]);
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()}`;
  const [tab, setTab] = React.useState(0);
  const [QuoteModal, setQuoteModal] = React.useState(false);
  const handleChange = function handleChange(event, newValue) {
    setTab(newValue);
  };

  const [Overlay, setOverlay] = useState(false);
  const [ProfileClass, setProfileClass] = useState(
    "offcanvas offcanvas-right p-10"
  );

  const handleCompanyChange = (company) => {
    setSelectedCompany(company);
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    setOnMobile(deviceType() == "mobile");
  }, []);

  useEffect(() => {
    if (tab == 0) {
      var newData = [...rawQuotesData];
      search &&
        (newData = newData.filter(
          (item) =>
            item.name
              .toString()
              .toUpperCase()
              .includes(search.toUpperCase()) ||
            item.date_time.toString().includes(search)
        ));
      setQuotesData(newData);
    } else if (tab == 1) {
      var newData = [...rawInvoicesData];
      search &&
        (newData = newData.filter(
          (item) =>
            item.company_name
              .toString()
              .toUpperCase()
              .includes(search.toUpperCase()) ||
            item.date_time.toString().includes(search)
        ));
      setInvoicesData(newData);
    }
  }, [search, tab]);

  function handleFileUpload(file) {
    setFileUpload(file);
  }

  function handleSelectService(s) {
    setService(s);
  }

  for (let i = 0; i < props.quotes.length; i++) {
    var quote = props.quotes[i];
    var c = {};
    c["label"] = quote["name"];
    c["value"] = Number(quote["company_id"]);
    if (company_ids.includes(c["id"])) {
      continue;
    }
    companies.push(c);
    company_ids.push(c["id"]);
  }

  for (let i = 0; i < props.invoices.length; i++) {
    var invoice = props.invoices[i];
    var c = {};
    c["label"] = invoice["company_name"];
    c["value"] = Number(invoice["company_id"]);
    if (company_ids.includes(c["id"])) {
      continue;
    }
    companies.push(c);
    company_ids.push(c["id"]);
  }

  function showProfile() {
    setProfileClass("offcanvas offcanvas-right p-10 offcanvas-on");
    setOverlay(true);
  }
  function hideProfile() {
    setProfileClass("offcanvas offcanvas-right p-10");
    setOverlay(false);
  }
  function logOut() {
    dispatch(clientPortalAuth.actions.logout());
    history.push("/clientportal/login");
  }

  return (
    <>
      <div className="card card-custom gutter-b">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">
              <b>Quotes & Invoices</b>
            </h3>
          </div>
          <div className="card-toolbar">
            <button
              type="button"
              onClick={() => {
                setQuoteModal(true);
              }}
              className="btn btn-primary tour_new_quote"
            >
              New Quote
            </button>
            <button
              type="button"
              onClick={() => {
                history.push("/clientPortal/quotes/booking");
              }}
              className="btn btn-primary tour_new_quote"
              style={{ marginLeft: "20px" }}
            >
              Booking
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="tour-quotes">
            <div className="tour_quotes">
              <Paper>
                <Tabs
                  value={tab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChange}
                  variant="fullWidth"
                  scrollButtons="auto"
                >
                  <Tab label="Quotes" />
                  <Tab label="Invoices" className="tour_web_leads" />
                </Tabs>
              </Paper>
              <div className="col-lg-4">
                <small className="form-text text-muted">
                  <b>Search</b>
                </small>
                <input
                  type="text"
                  className="form-control"
                  name="search"
                  placeholder="search"
                  // value={fullDate}
                  onChange={(event) => {
                    handleSearch(event.target.value);
                  }}
                  // onClick={hanldeOnclick}
                />
              </div>
              {tab == 0 ? (
                <>
                  {1 ? (
                    <CustomBootstrapTable
                      data={quotesData}
                      columns={columns}
                      isSelectable
                      ids={ids}
                      setIds={setIds}
                    />
                  ) : null}
                </>
              ) : tab == 1 ? (
                <CustomBootstrapTable
                  data={invoicesData}
                  columns={invoiceColumns}
                  isSelectable
                  ids={invoiceIds}
                  setIds={setInvoiceIds}
                />
              ) : (
                ""
              )}
              <br />
            </div>
          </div>
        </div>
      </div>
      <NewQuoteModal
        show={QuoteModal}
        onHide={() => setQuoteModal(false)}
        companies={companies}
      />
      <InvoiceModal
        show={invoiceModal}
        invoiceModalUrl={invoiceModalUrl}
        onHide={() => {
          setInvoiceModal(false);
        }}
      />
    </>
  );
}
