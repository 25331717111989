import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useSelector } from "react-redux";
import { ManageLocations } from "./ManageLocations.js";
import { Switch } from "@mui/material";
import { ChooseFBTemplate } from "./ChooseFBTemplate.js";
import GoogleMapReviewsIndex from "./../../GoogleMapReviews/GoogleMapReviewsIndex.js";

export function GoogleMyBusiness() {
  const history = useHistory();
  const [enableBG, setEnableBG] = useState(false);
  const company_id = useSelector((state) => state.auth.user.company_id);
  const [showManageLocations, setShowManageLocations] = useState(false);
  const [fetchedLocations, setFetchedLocations] = useState([]);
  const [ids, setIds] = React.useState([]);
  const [toggleState, setToggleState] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);
  const [chooseFBtemplate, setChooseFBtemplate] = useState(false);
  const columns = [
    { text: "TITLE", dataField: "title" },
    {
      text: "Manage Location",
      dataField: "toggle",
      formatter: (cellContent, row) => (
        <Switch
          checked={row.toggle}
          onChange={() => handleToggleChange(row.name)}
        />
      ),
    },
  ];

  const handleToggleChange = async (locationId) => {
    let fetchedLocationsTemp = [...fetchedLocations];
    fetchedLocationsTemp = fetchedLocationsTemp.map((item) => {
      if (item.name == locationId) {
        return { ...item, toggle: !item.toggle };
      } else {
        return item;
      }
    });
    //hideconsole.log(fetchedLocationsTemp);
    const locationIdsStr = JSON.stringify(fetchedLocationsTemp);
    axios
      .post("https://www.api.sendatradie.com/company/googleMyBusinessLocationsToggle", {
        data: locationIdsStr,
      })
      .then(async (res) => {
        //hideconsole.log("post success");
        await fetchData();
      });
  };

  useEffect(() => {
    //hideconsole.log("Updated state:", toggleState);
  }, [toggleState]);

  const handleLoginClick = () => {
    window.location.href = `https://www.api.sendatradie.com/api/oauth/authorize1.1?company_id=${company_id}`;
  };

  const handleManageBusinessLocations = async () => {
    setShowManageLocations(true);
  };

  useEffect(async () => {
    if (showManageLocations) await fetchData();
  }, [showManageLocations]);

  const fetchData = async () => {
    //hideconsole.log("data fetched");
    axios
      .post("https://www.api.sendatradie.com/company/googleMyBusinessLocations")
      .then((res) => {
        setFetchedLocations(JSON.parse(res.data.data[0].location_ids));
        setLoggedIn(JSON.parse(res.data.data[0].location_ids)?.length);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChooseTemplate = () => {
    setChooseFBtemplate(!chooseFBtemplate);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <br />
        <Card>
          <CardHeader title=" Google My Business">
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-light btn-elevate ml-3"
                onClick={handleLoginClick}
              >
                <img
                  alt="Logo"
                  src="/media/svg/logos/google-icon.svg"
                  class="h-20px mr-3"
                ></img>
                {loggedIn ? "Switch Account" : " Google My Business Login"}
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            {!loggedIn ? (
              <h3>Please login with your Google My Business account.</h3>
            ) : (
              <>
                <GoogleMapReviewsIndex />
                <ManageLocations
                  locations={fetchedLocations}
                  ids={ids}
                  setIds={setIds}
                  columns={columns}
                  showManageLocations={showManageLocations}
                  setShowManageLocations={setShowManageLocations}
                />

                <ChooseFBTemplate
                  enableBG={enableBG}
                  setEnableBG={setEnableBG}
                />
              </>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
