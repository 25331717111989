import React, { useState, useEffect } from "react";
import loadable from "@loadable/component";
import * as requestFromServer from "./../Jobs/_redux/customers/customersCrud.js";
// import SubEmailTemplate from "./../Tamplate/SubEmailTemplate.js";
// import { Grid, Paper } from "@material-ui/core";
import "./emailTemplate.scss";
import ReactHtmlParser from "react-html-parser";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
const SubEmailTemplate = loadable(() => import("./SubEmailTemplate"));

export function EmailTemplate(props) {
  const [SMSTemplate, setSMSTemplate] = useState({
    Message: "",
  });

  const [selectedPlatform, setSelctedPlatform] = useState(null);
  const [platformOptions, setPlatformOptions] = useState([
    { value: "0", label: "Google" },
    { value: "1", label: "Facebook" },
  ]);

  const [buttonValue, setbuttonValue] = useState([
    "{Name}",
    "{Company}",
    "{Job_Type}",
  ]);

  const [selectValue, setselectValue] = React.useState("");
  const [selectTemplate, setselectTemplate] = React.useState("");
  const [optionForSelection, setOptionForSelection] = React.useState([]);
  const [textEditorValue, settextEditorValue] = useState("");
  const [textEditorValueList, settextEditorValueList] = useState("");
  const [replaceStr, setreplaceStr] = useState("");
  const userData = useSelector((state) => state.auth.user);

  useEffect(() => {
    // //hideconsole.log(textEditorValue);
    // //hideconsole.log(selectTemplate);
    const ReplaceValue = selectTemplate
      .replace(`{Text}`, textEditorValue)
      .replace(
        "{Logo}",
        userData.company_logo && userData.company_logo.startsWith("http")
          ? userData.company_logo
          : "https://storage.googleapis.com/platform-295512.appspot.com/" +
              userData.company_logo
      );
    setreplaceStr(ReplaceValue);
  }, [textEditorValueList, textEditorValue, selectTemplate]);

  const onSaveForSMSTemplate = () => {
    const data = {
      data: textEditorValue,
      selectedTemplate: selectValue,
      selectedPlatform: selectedPlatform?.value,
    };
    //hideconsole.log("selected template value at time of save is", selectValue);
    requestFromServer
      .postEmailTemplate(data)
      .then((response) => {
        // //hideconsole.log(response.data.result, "response.SMSTemplatedata.result.");
        toast.success("Email Template successfully saved");
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
          toast.error("Failed");
        } else {
          //   toasterMessage("error", error.response.data.message);
          toast.error("Failed");
        }
      });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <br />
          <div className="row">
            <div className="col-md-7">
              <SubEmailTemplate
                buttonValue={buttonValue}
                setMessage={setSMSTemplate}
                messages={SMSTemplate}
                Title="Email Review Template"
                onSaveData={onSaveForSMSTemplate}
                setOptionForSelection={setOptionForSelection}
                optionForSelection={optionForSelection}
                setselectTemplate={setselectTemplate}
                selectTemplate={selectTemplate}
                setselectValue={setselectValue}
                selectValue={selectValue}
                textEditorValue={textEditorValue}
                settextEditorValue={settextEditorValue}
                settextEditorValueList={settextEditorValueList}
                textEditorValueList={textEditorValueList}
                selectedPlatform={selectedPlatform}
                setSelctedPlatform={setSelctedPlatform}
                platformOptions={platformOptions}
                setPlatformOptions={setPlatformOptions}
              />
            </div>
            <div className="col-md-5 mt-5 mt-md-0">
              {ReactHtmlParser(replaceStr)}
            </div>
          </div>
          {/* <Grid container spacing={3}>
            <Grid item xs={6}>
              
            </Grid>
            <Grid item xs={6}>
              <div className="html_body w-50">
               {ReactHtmlParser(replaceStr)}
              </div>
            </Grid>
          </Grid> */}
        </div>
      </div>
    </>
  );
}
