import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/customers/customersActions";
import { JobAddForm } from "./JobAddForm";
import { useCustomersUIContext } from "../CustomersUIContext";
import { useHistory } from "react-router-dom";
import * as requestFromServer from "../../../_redux/customers/customersCrud";
import moment from "moment";
import "moment-timezone";
import axios from "axios";
import { toast } from "react-toastify";
import { ContactsListCustomerLoad } from "../../../../../../redux/slice/contactsListSlice";
import { ContactsListUserLoad } from "../../../../../../redux/slice/contactsListSlice";
import styled from "styled-components";

const CustomModal = styled(Modal)`
  z-index: 9800 !important; /* Adjust the value as needed to ensure it's the highest */
`;

export function JobAddDialog({
  job,
  show,
  onHide,
  type,
  clientData,
  transferJob,
  id,
}) {
  // Customers UI Context
  const history = useHistory();
  const customersUIContext = useCustomersUIContext();
  const [jobDateTime, setJobDateTime] = useState(null);
  const userData = useSelector((state) => state.auth.user);

  // Customers Redux state
  const dispatch = useDispatch();
  const [check, setCheck] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recurringEndValue, setRecurringEndValue] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [repeatEvery, setRepeatEvery] = useState(null);
  const [endNumber, setEndNumber] = useState(null);
  const [suburbName, setSuburbName] = useState();
  const repeatOptions = [
    { value: "1", label: "Day(s)" },
    { value: "2", label: "Week(s)" },
    { value: "3", label: "Month(s)" },
  ];
  const [selectedRepeatOption, setSelectedRepeatOption] = useState({
    value: "1",
    label: "Day",
  });
  const InitialValue = {
    // address: "",
    name: "",
    phone: "",
    email: "",
    job_type: "",
    Add_info: "",
    assign_worker: "",
    lead_from: "",
    sms: "",
    schedule_appointment: "",
    appointment_time: "",
  };
  const [showJobType, setShowJobType] = React.useState(false);
  const [jobType, setJobType] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isReccurringJob, setIsReccurringJob] = useState(false);

  // get job type data
  useEffect(() => {
    requestFromServer.getJobsType().then((response) => {
      if (response.data.result.length) {
        setJobType(response.data.result);
        //hideconsole.log();
        setShowJobType(true);
      }
    });
  }, []);

  useEffect(() => {
    //hideconsole.log(clientData);
  }, [clientData]);

  // server request for saving job
  const saveJob = (job) => {
    //hideconsole.log(job);
    setIsLoading(true);

    axios
      .post("https://www.api.sendatradie.com/jobs/add", job)
      .then((res) => {
        onHide();
        if (isReccurringJob) {
          //hideconsole.log("last insertId", res.data.lastJobInsertId);
          //hideconsole.log("isReccurring", isReccurringJob);
          //hideconsole.log("Repeat Every", repeatEvery);
          //hideconsole.log("Repeat Option", Number(selectedRepeatOption.value));
          //hideconsole.log("Ends", recurringEndValue);

          //hideconsole.log("After value", endNumber);
          //hideconsole.log("job_datetime", jobDateTime);

          const reccurringData = {
            Id: res.data.lastJobInsertId,
            repeat_every: repeatEvery,
            repeat_option: Number(selectedRepeatOption.value),
            end_option: recurringEndValue,
            end_datetime: endDateTime
              ? moment
                  .utc(moment.tz(endDateTime, userData.time_zone))
                  .format("yyyy-MM-DD HH:mm:ss")
              : null,
            end_after_occurances: endNumber,
            job_datetime: check
              ? job.appointment_time
              : moment
                  .utc(moment.tz(new Date(), userData.time_zone))
                  .format("yyyy-MM-DD HH:mm:ss"),
            time_zone: userData.time_zone,
          };

          //hideconsole.log(reccurringData);

          axios.post(
            "https://www.api.sendatradie.com/jobs/addReccurringJob",
            reccurringData
          );
        }
        setIsLoading(false);
        if (history?.location?.pathname?.includes("/jobs")) {
          history.push("/jobs#onJobsChange");
        } else if (history.location.pathname == "/dashboard") {
          history.push("/dashboard#onNewJob");
        }

        setIsSubmitting(false);
        toast.success("Created New Job");
        dispatch(ContactsListCustomerLoad());
        dispatch(ContactsListUserLoad());
      })
      .catch((err) => {
        toast.error(err?.response?.data?.data || "An error occurred.");
        setIsLoading(false);
        // onHide();
      });
  };

  // axios.get("https://www.api.sendatradie.com/jobs/reccurringJob").then((res) => {
  //   //hideconsole.log("running reccurring job cron");
  // });

  return (
    <CustomModal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          Create New Job
        </Modal.Title>
        <button
          type="button"
          className="btn btn-light btn-close mr-0"
          aria-label="Close"
          onClick={onHide}
        >
          x
        </button>
      </Modal.Header>

      <JobAddForm
        clientData={clientData}
        saveJob={saveJob}
        actionsLoading={isLoading}
        InitialValue={
          type === "editClient"
            ? {
                ...InitialValue,
                name: clientData?.name,
                phone: clientData?.phone,
                email: clientData?.email,
                id: clientData?.id,
              }
            : InitialValue
        }
        onHide={onHide}
        show={showJobType}
        jobType={jobType}
        transferJob={transferJob}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
        id={id}
        recurringEndValue={recurringEndValue}
        setRecurringEndValue={setRecurringEndValue}
        isReccurringJob={isReccurringJob}
        setIsReccurringJob={setIsReccurringJob}
        repeatEvery={repeatEvery}
        setRepeatEvery={setRepeatEvery}
        endDateTime={endDateTime}
        setEndDateTime={setEndDateTime}
        endNumber={endNumber}
        setEndNumber={setEndNumber}
        repeatOptions={repeatOptions}
        selectedRepeatOption={selectedRepeatOption}
        setSelectedRepeatOption={setSelectedRepeatOption}
        jobDateTime={jobDateTime}
        setJobDateTime={setJobDateTime}
        check={check}
        setCheck={setCheck}
        suburbName={suburbName}
        setSuburbName={setSuburbName}
      />
    </CustomModal>
  );
}
