import React, { useState, useEffect } from "react";
import * as requestFromServer from "./../modules/Jobs/_redux/customers/customersCrud.js";
import { PlansView } from "../modules/Plans";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
export const SmsPlan = () => {
  const [SMSPlans, setSMSPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState(0);
  const [autoRefill, setAutoRefill] = useState(false);

  useEffect(() => {
    getSMSData();
  }, []);

  const getSMSData = () => {
    requestFromServer
      .getSMSSubScriptionPlan()
      .then((response) => {
        //hideconsole.log(response.data.result, "response.SMSdata.result.");
        if (response.data.result.length) {
          setSMSPlans(response.data.result);
          setCurrentPlan(response.data?.currentPlan[0]?.pack_id);
          setAutoRefill(!!response.data?.currentPlan[0]?.active);
        } else {
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message == "User not authorized.") {
        } else {
          //   toasterMessage("error", error.response.data.message);
        }
      });
  };

  return (
    <div className="card">
      <div className="card-body p-lg-17">
        <div className="d-flex flex-column">
          <div className="mb-10 text-center">
            {/* <h1 class="fs-2hx fw-bold mb-5">Choose Your Plan</h1> */}
            {/* <div class="text-gray-400 fw-semibold fs-5">
              If you need more info about our pricing, please check
              <a href="#" class="link-primary fw-bold">
                Pricing Guidelines
              </a>
              .
            </div> */}
          </div>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <PlansView
              data={SMSPlans}
              type="SMSplan"
              currentPlan={currentPlan}
              autoRefill={autoRefill}
              setAutoRefill={setAutoRefill}
              getData={getSMSData}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </div>
  );
};

export default SmsPlan;
